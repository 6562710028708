import React from 'react';
import  { useNavigate } from 'react-router-dom';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';


export const Alert = (message) => {
    e3.modal.alert(alertType.Info, message);
}

export const StepValidationCheck = () => {
    const navigate = useNavigate();
    const signinUser = ActionEvent.GetUser();
    if (signinUser?.SAJIN !== 'Y') {
        navigate('/app/account');
        e3.modal.alert(alertType.Error, '안면인식용 사진을 첨부해 주세요!');
    }
    if (signinUser?.SAFE_EDU_CMPLT_YN !== 'Y') {
        navigate('/app/videoplayer');
        e3.modal.alert(alertType.Error, '안전교육을 수강해 주세요!');
    }
    if (signinUser?.PASS_YN !== 'Y') {
        navigate('/app/safequiz');
        e3.modal.alert(alertType.Error, '안전교육을 평가를 진행해 주세요!');
    }
}

export const dataURLtoFile = (dataurl, fileName) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
}

export const actionDownloadFile4Blob = (blob, filename) => {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const actionDownloadFile = (base64, filename) => {
    var a = document.createElement('a');
    a.href = base64;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const getInitials = (name = '') => name.replace(/\s+/, ' ').split(' ').slice(0, 2).map((v) => v && v[0].toUpperCase()).join('');
