import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Container,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography
    //Divider,
} from '@material-ui/core';
import {
    Player,
    ControlBar,
    PlaybackRateMenuButton,
    PlayToggle,
    ReplayControl,
    ForwardControl,
    //BigPlayButton,
    CurrentTimeDisplay,
    TimeDivider,
    RemainingTimeDisplay,
    DurationDisplay,
    //MouseTimeDisplay,
    VolumeMenuButton,
    FullscreenToggle,
    LoadingSpinner,
    Shortcut
} from 'video-react';
import 'video-react/dist/video-react.css';
import QuizDialog from 'src/components/video/QuizDialog';
import ISONotification from 'src/framework/components/controls/ISONotification';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';
import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import useLocalStorage from 'src/framework/core/hooks/useLocalStorage';

const VIDEO_SOURCE_ROOT_URI = 'http://m.insighton.co.kr/Video';
const VIDEO_POSTER_ROOT_URI = './static/images';
const VIDEO_TIME_JUMP_INTERVAL = 0; // 점프 시간이 설정되지 않으면 이전 이벤트 구간으로 이동한다.
const VIDEO_QUIZ_SHOW_COUNT = 2;

const useStyles = makeStyles((theme) => ({
    btn: {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        fontSize: '14px',
        color: 'rgba(255, 255, 255, 0.5)'
    }
}));

const VideoPlayer = (props) => {
    const { signinUser, getReloadUserData, goToPage } = useUserInfoPublicAccount();
    const { saveSessionStorage, loadSessionStorage } = useSessionStorage('isFullScreen');
    const { localStorageSave, localStorageLoad } = useLocalStorage();
    const player = React.useRef(); // Video 콘트롤 선언
    const classes = useStyles();
    // Video Source 정보 설정
    const [videoState, setVideoState] = React.useState({
        play: 'CAN_PLAY',
        src: null,
        poster: ''
    });

    /* 
    // 돌발문제 결과 처리 방법
    const [quickQuizHandle, setQuickQuizHandle] = React.useState('VIDEO'); // VIDEO, COUNT

    // 돌발 문제 이벤트
    const [eventList, setEventList] = React.useState({
        list: [{ index: 1, eventTime: 600, isCheck: false, count: 0 }]
    }); */

    // Notify 출력 설정
    const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: '' });

    // 교육 정보를 가져온다.
    const getSafeSubject = async () => {
        let result = await ActionEvent.GetSafeSubject();
        videoSourceChange(result[0].FILE_PATH, `${VIDEO_POSTER_ROOT_URI}/safeedu.png`);
    };

    // 안전 교육 정보를 저장한다.
    const setSafeSubjectResult = async (passYn) => {
        await ActionEvent.SetSafeSubjectResult(passYn);
    };

    // 이벤트 점근 차단
    const eventRemove = () => {
        var video = document.querySelector('.video-react-video');
        video.addEventListener(
            'keydown',
            function (event) {
                //event.preventDefault();
                return null;
            },
            false
        );
        video.addEventListener(
            'keypress',
            function (event) {
                //event.preventDefault();
                return null;
            },
            false
        );
        video.addEventListener(
            'keyup',
            function (event) {
                //event.preventDefault();
                return null;
            },
            false
        );
        video.addEventListener(
            'focus',
            function () {
                this.blur();
            },
            false
        );
        var videocontrolbar = document.querySelector('.video-react');
        videocontrolbar.addEventListener(
            'keydown',
            function (event) {
                //event.preventDefault();
                return null;
            },
            false
        );
        videocontrolbar.addEventListener(
            'keypress',
            function (event) {
                //event.preventDefault();
                return null;
            },
            false
        );
        videocontrolbar.addEventListener(
            'keyup',
            function (event) {
                //event.preventDefault();
                return null;
            },
            false
        );
        videocontrolbar.addEventListener(
            'focus',
            function () {
                this.blur();
            },
            false
        );
    };

    // videoState.play 모니터링
    React.useEffect(async () => {
        if (videoState.play === 'END') {
            if (videoState.isFullscreen) {
                player.current.toggleFullscreen();
            }
            setNotify({ isOpen: true, message: '안전 교육 수강 완료 하였습니다.', type: alertType.Info });
            await getReloadUserData();
            setTimeout(() => {
                goToPage('/app/dashboard');
            }, 1000);
        }
    }, [videoState]);

    // onLoad event 설정
    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            getSafeSubject();
            eventRemove();
            player.current.playbackRate = 1.0;
        }
        return () => {
            mounted = false;
        };
    }, []);

    // Video event END
    function onEndedVideo(e) {
        setSafeSubjectResult('Y');
        setTimeout(() => {
            setVideoState({
                ...videoState,
                play: 'END'
            });
        }, 100);
    }
    // Video event PLAY
    function onPlay(e) {
        setVideoState({
            ...videoState,
            play: 'PLAY'
        });
        try {
            eventRemove();
            const videoState = player.current.manager.store.getState().player;

            if (parseInt(videoState.currentTime) === 0) {
                //player.current.toggleFullscreen();
                localStorageSave('previousTime', 0);
            }
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    }

    // Video 비디오 출력 현재 시간 정보
    function onTimeUpdate(e) {
        const videoState = player.current.manager.store.getState().player;
        var currentTime = e.target.currentTime;
        var previousTime = localStorageLoad('previousTime');

        if (parseInt(videoState.currentTime) - parseInt(previousTime) > 9) {
            player.current.seek(1);
        }

        localStorageSave('previousTime', currentTime);
    }

    // Video Source change..
    function videoSourceChange(paramSource, paramPoster = '') {
        if (paramPoster.length) {
            setVideoState({
                play: 'CAN_PLAY',
                src: paramSource,
                poster: paramPoster
            });
        } else {
            setVideoState({
                play: 'CAN_PLAY',
                src: paramSource,
                poster: ''
            });
        }
        setTimeout(() => {
            player.current.load();
        }, 200);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>안전교육 학습</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        안전교육 학습
                    </Typography>
                </Box>
                <Container maxWidth={false}>
                    <Card className="video MuiPaper-common">
                        <CardContent>
                            <Box>
                                <Grid
                                    container
                                    alignContent="center"
                                    alignItems={'center'}
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Grid item xl={12} md={12} sm={12} xs={12}>
                                        <Player
                                            ref={player}
                                            playsInline
                                            poster={videoState.poster}
                                            onEnded={onEndedVideo}
                                            onPlay={onPlay}
                                            onTimeUpdate={onTimeUpdate}
                                        >
                                            <source src={videoState.src} type="video/mp4" />
                                            <Shortcut clickable={false} dblclickable={false} shortcuts={null} />
                                            <ControlBar
                                                autoHide={true}
                                                disableDefaultControls={true}
                                                className={classes.btn}
                                            >
                                                <PlayToggle />
                                                <VolumeMenuButton enabled />
                                                <CurrentTimeDisplay order={10.1} />
                                                <TimeDivider order={10.2} />
                                                <RemainingTimeDisplay order={10.3} />
                                                <TimeDivider order={10.4} />
                                                <DurationDisplay order={10.5} />
                                                <FullscreenToggle order={10.9} />
                                                <ReplayControl disabled />
                                                <ForwardControl disabled />
                                            </ControlBar>
                                            <LoadingSpinner />
                                        </Player>
                                    </Grid>
                                </Grid>
                            </Box>
                            <hr className="marginTop-0" />
                            <Box className="note">
                                <h5>학습방법</h5>
                                <ul>
                                    <li>본 안전교육은 동영상 시청으로 진행됩니다.</li>
                                    <li>
                                        <strong>
                                            학습을 마치기 전에 종료하는 경우 처음부터 다시 시청하셔야 합니다.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>학습을 마친 후 평가 시험이 있습니다.</strong>
                                    </li>
                                    <li>집중해서 교육에 참여해 주세요.</li>
                                </ul>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
            <ISONotification notify={notify} setNotify={setNotify} />
        </React.Fragment>
    );
};

export default VideoPlayer;
