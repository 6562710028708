/* eslint-disable no-return-assign */
/* eslint-disable prefer-arrow-callback */
import React from 'react';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import AccountHandler from '../handlers/AccountHandler';
import useSessionStorage, { saveSession } from './useSessionStorage';
/** *
 * LoginForm에서 사용자 로그인 처리를 담당하는 영역
 * API Server와 통신
 * 프로그램 Menu 정보 수집
 * 쿠키 데이타 처리
 * 세션 스토리지에 데이타 처리
 * F5를 누를 경우 로그인 정보 확인
 */
export default function useSignInPublicAccount() {
    const { saveSessionStorage,saveMdmSessionStorage, clearSessionStorage } = useSessionStorage(e3.sessionKeys.LoginSign);
    const SESSION_EXPIRATION_TERM = parseFloat(process.env.REACT_APP_SESSION_EXPIRATION_TERM) || 1000 * 60 * 60 * 24;

    const saveLoginInfo = (userName, userId, password, isRemember) => {
        if (isRemember) {
            e3.setCookie('username', encodeURI('성명'));
            e3.setCookie('userid', '');
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                e3.setCookie('userpw', '');
            }
        } else {
            e3.setCookie('username', '', -1);
            e3.setCookie('userid', '', -1);
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                e3.setCookie('userpw', '', -1);
            }
        }
        e3.setCookie('remember', isRemember);
    };

    const loadLoginInfo = () => {
        const ret = { username: '', userid: '', userpw: '' };
        ret.username = decodeURI(e3.getCookie('username'));
        ret.userid = e3.getCookie('userid');
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            ret.userpw = e3.getCookie('userpw');
        } else {
            ret.userpw = '';
        }
        ret.remember = e3.getCookie('remember');
        return ret;
    };

    const SignIn = async (userName, userphone, crticNo, callback, isRemember = false) => {
        let params = DataPackage.init();
        params.Division = 'GUEST';
        params.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', userName));
        params.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', userphone));
        params.KeyValues.push(DataPackage.NewKeyValue('CRTIC_NO', e3.base64Encode(crticNo)));
        /**
         * Token Service에서 인증 확인
         * Token이 존재하면 로그인 과정을 진행한다.
         * Token session Storage에 저장을 한다.
         * 사용자 정보 가져온 후 메뉴 및 즐겨찾기 메뉴정보를 가져온다.
         */
        try {
            const response = await ISOAxios.Login(params);
            if (response && response.Token) {
                saveSession('Token', response.Token);
                let result = await AccountHandler.GetGuestProfile();

                if (result && result.JsonData && JSON.stringify(result.JsonData) !== '[]') {
                    const resultData = {};

                    let temp = JSON.parse(result.JsonData);
                    temp.map((item) => {
                        resultData[item.Key] = encodeURI(item.Value);
                    });
                    const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
                    resultData.expiration = expiration;
                    resultData.RESULT = 200;
                    saveSessionStorage(resultData, true);
                    saveLoginInfo(userName, userphone, crticNo, true);
                }
            }
            if (typeof callback === 'function') callback(response, null);
        } catch (e) {
            if (typeof callback === 'function') callback(null, e);
        }
    };

    const MdmSignIn = async (userName, userphone, crticNo, callback, isRemember = false) => {
        let params = DataPackage.init();
        params.Division = 'GUEST';
        params.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', userName));
        params.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', userphone));
        params.KeyValues.push(DataPackage.NewKeyValue('CRTIC_NO', e3.base64Encode(crticNo)));
        /**
         * Token Service에서 인증 확인
         * Token이 존재하면 로그인 과정을 진행한다.
         * Token session Storage에 저장을 한다.
         * 사용자 정보 가져온 후 메뉴 및 즐겨찾기 메뉴정보를 가져온다.
         */
        try {

            const response = await ISOAxios.MdmLogin(params);
            if (response && response.Token) {
                saveSession('Token', response.Token);
                let result = await AccountHandler.GetMdmProfile();

                if (result && result.JsonData && JSON.stringify(result.JsonData) !== '[]') {
                    const resultData = {};

                    let temp = JSON.parse(result.JsonData);
                    temp.map((item) => {
                        resultData[item.Key] = encodeURI(item.Value);
                    });
                    const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
                    resultData.expiration = expiration;
                    resultData.RESULT = 200;
                    saveMdmSessionStorage(resultData, true);
                    saveLoginInfo(userName, userphone, crticNo, true);
                }
            }
            if (typeof callback === 'function') callback(response, null);
        } catch (e) {
            if (typeof callback === 'function') callback(null, e);
        }
    };

    React.useEffect(() => {
        let msg = '';
        try {
            // 이전 로그인 정보 확인 (디버깅 결과 useEffect 보다 먼저 발생함.)
            // F5 또는 새로고침을 누른 경우 호출됨
            const prevSignInfo = e3.loadSession(e3.sessionKeys.LoginSign);
            if (prevSignInfo && prevSignInfo.EMP_ID.length > 0 && prevSignInfo.RESULT === 200) {
                if (prevSignInfo && prevSignInfo.expiration) {
                    // CHECK SESSION_EXPIRATION_TERM
                    if (new Date(prevSignInfo.expiration) > new Date()) {
                        const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
                        prevSignInfo.expiration = expiration;
                        saveSessionStorage(e3.sessionKeys.LoginSign, prevSignInfo, true); // 로그인정보 Storage 저장
                    } else {
                        clearSessionStorage(true);
                    }
                }
            }
        } catch (e) {
            msg = e.message;
        }
    });

    return {
        SignIn,
        MdmSignIn,
        loadLoginInfo,
        saveLoginInfo,
    };
}
