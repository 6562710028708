import React, { useEffect, useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISOCombobox } from '../framework/components/controls';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';
import { alertType, e3 } from '../framework/core/utils/utils';
import { Helmet } from 'react-helmet';
import ActionEvent from '../components/actionevent/ActionEvent';
import useLocalStorage from '../framework/core/hooks/useLocalStorage';
import useSignInPublicAccount from '../framework/core/hooks/useSignInPublicAccount';
import useSessionStorage from '../framework/core/hooks/useSessionStorage';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        width: '350px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9'
    },
    title: {
        marginBottom: '20px',
        fontSize: '30px',
        color: '#0058b2'
    },
    selectorContainer: {
        marginBottom: '15px'
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        color: '#666'
    },
    select: {
        width: '100%',
        padding: '5px',
        fontSize: '14px'
    },
    button: {
        width: '100%',
        marginTop: '10px',
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#0058b2',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer'
    }
}));

const MdmVendSelect = () =>{
    const classes = useStyles();
    const {localStorageSave, localStorageLoad} = useLocalStorage();
    const [scanResult, setScanResult] = useState(null);
    const navigate = useNavigate();

    const ref_REGION = useRef(ISOCombobox);
    const ref_REGION_DETAIL = useRef(ISOCombobox);
    const ref_VENDER = useRef(ISOCombobox);

    const handleRegionChange = (event) => {
        // setRegion(event.target.value);
        searchRegionDetail(event["value"]);
    };

    useEffect(() => {
        const setupBackButtonHandler = () => {
            const handleBackButton = (event) => {
                event.preventDefault();
                navigate('/m_eats');
            };
            window.addEventListener('popstate', handleBackButton);

            return () => {
                window.removeEventListener('popstate', handleBackButton);
            };
        };

        const initializeComponent = async () => {
            console.log('param: ', localStorageLoad('vender_code'));
            if (!localStorageLoad('vender_code')) {
                await searchRegion();
            } else {
                // ref가 존재하는지 확인한 후에만 setDataBinding 호출
                if (ref_REGION.current) {
                    await ref_REGION.current.setDataBinding(localStorageLoad('region_data'));
                }
                if (ref_REGION_DETAIL.current) {
                    await ref_REGION_DETAIL.current.setDataBinding(localStorageLoad('detail_data'));
                }
                if (ref_VENDER.current) {
                    await ref_VENDER.current.setDataBinding(localStorageLoad('vender_data'));
                }
            }
            setupBackButtonHandler();
        };

        initializeComponent();
    }, [navigate]);

    const handleStoreChange = (event) => {
        // setStore(event.target.value);
    };

    const handleSubmit = () => {
        console.log(ref_REGION.current.getValue().length>0);
        if(!ref_REGION.current || !ref_REGION.current.getValue().length>0){
            e3.modal.alert(alertType.Error, '지역을 선택해주세요');
        }
        else if(!ref_REGION_DETAIL.current || !ref_REGION_DETAIL.current.getValue().length>0){
            e3.modal.alert(alertType.Error, '상세지역을 선택해주세요');
        }
        else if(!ref_VENDER.current || !ref_VENDER.current.getValue().length>0){
            e3.modal.alert(alertType.Error, '가맹점을 선택해주세요');
        }
        else{
            localStorageSave('vender_code', ref_VENDER.current.getValue())
            localStorageSave('vender_name', ref_VENDER.current.getLabel())
            navigate('/mdmLogin',
                {
                    state: {
                        from: 'manual',
                        code: '',
                    }
                });
        }
    };

    // useEffect(() => {
    //     console.log('param: ', localStorageLoad('vender_code'));
    //     if(!localStorageLoad('vender_code')){
    //         searchRegion();
    //     }
    //     else{
    //         ref_REGION.current.setDataBinding(localStorageLoad('region_data'));
    //         ref_REGION_DETAIL.current.setDataBinding(localStorageLoad('detail_data'));
    //         ref_VENDER.current.setDataBinding(localStorageLoad('vender_data'));
    //     }
    // },[])

    const searchRegion = async() => {
        console.log('intro...')
        let param = DataPackage.create("MDM400", "SelectRegionListMobile");
        param.KeyValues.push(
            DataPackage.NewKeyValue("SEARCH_REGION", '')
        );
        try {
            const response = await ISOAxios.Execute(param);
            if (response && response.JsonData && JSON.stringify(response.JsonData) !== '[]') {
                if(ref_REGION.current) await ref_REGION.current.setDataBinding(response.JsonData);
                await localStorageSave('region_data', response.JsonData);
            }
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    }

    const searchRegionDetail = async (e) => {
        if(!localStorageLoad('vender_code')){
            let param = DataPackage.create("MDM400", "SelectRegionDetailListMobile");
            param.KeyValues.push(
                DataPackage.NewKeyValue("SEARCH_REGION", ref_REGION.current.getValue())
            );
            try {
                const response = await ISOAxios.Execute(param);
                if (response && response.JsonData && JSON.stringify(response.JsonData) !== '[]') {
                    if(ref_REGION_DETAIL.current) await ref_REGION_DETAIL.current.setDataBinding(response.JsonData).then(handelRegionDetailChange());
                    await localStorageSave('detail_data', response.JsonData);
                }
            } catch (error) {
                e3.modal.alert(alertType.Error, error.message);
            }
        }
    }

    const handelRegionDetailChange = async() => {
        if(!localStorageLoad('vender_code')) {
            let param = DataPackage.create("MDM400", "SelectVenderListMobile");
            param.KeyValues.push(
                DataPackage.NewKeyValue("SEARCH_REGION", ref_REGION.current.getValue())
            );
            param.KeyValues.push(
                DataPackage.NewKeyValue("SEARCH_REGION_DETAIL", ref_REGION_DETAIL.current.getValue())
            );
            try {
                const response = await ISOAxios.Execute(param);
                if (response && response.JsonData && JSON.stringify(response.JsonData) !== '[]') {
                    if(ref_VENDER.current)await ref_VENDER.current.setDataBinding(response.JsonData)
                    await localStorageSave('vender_data', response.JsonData);
                }
            } catch (error) {
                e3.modal.alert(alertType.Error, error.message);
            }
        }
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>롯데정밀화학 기숙사 식비 관리</title>
            </Helmet>
            <Box className="MainLayoutBox intro-wrap-mdm">
                <Container className={classes.root}>
                    <div className={classes.container}>
                        <h2 className={classes.title}>가맹점 직접 선택</h2>
                        <div className={classes.selectorContainer}>
                            <label className={classes.label}>지역</label>
                            <ISOCombobox ref={ref_REGION} placeholder={'시'} onChange={(e)=>handleRegionChange(e)} isAddBlank={true}/>
                            <ISOCombobox ref={ref_REGION_DETAIL}  placeholder={'군,구'} onChange={(e) => handelRegionDetailChange(e)} isAddBlank={true}/>
                        </div>
                        <div className={classes.selectorContainer}>
                            <label className={classes.label}>가맹점</label>
                            <ISOCombobox ref={ref_VENDER}  placeholder={'군,구'} isAddBlank={true}/>
                        </div>
                        <button onClick={handleSubmit} className={classes.button}>확인</button>
                    </div>

                </Container>
            </Box>
            <footer>
                <p className="copyright">COPYRIGHT LOTTE Fine Chemical Co., Ltd.</p>
            </footer>
        </React.Fragment>
    )
        ;
};

export default MdmVendSelect;
