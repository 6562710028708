import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ISOButton from './ISOButton';
// eslint-disable-next-line import/no-cycle
import { divName, e3 } from '../../core/utils/utils';

class ISOAlert extends PureComponent<> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  close = () => {
    this.setState({
      isOpen: false,
    });
    e3.ui.removeElementReconfirm(divName.alertDivName);
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">{this.props.title ? this.props.title : this.props.children}</DialogTitle> */}
          <DialogContent className="alert">
            <DialogContentText id="alert-dialog-description">
              <Alert severity={this.props.alertType}>
                <AlertTitle>{this.props.alertType}</AlertTitle>
                {/*CR40326 LFC 정보시스템담당 남영진 책임 요청사항*/}
                {/*알림 메세지 줄바꿈 처리*/}
                {/*{this.props.contents}*/}
                {this.props.contents.split("\n").map((line) => (
                  <div>{line}</div>
                ))}
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ISOButton onClick={this.close}>
              확인
            </ISOButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ISOAlert.propTypes = {
  alertType: PropTypes.string,
  contents: PropTypes.string,
};

ISOAlert.defaultProps = {
  alertType: 'error',
  contents: '내용',
};

export default ISOAlert;
