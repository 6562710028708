import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid } from '@material-ui/core';
import useLocalStorage from '../framework/core/hooks/useLocalStorage';

const MdmHome = () => {
    const navigate = useNavigate();
    const { localStorageSave, localStorageLoad, localStorageRemove } = useLocalStorage();

    useEffect(() => {
        localStorageRemove('vender_code');
        localStorageRemove('vender_name');
    }, []);

    const handleSelectionClick = () => {
        navigate('/mdmVendSelect');
    };
    const handleQrScan = () => {
        navigate('/mdmQr');
    };

    const onGuideClick = () => {
        navigate('/mdmGuide');
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>롯데정밀화학 기숙사 식비 관리</title>
            </Helmet>
            <Box className="MainLayoutBox intro-wrap-mdm">
                <Container maxWidth={false}>
                    <Grid container spacing={2} style={{height:'100px'}}>
                        <Grid item xs={12} spacing={3}>
                            <a onClick={handleQrScan} className="main_banner qrscan">
                                <h4>QR스캔</h4>
                                <p>가맹점에 비치되어있는
                                    <br /> QR코드 스캔</p>
                            </a>
                        </Grid>
                        {/* 주석 요청 - 2024-10-30 정범석 사원*/}
                        {/*<Grid item xs={6} spacing={3}>*/}
                        {/*    <a onClick={handleSelectionClick} className="main_banner manual">*/}
                        {/*        <h4>직접 선택</h4>*/}
                        {/*        <p>*/}
                        {/*            가맹점 정보*/}
                        {/*            <br/>직접선택*/}
                        {/*        </p>*/}
                        {/*    </a>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} spacing={2}>
                            <a
                                target="_blank"
                                className="main_banner guide"
                                onClick={onGuideClick}
                            >
                                <h4>사용안내</h4>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <footer>
                <p className="copyright">COPYRIGHT LOTTE Fine Chemical Co., Ltd.</p>
            </footer>
        </React.Fragment>
    );
};

export default MdmHome;

