import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Link } from '@material-ui/core';
import { e3, alertType } from 'src/framework/core/utils/utils';
import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import useSignIn from 'src/framework/core/hooks/useSignIn';

const Home = () => {
    const { saveSessionStorage, loadSessionStorage, clearSessionStorage } = useSessionStorage('loginSign');
    const { SignInMmoin } = useSignIn();
    const SESSION_EXPIRATION_TERM = parseFloat(process.env.REACT_APP_SESSION_EXPIRATION_TERM) || 1000 * 60 * 60 * 24;
    const navigate = useNavigate();
    const [values, setValues] = React.useState({
        showPassword: false,
        isLoading: false
    });

    const handleLoginClick = () => {
        navigate('/login');
    };
    /* const handleEmpLoginClick = () => {
        navigate('/empLogin');
    }; */
    const handleRequestInClick = () => {
        navigate('/privacySetting');
    };

    const mmoinSSO = () => {
        const mmoin_emp = window.sessionStorage.getItem('MMOIN_EMP');
        if (mmoin_emp) {
            window.sessionStorage.removeItem('MMOIN_EMP');
            setValues({ ...values, isLoading: true });
            SignInMmoin(mmoin_emp, (response, error) => {
                setValues({ ...values, isLoading: false });
                if (error) {
                    e3.modal.alert(alertType.Error, error.message);
                }
            });
        }
    };

    React.useEffect(() => {
        try {
            // 이전 로그인 정보 확인 (디버깅 결과 useEffect 보다 먼저 발생함.)
            // F5 또는 새로고침을 누른 경우 호출됨

            // 자동로그인
            mmoinSSO();

            const prevSignInfo = loadSessionStorage(e3.sessionKeys.LoginSign);

            if (
                (prevSignInfo && prevSignInfo.EMP_ID != undefined && prevSignInfo.RESULT === 200) ||
                (prevSignInfo && prevSignInfo.USER_ID != undefined && prevSignInfo.RESULT === 200)
            ) {
                if (prevSignInfo && prevSignInfo.expiration) {
                    // CHECK SESSION_EXPIRATION_TERM
                    if (new Date(prevSignInfo.expiration) > new Date()) {
                        const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
                        prevSignInfo.expiration = expiration;
                        saveSessionStorage(prevSignInfo, true); // 로그인정보 Storage 저장
                    } else {
                        clearSessionStorage(true);
                    }
                }
            }
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    });
    return (
        <React.Fragment>
            <Helmet>
                <title>스마트 안전출입관리시스템</title>
            </Helmet>
            <Box className="MainLayoutBox intro-wrap">
                <Container maxWidth={false}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} spacing={2}>
                            <a onClick={handleRequestInClick} className="main_banner ask">
                                <h4>방문신청</h4>
                                <p>모바일로 쉽고 빠르게 방문 신청하세요.</p>
                            </a>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <a onClick={handleLoginClick} className="main_banner search">
                                <h4>방문신청 정보 조회</h4>
                                <p>
                                    과거 방문신청 정보를 이용하여
                                    <br /> 방문신청 가능합니다.
                                </p>
                            </a>
                        </Grid>
                        {/* <Grid item xs={12} spacing={2}>
                            <a onClick={handleEmpLoginClick} className="main_banner door">
                                <h4>임직원</h4>
                            </a>
                        </Grid> */}
                        <Grid item xs={6} spacing={2}>
                            <a
                                //CR31065 LFC EQS담당 남영진 책임 요청사항
                                //모바일 안내 동영상 재생 주소 변경
                                //href="https://lottefinechem-vod.akamaized.net/visitor01guide.mp4"
                                href="https://lottefinechem-vod.akamaized.net/guide.mp4"
                                target="_blank"
                                className="main_banner guide"
                            >
                                <h4>방문안내</h4>
                            </a>
                        </Grid>
                        <Grid item xs={6} spacing={2}>
                            <a
                                href="https://www.lottefinechem.com/privacy/"
                                target="_blank"
                                className="main_banner privacy"
                            >
                                <h4>개인정보처리방침</h4>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <footer>
                <p className="copyright">COPYRIGHT LOTTE Fine Chemical Co., Ltd.</p>
            </footer>
        </React.Fragment>
    );
};

export default Home;
