import React, { useEffect, useRef } from 'react';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import { ISOInput, ISOButton } from 'src/framework/components/controls';
import { ISODialog, ISODialogTitle } from '../../framework/components/controls/ISODialog';
import { Container, Grid } from '@material-ui/core';
import Company_PopUp2 from './Company_PopUp2.js';
import { Height } from '@material-ui/icons';

/**
 * 회사찾기 - 팝업
 * @returns {JSX.Element}
 * @constructor
 */

const Company_PopUp1 = React.forwardRef((props, ref) => {
    const { callbackAction, selectedItem, jsActionClick, ...others } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const [cmpList, setCmpList] = React.useState();
    const popupTitle = '회사 조회';
    let flag = true;

    /* 1. Search Box */
    const searchCmpName = React.useRef(ISOInput);

    /* 3. Dialog */
    const company_dialog = useRef(Company_PopUp2);
    const onShow = () => {
        company_dialog.current.showDialog();
    };

    const setFindCompany = (result) => {
        onClose();
        callbackAction(result);
    };

    /* 10. function */
    // 팝업 오픈 및 조회
    React.useImperativeHandle(ref, () => ({
        showDialog() {
            setIsOpen(true);

            setTimeout(() => {
                if (selectedItem && selectedItem.length > 0) {
                    setCmpList(selectedItem);
                } else {
                    e3.modal.alert(alertType.Info, '등록된 회사 정보가 없습니다. 신규 등록해주세요.');
                }
            }, 600);
        }
    }));

    // 팝업 종료
    const onClose = () => {
        setIsOpen(false);
    };

    // 리스트 조회
    const onSearch = async () => {
        let cmpname = e3.string.stringCmpCheck(searchCmpName.current.getValue());

        if (cmpname.length > 1) {
            let param = DataPackage.init();

            if (isNaN(cmpname)) {
                //글자-회사명
                param.KeyValues.push(DataPackage.NewKeyValue('CMP_NM', cmpname));
            } else {
                //숫자-사업자번호
                param.KeyValues.push(DataPackage.NewKeyValue('CMP_NO', cmpname));
            }
            try {
                let response = await ISOAxios.GetCmpList(param);
                setCmpList(JSON.parse(response.JsonData));
            } catch (err) {
                e3.modal.alert(alertType.Error, err.message);
            }
        } else {
            e3.modal.alert(alertType.Info, '특수문자 외 2글자 이상 검색어를 입력해주세요.');
        }
    };

    // 선택
    const onSelect = (id, name) => {
        let result = {
            CMP_ID: id,
            CMP_NM: name
        };
        onClose();
        callbackAction([result]);
    };

    return (
        <>
            <React.Fragment>
                <ISODialog
                    isOpen={isOpen}
                    onClose={onClose}
                    isReasize={false}
                    isDraggable={false}
                    dialogId="COMPANY_POPUP1_Dialog"
                    fullScreen
                    fullWidth={true}
                >
                    <ISODialogTitle onClose={onClose} id="COMPANY_POPUP1_Dialog">
                        {popupTitle}
                    </ISODialogTitle>
                    <div className="MuiContainer-root">
                        <div className="isodialog_search combo-input-botton">
                            <ISOInput
                                ref={searchCmpName}
                                placeholder={'회사명 & 사업자번호'}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        searchCmpName.current.setValue(e3.string.stringCmpCheck(e.target.value));
                                    }
                                }}
                            />
                            <ISOButton type="button" color="secondary" fullWidth={true} onClick={onSearch}>
                                조회
                            </ISOButton>
                        </div>
                    </div>
                    <br />
                    <Container maxWidth={true}>
                        <Grid container spacing={2} style={{ Height: '100px' }}>
                            <div className="result_list modal_result_scroll">
                                <table>
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: '40px',
                                                    height: '20px'
                                                }}
                                            >
                                                선택
                                            </th>
                                            <th
                                                style={{
                                                    width: '50px'
                                                }}
                                            >
                                                사업장
                                            </th>
                                            <th
                                                style={{
                                                    width: '130px'
                                                }}
                                            >
                                                회사
                                            </th>
                                            <th
                                                style={{
                                                    width: '100px'
                                                }}
                                            >
                                                사업자번호
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cmpList && cmpList != ''
                                            ? cmpList.map((item) => (
                                                  <tr>
                                                      <td>
                                                          <ISOButton
                                                              color="secondary"
                                                              size="small"
                                                              fullWidth={false}
                                                              onClick={() => {
                                                                  onSelect(item.CMP_ID, item.CMP_NM);
                                                              }}
                                                          >
                                                              선택
                                                          </ISOButton>
                                                      </td>
                                                      <td>{item.PLANT_NAME}</td>
                                                      <td>{item.CMP_NM}</td>
                                                      <td>{item.CMP_NO}</td>
                                                  </tr>
                                              ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            <ISOButton color="primary" type="button" size="large" onClick={onShow} className="btn_wrap">
                                회사 신규 등록
                            </ISOButton>
                        </Grid>
                    </Container>
                </ISODialog>
                <Company_PopUp2 ref={company_dialog} callbackAction={setFindCompany} />
            </React.Fragment>
        </>
    );
});

export default Company_PopUp1;
