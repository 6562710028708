import React from 'react';

const Logo = (props) => (
  <img
    className="logo"
    alt="Logo"
    src={`${process.env.PUBLIC_URL}/static/images/logo.png`}
    {...props}
  />
);

export default Logo;
