import React, { useRef } from 'react';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import { ISOInput, ISOButton } from 'src/framework/components/controls';
import { Container, Grid } from '@material-ui/core';
import { ISODialog, ISODialogTitle } from '../../framework/components/controls/ISODialog';

/**
 * 사용자 찾기 - 팝업
 * @returns {JSX.Element}
 * @constructor
 */

const User_PopUp = React.forwardRef((props, ref) => {
    const { callbackAction, selectedItem, ...others } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const [userList, setUserList] = React.useState();
    const popupTitle = '담당자 검색';

    /* 1. Search Box */
    const searchMngName = useRef(ISOInput);

    /* 10. function */
    // 팝업 오픈 및 조회
    React.useImperativeHandle(ref, () => ({
        showDialog() {
            setIsOpen(true);

            setTimeout(() => {
                if (selectedItem && selectedItem.length > 0) {
                    setUserList(selectedItem);
                }
            }, 600);
        }
    }));

    // 팝업 종료
    const onClose = () => {
        setIsOpen(false);
    };

    // 리스트 조회
    const onSearch = async () => {
        let userName = e3.string.stringStrCheck(searchMngName.current.getValue());
        if (userName == '') {
            e3.modal.alert(alertType.Info, '담당자명을 입력해 주세요');
            return;
        } else if (userName.length < 2) {
            e3.modal.alert(alertType.Info, '특수문자 외 2글자 이상 검색어를 입력해주세요.');
            return;
        }
        let param = DataPackage.init();
        param.KeyValues.push(DataPackage.NewKeyValue('USER_NAME', userName));

        let result = await ISOAxios.GetMngList(param);
        result = JSON.parse(result.JsonData);
        if (result) {
            setUserList(result);
        }
        if (result) {
        } else {
            e3.modal.alert(alertType.Info, '검색하신 이름이 없습니다. 관리자에게 문의해주세요');
        }
    };

    // 선택
    const onSelect = (plantid, userid, username, depid) => {
        let result = {
            PLANT_ID: plantid,
            USER_ID: userid,
            USER_NAME: username,
            DEPART_ID: depid || ''
        };
        onClose();
        callbackAction([result]);
    };

    return (
        <>
            <React.Fragment>
                <ISODialog
                    isOpen={isOpen}
                    onClose={onClose}
                    isReasize={false}
                    isDraggable={false}
                    dialogId="USER_POPUP_Dialog"
                    fullScreen
                    fullWidth={true}
                >
                    <ISODialogTitle onClose={onClose} id="USER_POPUP_Dialog">
                        {popupTitle}
                    </ISODialogTitle>
                    <div className="MuiContainer-root">
                        <div className="isodialog_search combo-input-botton">
                            <ISOInput
                                ref={searchMngName}
                                placeholder={'담당자명'}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        searchMngName.current.setValue(e3.string.stringStrCheck(e.target.value));
                                    }
                                }}
                            />
                            <ISOButton type="button" color="secondary" fullWidth={true} onClick={onSearch}>
                                조회
                            </ISOButton>
                        </div>
                    </div>
                    <br />
                    <Container maxWidth={true}>
                        <Grid container spacing={2} style={{ Height: '100px' }}>
                            <div className="result_list modal_result_scroll">
                                <table>
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: '40px',
                                                    height: '20px'
                                                }}
                                            >
                                                선택
                                            </th>
                                            <th
                                                style={{
                                                    width: '100px'
                                                }}
                                            >
                                                부서/회사
                                            </th>
                                            <th
                                                style={{
                                                    width: '110px'
                                                }}
                                            >
                                                전화번호
                                            </th>
                                            <th
                                                style={{
                                                    width: '60px'
                                                }}
                                            >
                                                이름
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList && userList != ''
                                            ? userList.map((item) => (
                                                  <tr>
                                                      <td>
                                                          <ISOButton
                                                              color="secondary"
                                                              size="small"
                                                              fullWidth={false}
                                                              onClick={() => {
                                                                  onSelect(
                                                                      item.PLANT_ID,
                                                                      item.USER_ID,
                                                                      item.USER_NAME,
                                                                      item.DEPART_ID || ''
                                                                  );
                                                              }}
                                                          >
                                                              선택
                                                          </ISOButton>
                                                      </td>
                                                      <td>{item.DEPART_NAME}</td>
                                                      <td>{item.USER_TEL_MASK}</td>
                                                      <td>{item.USER_NAME_MASK}</td>
                                                  </tr>
                                              ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Container>
                </ISODialog>
            </React.Fragment>
        </>
    );
});

export default User_PopUp;
