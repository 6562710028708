import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { red } from '@material-ui/core/colors';
import { phoneNumberFomatter } from 'src/framework/core/utils/utils';
import ActionEvent from '../actionevent/ActionEvent';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import moment from 'moment';

const AccountProfile = (props) => {
    const userValue = ActionEvent.GetUser();
    const visitYn = `${decodeURI(userValue?.STATUS)}` == 'C' ? 'Y' : 'N';
    const startVisitDate = userValue.START_DATE
        ? moment(`${decodeURI(userValue.START_DATE)}`).format('YY-MM-DD HH:mm')
        : '';
    const endVisitDate =
        userValue.END_DATE != 'null' ? moment(`${decodeURI(userValue.END_DATE)}`).format('YY-MM-DD HH:mm') : '기간없음';
    const visitPlateName = userValue.PLANT_NAME ? `${decodeURI(userValue.PLANT_NAME)}` : '';
    const managerName = userValue.MANAGER_NAME ? `${decodeURI(userValue.MANAGER_NAME)}` : '';
    const visitPlace = userValue.VISIT_PLACE_NAME != 'null' ? `${decodeURI(userValue.VISIT_PLACE_NAME)}` : '-'; //VISIT_PLACE
    const parkingArea = userValue.PARKING_AREA != 'null' ? `${decodeURI(userValue.PARKING_AREA)}` : '-'; //PARKING_AREA

    return (
        <Card {...props}>
            <CardContent>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">
                                {userValue.REQ_TYPE == 'REQ_TYPE_001' ? '출입승인 여부' : '방문승인 여부'}
                            </span>{' '}
                            {visitYn == 'Y' ? (
                                <span className="dashboad_value Y">
                                    <i>
                                        <em>승인</em>
                                    </i>
                                </span>
                            ) : (
                                <span className="dashboad_value N">
                                    <i>
                                        <em>미승인</em>
                                    </i>
                                </span>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">
                                {userValue.REQ_TYPE == 'REQ_TYPE_001' ? '출입기간' : '방문기간'}
                            </span>{' '}
                            <span className="dashboad_value">
                                {startVisitDate} ~ {endVisitDate}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">
                                {userValue.REQ_TYPE == 'REQ_TYPE_001' ? '출입사업장' : '방문사업장'}
                            </span>{' '}
                            <span className="dashboad_value">{visitPlateName}</span>
                        </Typography>
                    </Grid>
                </Grid>
                {userValue.REQ_TYPE == 'REQ_TYPE_001' ? null : (
                    <>
                        {' '}
                        <Grid container spacing={3} className="BoxBetween">
                            <Grid item>
                                <Typography color="textSecondary" gutterBottom variant="h4">
                                    <span className="dashboad_label">담당자</span>{' '}
                                    <span className="dashboad_value">{managerName}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="BoxBetween">
                            <Grid item>
                                <Typography color="textSecondary" gutterBottom variant="h4">
                                    <span className="dashboad_label">방문장소</span>{' '}
                                    <span className="dashboad_value">{visitPlace}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">주차장</span>{' '}
                            <span className="dashboad_value">{parkingArea}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default AccountProfile;
