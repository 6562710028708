import React from 'react';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import { alertType, e3 } from '../utils/utils';
import MenuHandler from './MenuHandler';
const SESSION_EXPIRATION_TERM = parseFloat(process.env.REACT_APP_SESSION_EXPIRATION_TERM) || 1000 * 60 * 60 * 24;

const AccountHandler = {
    RegistGuest: async (
        userName,
        userPhone,
        cmpId,
        carcheck,
        carNo,
        carType,
        carDtl,
        plantId,
        mngId,
        mngDepart,
        startDay,
        startTime,
        endDay,
        endTime,
        inType,
        visitcheck,
        inDtl,
        inItem,
        localcheck,
        hardhatcheck,
        privacynum,
        isPrivacy,
        callback
    ) => {
        let result = {};
        try {
            let param = DataPackage.init();
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', e3.string.stringStrCheck(userName)));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', e3.string.stringNumCheck(userPhone)));
            param.KeyValues.push(DataPackage.NewKeyValue('CMP_ID', cmpId));
            param.KeyValues.push(DataPackage.NewKeyValue('carcheck', carcheck));
            if (carcheck == 'Y') {
                param.KeyValues.push(DataPackage.NewKeyValue('CAR_NUM', e3.string.stringCarNumCheck(carNo) || ''));
                param.KeyValues.push(DataPackage.NewKeyValue('CAR_TYPE', carType || ''));
                param.KeyValues.push(DataPackage.NewKeyValue('CAR_DETAIL', carDtl || ''));
            }
            param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', plantId));
            param.KeyValues.push(DataPackage.NewKeyValue('MANAGER', mngId));
            param.KeyValues.push(DataPackage.NewKeyValue('MANAGER_DEPT', mngDepart || ''));
            param.KeyValues.push(
                DataPackage.NewKeyValue('START_DATE', startDay.replace(/-/gi, '') + startTime.replace(/:/gi, '') + '00')
            );
            param.KeyValues.push(
                DataPackage.NewKeyValue('END_DATE', endDay.replace(/-/gi, '') + endTime.replace(/:/gi, '') + '00')
            );
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_CD', inType || ''));
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_PURPOSE', inDtl || ''));
            if (visitcheck) {
                param.KeyValues.push(DataPackage.NewKeyValue('LOCAL_YN', localcheck || ''));
                param.KeyValues.push(DataPackage.NewKeyValue('HARDHAT_YN', hardhatcheck || ''));
            }
            param.KeyValues.push(DataPackage.NewKeyValue('IN_ITEM', inItem || '없음'));
            param.KeyValues.push(DataPackage.NewKeyValue('CRTIC_NO', e3.string.stringNumCheck(privacynum)));
            param.KeyValues.push(DataPackage.NewKeyValue('PRIVACY_CONSENT_DT', isPrivacy == true ? 'Y' : 'N'));

            result = await ISOAxios.RegistGuest(param);
            result = JSON.parse(result.JsonData);
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        } finally {
            if (callback) {
                callback(result);
            }
        }
    },
    Login: async (username, password, isRemember) => {
        const dataPackage = DataPackage.create();
        dataPackage.KeyValues.push(DataPackage.NewKeyValue('username', username));
        dataPackage.KeyValues.push(DataPackage.NewKeyValue('password', password));

        let result = await ISOAxios.Login(dataPackage);
        if (result.Token) {
            ISOAxios.SetToken(result.Token);
        } else {
            throw new Error('Token is null');
        }

        let datamodel = DataPackage.InitSystemDataPackage();
        datamodel.MethodName = 'GetUserProfile';
        datamodel.KeyValues.push(DataPackage.NewKeyValue('USER_ID', username));

        let userProfile = await ISOAxios.Execute(datamodel);

        const resultData = JSON.parse(userProfile.JsonData)[0];

        let menusaveResult = await MenuHandler.Save(resultData.USER_ID, resultData.PLT_ID);

        await StorageHandler.SetLocalStorage('MENU', menusaveResult.JsonData);

        MenuHandler.GetFavoritMenu(resultData.USER_ID, resultData.PLT_ID);
        StorageHandler.SetSessionStorage(resultData, true);
        AccountHandler.SaveLoginInfo(username, password, isRemember);
    },
    //CR40326 LFC 정보시스템담당 남영진 책임 요청사항
    //출입관리 위반인원 관리 기능 보완
    //RequestCertificationCode: async (userName, userPhone) => {
    RequestCertificationCode: async (userName, userPhone, startDate, endDate) => {
        try {
            let param = DataPackage.init();

            param.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', userName));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', userPhone));
            //CR40326 LFC 정보시스템담당 남영진 책임 요청사항
            //출입관리 위반인원 관리 기능 보완
            param.KeyValues.push(DataPackage.NewKeyValue('START_DATE', startDate));
            param.KeyValues.push(DataPackage.NewKeyValue('END_DATE', endDate));

            //CR40326 LFC 정보시스템담당 남영진 책임 요청사항
            //출입관리 위반인원 관리 기능 보완
            //const responseData = ISOAxios.RequestCertificationCode(param);

            //if (responseData?.ErrorMessage && resultData.ErrorMessage.length > 0) {
            //    throw new Error(resultData?.ErrorMessage);
            //}

            const responseData = await ISOAxios.RequestCertificationCode(param);

            if (responseData?.ErrorMessage && responseData?.ErrorMessage.length > 0) {
                e3.modal.alert(alertType.Warning, responseData?.ErrorMessage);
            }
            return responseData;
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    },

    /**
     * 모바일 식수관리 로그인용 인증번호 생성
     * */
    RequestMdmCertificationCode: async (userName, userPhone) => {
        try {
            let param = DataPackage.init();

            param.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', userName));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', userPhone));

            const responseData = await ISOAxios.RequestMdmCertificationCode(param);

            if (responseData?.ErrorMessage && responseData?.ErrorMessage.length > 0) {
                e3.modal.alert(alertType.Warning, responseData?.ErrorMessage);
            }
            return responseData;
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    },
    GetGuestProfile: async () => {
        let param = DataPackage.InitSystemDataPackage();
        param.MethodName = 'GetGuestProfile';
        return await ISOAxios.Execute(param);
    },
    GetMdmProfile: async() => {

        let param = DataPackage.InitSystemDataPackage();
        param.MethodName = 'GetMdmGuestProfile';
        return await ISOAxios.Execute(param);
    },
    SaveLoginInfo: (userid, password, isRemember) => {}
};

export default AccountHandler;
