//CR36941 LFC 디지털혁신팀 홍승재 수석 요청사항
//출입관리 보안 취약점 조치 - SALT 값 노출 제거
//import useBase64 from './useBase64';
import { e3, alertType } from 'src/framework/core/utils/utils';

/**
 * 쿠키 데이타 처리
 * @param {*} name
 */
export default function useCookieData() {
    //CR36941 LFC 디지털혁신팀 홍승재 수석 요청사항
    //출입관리 보안 취약점 조치 - SALT 값 노출 제거
    //const { encryptAES, decryptAES } = useBase64();

    const setCookie = (name, value, days = 7) => {
        const expires = new Date();
        expires.setDate(expires.getDate() + days);
        document.cookie = `${name}=${e3.base64Encode(value)}; expires=${expires.toUTCString()}; path=/`;
    };
    const getCookie = (name) => {
        try {
            const cname = `${name}=`;
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(cname) === 0) {
                    return e3.base64Decode(c.substring(cname.length, c.length));
                }
            }
            return '';
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };

    //CR36941 LFC 디지털혁신팀 홍승재 수석 요청사항
    //출입관리 보안 취약점 조치 - SALT 값 노출 제거
    //const setCookieAES = (name, value, days = 7) => {
    //    const expires = new Date();
    //    expires.setDate(expires.getDate() + days);
    //    document.cookie = `${name}=${encryptAES(value)}; expires=${expires.toUTCString()}; path=/`;
    //};
    //CR36941 LFC 디지털혁신팀 홍승재 수석 요청사항
    //출입관리 보안 취약점 조치 - SALT 값 노출 제거
    //const getCookieAES = (name) => {
    //    try {
    //        const cname = `${name}=`;
    //        const ca = document.cookie.split(';');
    //        for (let i = 0; i < ca.length; i++) {
    //            let c = ca[i];
    //            while (c.charAt(0) === ' ') {
    //                c = c.substring(1);
    //            }
    //            if (c.indexOf(cname) === 0) {
    //                return decryptAES(c.substring(cname.length, c.length));
    //            }
    //        }
    //        return '';
    //    } catch (e) {
    //        e3.modal.alert(alertType.Error, e.message);
    //    }
    //};

    return {
        setCookie,
        getCookie,
        //CR36941 LFC 디지털혁신팀 홍승재 수석 요청사항
        //출입관리 보안 취약점 조치 - SALT 값 노출 제거
        //setCookieAES,
        //getCookieAES
    };
}

export function saveCookie(name, value, days = 7) {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie = `${name}=${base64.encode(value)}; expires=${expires.toUTCString()}; path=/`;
}
export function loadCookie(name) {
    try {
        const cname = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cname) === 0) {
                return base64.decode(c.substring(cname.length, c.length));
            }
        }
        return '';
    } catch (e) {
        e3.modal.alert(alertType.Error, e.message);
    }
}
