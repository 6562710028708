/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch } from 'react-redux';
import base64 from 'base-64';
import { login, logout } from '../reducers/loginReducer';
import { e3, alertType } from 'src/framework/core/utils/utils';

const StorageHandler = {
    callLogin: (data) => useDispatch().dispatcher(login(data)),
    callLogout: () => useDispatch().dispatcher(logout()),
    base64Encode: (data) => base64.encode(data),
    base64Decode: (data) => base64.decode(data),
    ClearSessionStorage: (isLogoutReducer = false) => {
        window.sessionStorage.clear();
        if (isLogoutReducer) StorageHandler.callLogout();
    },
    SetSessionStorage: (name, value, isLoginReducer = false) => {
        const val = typeof value !== 'string' ? JSON.stringify(value) : value;
        window.sessionStorage.setItem(name, StorageHandler.base64Encode(val));
        if (isLoginReducer) StorageHandler.callLogin(value);
    },
    GetSessionStorage: (name) => {
        const tmp = window.sessionStorage.getItem(name);
        const ret = tmp && tmp.length > 0 ? JSON.parse(StorageHandler.base64Decode(tmp.toString())) : undefined;
        return ret;
    },
    SetLocalStorage: (name, value) => {
        try {
            const data = typeof value !== 'string' ? JSON.stringify(value) : value;
            localStorage.setItem(name, data);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetLocalStorage: (name) => {
        try {
            return localStorage.getItem(name);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    RemoveLocalStorage: (name) => {
        try {
            localStorage.removeItem(name);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    }
};

export default StorageHandler;
