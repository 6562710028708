import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Videocam from '@material-ui/icons/Videocam';

const styles = (theme) => ({
    input: {
        display: 'none'
    }
});

/**
 * 사진의 캡쳐 화질이 떨어질 경우 사용을 위해 테스트
 */
class ImageUpload extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            result: '',
            images: [],
            videos: [],
        };
    }
    handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            this.setState((prevState) => ({
                [name]: [...prevState[name], e.target.result]
            }));
        };
    };

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    getFile = () => {
        if (this.state.type === 'image') 
            return this.state.images;
        else
            return this.state.videos;
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                {(this.state.type === 'image') ? (
                <span>
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-photo"
                        onChange={this.handleCapture}
                        type="file"
                    />
                    <label htmlFor="icon-button-photo">
                        <IconButton color="primary" component="span">
                            <PhotoCamera />
                        </IconButton>
                    </label>
                </span>
                ) : (
                <span>
                    <input
                        accept="video/*"
                        capture="camcorder"
                        className={classes.input}
                        id="icon-button-video"
                        onChange={this.handleCapture}
                        type="file"
                    />
                    <label htmlFor="icon-button-video">
                        <IconButton color="primary" component="span">
                            <Videocam />
                        </IconButton>
                    </label>
                </span>
                )}
            </Fragment>
        );
    }
}

ImageUpload.propTypes = {
    type: PropTypes.string,
};
ImageUpload.defaultProps = {
    type: 'image',
};

export default withStyles(styles, { withTheme: true })(ImageUpload);