import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    Checkbox,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import { ISOTextField, ISODateTime, ISOButton } from 'src/framework/components/controls';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AccountHandler from 'src/framework/core/handlers/AccountHandler';
import { useSelector } from 'react-redux';
import useSignInPublicAccount from 'src/framework/core/hooks/useSignInPublicAccount';
import Company_PopUp1 from './popup/Company_PopUp1.js';
import User_PopUp from './popup/User_PopUp.js';
import Car_PopUp from './popup/Car_PopUp.js';
import Privacy_PopUp from './Settings.js';

const ApplicationForEntry = (props) => {
    const userInfo = useSelector((state) => state.user);
    const { SignIn, loadLoginInfo } = useSignInPublicAccount();
    const { userid, userpw, remember } = loadLoginInfo();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState({
        isLoading: false
    });
    const [cmpid, setCmpId] = React.useState(); // 회사 코드
    const [mngInfo, setMngInfo] = React.useState({ mngId: '', mngDepart: '' });
    const [visitcheck, setVisitCheck] = React.useState(true);
    const [addType, setAddType] = React.useState('');

    // 페이지 체크 유무 (visithistory or visithistoryview에서 이동했는지 Home에서 이동했는지 체크)
    const [pageCheck, setPageCheck] = React.useState(false);

    const [initemCheck, setInitemCheck] = React.useState(false);
    const [privacycheck, setPrivacyCheck] = React.useState(true);
    const [carnumcheck, setCarNumCheck] = React.useState(true);
    const [carcheck, setCarCheck] = React.useState('N');
    const [localcheck, setLocalCheck] = React.useState('LOCAL_Y');
    const [hardhatcheck, setHardhatCheck] = React.useState('HARDHAT_N');
    const [modifyFlag, setModifyFlag] = React.useState(false);

    const refplantId = useRef(ISOTextField);
    const refmngName = useRef(ISOTextField);
    const refprivacynum = useRef(ISOTextField);
    const refstartDay = useRef(ISODateTime);
    const refstartTime = useRef(ISOTextField);
    const refendDay = useRef(ISODateTime);
    const refendTime = useRef(ISOTextField);
    const refinType = useRef(ISOTextField);
    const refinDtl = useRef(ISOTextField);
    const refinItem = useRef(ISOTextField);
    const refuserName = useRef(ISOTextField);
    const refuserPhone = useRef(ISOTextField);
    const refcmpName = useRef(ISOTextField);
    const refcarNo = useRef(ISOTextField);
    const refcarType = useRef(ISOTextField);
    const refcarDtl = useRef(ISOTextField);

    const privacyChange = () => {
        if (privacycheck == true) {
            onPrivacyShow();
        }
    };

    React.useEffect(() => {
        setLoading(true);
        if (location.state) {
            if (location.state.flag == true) {
                setPageCheck(true);
                onTimeSet();
                onUserInfo();
            } else {
                setPageCheck(true);
                onTimeSet();
                onUserInfo();
            }
        } else {
            setPageCheck(false);
            onTimeSet();
            init();
            onPrivacyShow();
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const onVisitPorposSet = async (value) => {
        let params = DataPackage.init();
        params.KeyValues.push(DataPackage.NewKeyValue('codeId', value));
        let result = (await ISOAxios.GetCodes(params)).JsonData;
        if (result) {
            refinDtl.current.setDataBinding(result);
        }
    };

    const onTimeSet = async () => {
        let params = DataPackage.init();
        params.KeyValues.push(DataPackage.NewKeyValue('codeId', 'TIME_CD'));
        params.KeyValues.push(DataPackage.NewKeyValue('time', 'Y'));

        let result = (await ISOAxios.GetCodes(params)).JsonData;
        if (result) {
            refstartTime.current.setDataBinding(result);
            refendTime.current.setDataBinding(result);
        }
    };

    const init = () => {
        refstartTime.current.setValue('00:00');
        refplantId.current.setValue('PLNT100');
        refinType.current.setValue('VISIT100');
        refinDtl.current.setValue('VISIT100_010');
        refendTime.current.setValue('23:30');
    };
    /*  방문신청정보 페이지에서 넘어올 경우 데이터 채워줘야함 */
    const onUserInfo = async () => {
        try {
            let param = DataPackage.init();
            param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', location.state.usePlantId));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', location.state.useEmpNm));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', location.state.useEmpTelNo));
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', location.state.req));

            let result = await ISOAxios.GetUserInfo(param);
            result = JSON.parse(result.JsonData);
            if (result && result.length > 0) {
                onUserInfoSet(result);
            } else {
                e3.modal.alert(alertType.Info, '검색하신 정보가 없습니다.');
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    const onUserInfoSet = (info) => {
        setAddType(info[0].ADD_TYPE);

        refplantId.current.setValue(info[0].PLANT_ID);
        refmngName.current.setValue(info[0].MANAGER_NAME);
        setMngInfo({ mngId: info[0].MANAGER, mngDepart: info[0].MANAGER_DEPT });

        refstartDay.current.setDate(new Date(moment().format('YYYY-MM-DD')));
        refstartTime.current.setValue('00:00');
        refendDay.current.setDate(new Date(moment().format('YYYY-MM-DD')));
        refendTime.current.setValue('23:30');
        refinType.current.setValue(info[0].VISIT_CD);

        onVisitPorposSet(info[0].VISIT_CD);
        refinDtl.current.setValue(info[0].VISIT_PURPOSE);

        refinItem.current.setValue(info[0].IN_ITEM);
        refuserName.current.setValue(info[0].EMP_NM);
        refuserName.current.disable();
        refuserPhone.current.setValue(info[0].EMP_TEL_NO);
        refuserPhone.current.disable();
        setCmpId(info[0].CMP_ID);
        refcmpName.current.setValue(info[0].CMP_NM);

        if (info[0].CAR_NUM) {
            setCarCheck('Y');
            refcarNo.current.setValue(info[0].CAR_NUM);
            refcarType.current.setValue(info[0].CAR_TYPE);
            refcarDtl.current.setValue(info[0].CAR_DETAIL);
        }
        if (info[0].VISIT_CD == 'VISIT100') {
            setVisitCheck(true);
            setLocalCheck(info[0].LOCAL_YN);
            setHardhatCheck(info[0].HARDHAT_YN);
        } else {
            setVisitCheck(false);
        }

        let privacyday = moment(info[0].PRIVACY_CONSENT_DT).add(1, 'year').format('YYYY-MM-DD HH:mm:ss');
        let nowday = moment().format('YYYY-MM-DD HH:mm:ss');
        if (privacyday < nowday) {
            onPrivacyShow();
        }
        // 수정모드 시 DISABLE
        if (location.state.flag == true) {
            if (info[0].ADD_TYPE == 'ADD_TYPE_01' || info[0].ADD_TYPE == 'ADD_TYPE_03') {
                //직접신청 CASE
                setModifyFlag(true);
            } else if (info[0].ADD_TYPE == 'ADD_TYPE_02' || info[0].ADD_TYPE == 'ADD_TYPE_04') {
                //대리신청 CASE
                setModifyFlag(true);
                if (info[0].CAR_NUM) {
                    setCarCheck('Y');
                    refcarNo.current.disable();
                    refcarType.current.disable();
                    refcarDtl.current.disable();
                }
                refinType.current.disable();
            }
        }
    };

    /* Dialog-Privacy */
    const privacy_dialog = useRef(Privacy_PopUp);
    const onPrivacyShow = async () => {
        await privacy_dialog.current.showDialog();
    };

    /* Dialog-Company */
    const [selectedCompany, setSelectedCompany] = React.useState();
    const company_dialog = useRef(Company_PopUp1);
    const onCmpShow = async () => {
        await company_dialog.current.showDialog();
    };
    const openFindCompany = (result) => {
        setSelectedCompany(result);
        onCmpShow();
    };
    const setFindCompany = (result) => {
        setCmpId(result[0].CMP_ID);
        refcmpName.current.setValue(result[0].CMP_NM);
    };

    /* Dialog-User */
    const [selectedUser, setSelectedUser] = React.useState();
    const user_dialog = useRef(User_PopUp);
    const onUserShow = async () => {
        await user_dialog.current.showDialog();
    };
    const openFindUser = (result) => {
        setSelectedUser(result);
        onUserShow();
    };
    const setFindUser = (result) => {
        refplantId.current.setValue(result[0].PLANT_ID);
        refmngName.current.setValue(result[0].USER_NAME);
        setMngInfo({ mngId: result[0].USER_ID, mngDepart: result[0].DEPART_ID });
    };

    /* Dialog-Car */
    /* const [selectedCar, setSelectedCar] = React.useState();
    const car_dialog = useRef(Car_PopUp);
    const onCarShow = async () => {
        await car_dialog.current.showDialog();
    };
    const openFindCar = (result) => {
        setSelectedCar(result);
        onCarShow();
    };
    const setFindCar = (result) => {
        refcarNo.current.setValue(result[0].CAR_NUM);
        refcarType.current.setValue(result[0].CAR_TYPE);
        refcarDtl.current.setValue(result[0].CAR_DETAIL);
    }; */

    // ※ 컬럼 정해지면 수정 반드시 해야함 ※
    const onMngName = async () => {
        let mngName = e3.string.stringStrCheck(refmngName.current.getValue()) || '';
        if (mngName == '') {
            e3.modal.alert(alertType.Info, '담당자명을 입력해 주세요');
            return;
        } else if (mngName.length < 2) {
            e3.modal.alert(alertType.Info, '특수문자 외 2글자 이상 검색어를 입력해주세요.');
            return;
        }

        try {
            let mngname = refmngName.current.getValue();
            let resultData = e3.string.stringStrCheck(mngname);

            let param = DataPackage.init();
            param.KeyValues.push(DataPackage.NewKeyValue('USER_NAME', resultData));

            let result = await ISOAxios.GetMngList(param);
            result = JSON.parse(result.JsonData);
            if (result && result.length > 0) {
                if (result.length > 1) {
                    openFindUser(result);
                } else {
                    setFindUser(result);
                    e3.modal.alert(alertType.Info, '담당자 확인이 완료되었습니다.');
                }
            } else {
                refplantId.current.setValue('PLNT100');
                refmngName.current.setValue('');
                mngInfo.mngId = '';
                mngInfo.mngDepart = '';
                e3.modal.alert(alertType.Info, '검색하신 이름이 없습니다. 관리자에게 문의해주세요');
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    const onCmpName = async () => {
        let cmpname = e3.string.stringCmpCheck(refcmpName.current.getValue()) || '';
        if (cmpname == '' || cmpname.length < 2) {
            e3.modal.alert(alertType.Info, '특수문자 외 2글자 이상 검색어를 입력해주세요.');
            return;
        }

        let param = DataPackage.init();

        if (isNaN(cmpname)) {
            //글자-회사명
            param.KeyValues.push(DataPackage.NewKeyValue('CMP_NM', cmpname));
        } else {
            //숫자-사업자번호
            param.KeyValues.push(DataPackage.NewKeyValue('CMP_NO', cmpname));
        }
        try {
            let result = await ISOAxios.GetCmpList(param);
            result = JSON.parse(result.JsonData);
            if (result && result.length > 0) {
                if (result.length > 1) {
                    openFindCompany(result);
                } else {
                    setFindCompany(result);
                    e3.modal.alert(alertType.Info, '회사 확인이 완료되었습니다.');
                }
            } else {
                setSelectedCompany([{}]);
                openFindCompany();
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    /* const onCarNo = async () => {
        let carnum = e3.string.stringCarNumCheck(refcarNo.current.getValue()) || '';

        if (carnum == '' || carnum.length < 4) {
            e3.modal.alert(alertType.Info, '차량번호를 4자리 이상 넣어 주세요.');
            return;
        }
        try {
            let param = DataPackage.init();
            param.KeyValues.push(DataPackage.NewKeyValue('CAR_NUM', carnum));

            let result = await ISOAxios.GetCarList(param);
            result = JSON.parse(result.JsonData);
            if (result && result.length > 0) {
                if (result.length > 1) {
                    openFindCar(result);
                } else {
                    setFindCar(result);
                    e3.modal.alert(alertType.Info, '차량번호 확인이 완료되었습니다.');
                }
            } else {
                e3.modal.alert(
                    alertType.Warning,
                    '검색하신 차량은 등록되어있지 않습니다. 차량 추가를 위해 전체 차량번호와 아래 나머지 항목들을 채워주세요'
                );
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    }; */

    const onClose = () => {
        navigate('/');
    };

    const onPrivacySign = async () => {
        setLoading(true);
        let userName = e3.string.stringStrCheck(refuserName.current.getValue()) || '';
        let userPhone = e3.string.stringNumCheck(refuserPhone.current.getValue()) || '';
        if (userName == '' || userPhone == '') {
            e3.modal.alert(alertType.Warning, '이름 또는 전화번호를 정확히 기입해 주세요.');
            return;
        } else if (!e3.validation.isMobileNumber(userPhone)) {
            e3.modal.alert(alertType.Warning, '전화번호의 형태가 정확하지 않습니다.');
            return;
        }

        try {
            //CR40326 LFC 정보시스템담당 남영진 책임 요청사항
            //출입관리 위반인원 관리 기능 보완
            //const response = await AccountHandler.RequestCertificationCode(userName, userPhone);
            let startDate = refstartDay.current.getDate().replace(/-/gi, '') + refstartTime.current.getValue().replace(/:/gi, '') + '00'
            let endDate = refendDay.current.getDate().replace(/-/gi, '') + refendTime.current.getValue().replace(/:/gi, '') + '00'
            const response = await AccountHandler.RequestCertificationCode(userName, userPhone, startDate, endDate);
            if (response.KeyValues[0].Value == '200') {
                setLoading(false);
                e3.modal.alert(alertType.Info, '인증번호가 전송되었습니다.');
            }
        } catch (e) {
            e3.modal.alert(alertType.Error, e.Message);
        } finally {
            setLoading(false);
        }
    };

    const CarNumCheck = () => {
        if (e3.validation.isCarNumber(refcarNo.current.getValue())) {
            setCarNumCheck(true);
        } else {
            setCarNumCheck(false);
        }
    };

    const onCheck = async () => {
        let startday = moment(refstartDay.current.getDate() + ' ' + refstartTime.current.getValue() + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
        );
        let endtday = moment(refendDay.current.getDate() + ' ' + refendTime.current.getValue() + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
        );
        if (carcheck == 'Y') {
            CarNumCheck();
        }

        if ((refuserName.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '방문자 이름을 정확히 입력해 주세요.');
            return;
        } else if ((refuserPhone.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '방문자 휴대폰번호를 정확히 입력해 주세요.');
            return;
        } else if (pageCheck == false && (refprivacynum.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '인증번호 요청 후 정확히 입력해 주세요.');
            return;
        } else if ((refcmpName.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '회사명 또는 사업자번호를 검색해 주세요.');
            return;
        } else if (cmpid == '') {
            e3.modal.alert(alertType.Warning, '회사명 항목의 검색버튼을 눌러주세요.');

            return;
        } else if ((refplantId.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '방문사업장을 선택해 주세요.');
            return;
        } else if ((refmngName.current.getValue() || '') == '' || mngInfo.mngId == '' || mngInfo.mngDepart == '') {
            refmngName.current.setValue('');
            mngInfo.mngId == '';
            mngInfo.mngDepart == '';
            e3.modal.alert(alertType.Warning, '담당자를 찾을수 없습니다. 다시 검색해 주세요.');
            return;
        } else if (
            carcheck == 'Y' &&
            ((refcarNo.current.getValue() || '') == '' ||
                (refcarType.current.getValue() || '') == '' ||
                (refcarDtl.current.getValue() || '') == '')
        ) {
            e3.modal.alert(alertType.Warning, '차량번호, 차종, 차량상세는 필수 입력값 입니다.');
            return;
        } else if (carcheck == 'Y' && !carnumcheck) {
            e3.modal.alert(alertType.Warning, '차량 번호가 잘못되었습니다. 다시 확인해주세요.');
            return;
        } else if (startday == endtday) {
            e3.modal.alert(alertType.Warning, '시작시간와 종료시간은 같을 수 없습니다.');
            return;
        } else if (startday > endtday) {
            e3.modal.alert(alertType.Warning, '종료날짜가 시작날짜보다 작을 수 없습니다.');
            return;
        } else if ((refinType.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '방문구분을 선택해 주세요.');
            return;
        } else if ((refinDtl.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '방문목적을 선택해 주세요.');
            return;
        } else if (initemCheck == true && (refinItem.current.getValue() || '') == '') {
            e3.modal.alert(alertType.Warning, '운반할 물질을 입력해 주세요.');
            return;
        } else {
            try {
                let param = DataPackage.init();
                param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', refplantId.current.getValue()));
                param.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', refuserName.current.getValue()));
                param.KeyValues.push(
                    DataPackage.NewKeyValue('EMP_TEL_NO', e3.string.stringNumCheck(refuserPhone.current.getValue()))
                );
                param.KeyValues.push(
                    DataPackage.NewKeyValue(
                        'START_DATE',
                        refstartDay.current.getDate().replace(/-/gi, '') +
                            refstartTime.current.getValue().replace(/:/gi, '') +
                            '00'
                    )
                );
                param.KeyValues.push(
                    DataPackage.NewKeyValue(
                        'END_DATE',
                        refendDay.current.getDate().replace(/-/gi, '') +
                            refendTime.current.getValue().replace(/:/gi, '') +
                            '00'
                    )
                );
                param.KeyValues.push(DataPackage.NewKeyValue('carcheck', carcheck));
                if (carcheck == 'Y') {
                    param.KeyValues.push(DataPackage.NewKeyValue('CAR_NUM', refcarNo.current.getValue()));
                }
                param.KeyValues.push(
                    DataPackage.NewKeyValue('CRTIC_NO', pageCheck == false ? refprivacynum.current.getValue() : 'N')
                );

                if (modifyFlag == true) {
                    param.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', location.state.req));

                    let result = await ISOAxios.GetUserModCheck(param);
                    if (result && result.KeyValues[1].Value == '200') {
                        onModSubmit();
                    } else {
                        if (result?.ErrorMessage && result.ErrorMessage.length > 0) {
                            if (result && result.KeyValues[1].Value == '101') {
                                e3.modal.alert(alertType.Warning, result?.ErrorMessage);
                                return;
                            } else if (result && result.KeyValues[1].Value == '102') {
                                e3.modal.confirm('사용자 확인', result?.ErrorMessage, '등록', '취소', () => {
                                    onModSubmit();
                                });
                            }
                        }
                    }
                } else {
                    let result = await ISOAxios.GetUserCheck(param);
                    if (result && result.KeyValues[1].Value == '200') {
                        onSubmit();
                    } else {
                        if (result?.ErrorMessage && result.ErrorMessage.length > 0) {
                            if (result && result.KeyValues[1].Value == '101') {
                                e3.modal.alert(alertType.Warning, result?.ErrorMessage);
                                return;
                            } else if (result && result.KeyValues[1].Value == '102') {
                                e3.modal.confirm('사용자 확인', result?.ErrorMessage, '등록', '취소', () => {
                                    onSubmit();
                                });
                            }
                        }
                    }
                }
            } catch (err) {
                e3.modal.alert(alertType.Error, err.message);
            }
        }
    };

    const onModSubmit = async () => {
        try {
            let param = DataPackage.init();
            param.KeyValues.push(
                DataPackage.NewKeyValue('EMP_NM', e3.string.stringStrCheck(refuserName.current.getValue()))
            );
            param.KeyValues.push(
                DataPackage.NewKeyValue('EMP_TEL_NO', e3.string.stringNumCheck(refuserPhone.current.getValue()))
            );
            param.KeyValues.push(DataPackage.NewKeyValue('CMP_ID', cmpid));
            param.KeyValues.push(DataPackage.NewKeyValue('carcheck', carcheck));
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', location.state.req));
            if (carcheck == 'Y') {
                param.KeyValues.push(
                    DataPackage.NewKeyValue('CAR_NUM', e3.string.stringCarNumCheck(refcarNo.current.getValue()) || '')
                );
                param.KeyValues.push(DataPackage.NewKeyValue('CAR_TYPE', refcarType.current.getValue() || ''));
                param.KeyValues.push(DataPackage.NewKeyValue('CAR_DETAIL', refcarDtl.current.getValue() || ''));
            }
            param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', refplantId.current.getValue()));
            param.KeyValues.push(DataPackage.NewKeyValue('MANAGER', mngInfo.mngId));
            param.KeyValues.push(DataPackage.NewKeyValue('MANAGER_DEPT', mngInfo.mngDepart || ''));
            param.KeyValues.push(
                DataPackage.NewKeyValue(
                    'START_DATE',
                    refstartDay.current.getDate().replace(/-/gi, '') +
                        refstartTime.current.getValue().replace(/:/gi, '') +
                        '00'
                )
            );
            param.KeyValues.push(
                DataPackage.NewKeyValue(
                    'END_DATE',
                    refendDay.current.getDate().replace(/-/gi, '') +
                        refendTime.current.getValue().replace(/:/gi, '') +
                        '00'
                )
            );
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_CD', refinType.current.getValue() || ''));
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_PURPOSE', refinDtl.current.getValue() || ''));
            if (visitcheck) {
                param.KeyValues.push(DataPackage.NewKeyValue('LOCAL_YN', localcheck || ''));
                param.KeyValues.push(DataPackage.NewKeyValue('HARDHAT_YN', hardhatcheck || ''));
            }
            param.KeyValues.push(DataPackage.NewKeyValue('IN_ITEM', refinItem.current.getValue() || '없음'));

            let result = await ISOAxios.SetUserModify(param);
            if (result && result.KeyValues[1].Value == '200') {
                e3.modal.alert(alertType.Info, '방문수정에 성공하셨습니다.');
                navigate('/app/dashboard');
            } else {
                e3.modal.alert(alertType.Warning, '방문수정에 실패하셨습니다.');
                return;
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    const onSubmit = async () => {
        setLoading(true);
        try {
            await AccountHandler.RegistGuest(
                refuserName.current.getValue(),
                refuserPhone.current.getValue(),
                cmpid,
                carcheck,
                carcheck == 'Y' ? refcarNo.current.getValue() : '',
                carcheck == 'Y' ? refcarType.current.getValue() : '',
                carcheck == 'Y' ? refcarDtl.current.getValue() : '',
                refplantId.current.getValue(),
                mngInfo.mngId,
                mngInfo.mngDepart || '',
                refstartDay.current.getDate(),
                refstartTime.current.getValue(),
                refendDay.current.getDate(),
                refendTime.current.getValue(),
                refinType.current.getValue(),
                visitcheck,
                refinDtl.current.getValue(),
                refinItem.current.getValue(),
                localcheck,
                hardhatcheck,
                pageCheck == false ? refprivacynum.current.getValue() : 'N',
                userInfo.privacyAllow,
                (resultData) => {
                    try {
                        if (resultData?.ErrorMessage && resultData.ErrorMessage.length > 0) {
                            e3.modal.alert(alertType.Error, resultData.ErrorMessage);
                        } else {
                            SignIn(
                                refuserName.current.getValue(),
                                e3.string.stringNumCheck(refuserPhone.current.getValue()),
                                resultData[0].Value,
                                (ret, err) => {
                                    let isauth = false;
                                    try {
                                        if (err) {
                                            if (typeof err === 'string') {
                                                e3.modal.alert(alertType.Error, err);
                                            } else {
                                                e3.modal.alert(alertType.Error, '접속 오류입니다. 다시 시도해 주세요!');
                                            }
                                        }
                                        if (ret && ret.ErrorMessage && ret.ErrorMessage.length > 0) {
                                            e3.modal.alert(alertType.Error, ret.ErrorMessage);
                                        } else {
                                            isauth = true;
                                        }
                                    } catch (e) {
                                        e3.modal.alert(alertType.Error, e.message);
                                    } finally {
                                        setLoading({ ...loading, isLoading: false });
                                        if (isauth) {
                                            navigate('/app/dashboard');
                                        }
                                    }
                                },
                                true
                            );
                        }
                    } catch (err) {
                        e3.modal.alert(alertType.Error, err.message);
                    } finally {
                        setLoading({ ...loading, isLoading: false });
                    }
                }
            );
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{modifyFlag == true ? '방문수정' : '방문신청'}</title>
            </Helmet>
            <Box className={pageCheck == false ? 'MainLayoutContent page' : 'DashboardLayoutContentBox page'}>
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        {modifyFlag == true ? '방문수정' : '방문신청'}
                    </Typography>
                </Box>
                <Container maxWidth="sm">
                    <Grid container wrap="wrap">
                        <div className="radio_wrap marginTop-0">
                            <Checkbox color="primary" checked={privacycheck} name="agree" onChange={privacyChange} />
                            <label>
                                <span>
                                    <a className="bold" onClick={onPrivacyShow}>
                                        개인정보 수집 및 이용 동의
                                    </a>
                                    <br />
                                    <em className="f_note marginLeft-0">(클릭하시면 내용을 확인하실 수 있습니다.)</em>
                                </span>
                            </label>
                        </div>
                        <table>
                            <tr>
                                <td className={pageCheck == false ? 'required' : null}>
                                    <ISOTextField
                                        ref={refuserName}
                                        textlabel={'방문자명'}
                                        margin="normal"
                                        type="text"
                                        name="userName"
                                        variant="outlined"
                                        required={true}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                refuserName.current.setValue(e3.string.stringStrCheck(e.target.value));
                                            }
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={pageCheck == false ? 'required' : null}>
                                    <div className="combo-input-botton">
                                        <ISOTextField
                                            ref={refuserPhone}
                                            margin="normal"
                                            type="text"
                                            name="userPhone"
                                            variant="outlined"
                                            required={true}
                                            textlabel={'휴대폰번호'}
                                            placeholder={'숫자만 입력하세요.'}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    refuserPhone.current.setValue(
                                                        e3.string.toPhoneFormat(
                                                            e3.string.stringNumCheck(e.target.value)
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                        {pageCheck == false ? (
                                            <ISOButton
                                                type="button"
                                                color="secondary"
                                                fullWidth={true}
                                                onClick={onPrivacySign}
                                            >
                                                인증번호요청
                                            </ISOButton>
                                        ) : null}
                                    </div>
                                    <p className="f_note marginLeft-0">* 본인휴대폰 번호를 하이픈(-)없이 입력하세요.</p>
                                </td>
                            </tr>
                            {pageCheck == false ? (
                                <tr>
                                    <td className="required">
                                        <ISOTextField
                                            ref={refprivacynum}
                                            textlabel={'인증번호'}
                                            type="number"
                                            margin="normal"
                                            name="privacynum"
                                            variant="outlined"
                                            required={true}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    refprivacynum.current.setValue(
                                                        e3.string.stringNumCheck(e.target.value)
                                                    );
                                                }
                                            }}
                                        />
                                        <p className="f_note marginLeft-0">* 인증번호요청 후 정확히 기입해 주세요.</p>
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <td className="required">
                                    <div className="combo-input-botton">
                                        <ISOTextField
                                            ref={refcmpName}
                                            textlabel={'회사명'}
                                            margin="normal"
                                            name="cmpName"
                                            variant="outlined"
                                            placeholder={'회사명 또는 사업자번호로 검색'}
                                            required={true}
                                            onChange={(e) => {
                                                setCmpId('');
                                                if (e.target.value) {
                                                    refcmpName.current.setValue(
                                                        e3.string.stringCmpCheck(e.target.value)
                                                    );
                                                }
                                            }}
                                        />
                                        <ISOButton type="button" color="secondary" fullWidth={true} onClick={onCmpName}>
                                            검색
                                        </ISOButton>
                                    </div>
                                    <p className="f_note marginLeft-0">
                                        * 사업자번호가 없는 경우 '개인'으로 검색하세요.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={'required'}>
                                    <div className="combo-input-botton">
                                        <ISOTextField
                                            ref={refmngName}
                                            type="text"
                                            variant="outlined"
                                            margin="normal"
                                            name="mngName"
                                            required={true}
                                            textlabel={'담당자'}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    mngInfo.mngId = '';
                                                    mngInfo.mngDepart = '';
                                                    refmngName.current.setValue(
                                                        e3.string.stringStrCheck(e.target.value)
                                                    );
                                                }
                                            }}
                                        />
                                        <ISOButton type="button" color="secondary" fullWidth={true} onClick={onMngName}>
                                            확인
                                        </ISOButton>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refplantId}
                                        select={true}
                                        type="text"
                                        name="plantId"
                                        variant="outlined"
                                        textlabel={'방문사업장'}
                                        codeId={'PLANTID'}
                                        margin="normal"
                                        disabled={true}
                                    />
                                    <p className="f_note marginLeft-0">
                                        * 담당자 검색 시 담당자 소속사업장에 따라 바뀝니다.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className="required">
                                    <div className="date_wrap">
                                        <div className="MuiInputAdornment-root">
                                            <span>방문기간</span>
                                        </div>
                                        <div className="term">
                                            <Grid container spacing={2}>
                                                <Grid item xs={7}>
                                                    <ISODateTime
                                                        ref={refstartDay}
                                                        dateFormat={'yyyy-MM-dd'}
                                                        showMonthYearPicker={false}
                                                        minDate={new Date()}
                                                        required={true}
                                                        onFocus={(e) => e.target.blur()}
                                                        onChange={(e) => {
                                                            refendDay.current.setDate(
                                                                new Date(moment(e).format('YYYY-MM-DD'))
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <ISOTextField
                                                        ref={refstartTime}
                                                        select={true}
                                                        type="text"
                                                        name="startTime"
                                                        variant="outlined"
                                                        required={true}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <span>~</span>
                                            <Grid container spacing={2}>
                                                <Grid item xs={7}>
                                                    <ISODateTime
                                                        ref={refendDay}
                                                        minDate={new Date()}
                                                        dateFormat={'yyyy-MM-dd'}
                                                        showMonthYearPicker={false}
                                                        required={true}
                                                        onFocus={(e) => e.target.blur()}
                                                        onChange={(e) => {
                                                            if (
                                                                moment(refstartDay.current.getDate())
                                                                    .add(6, 'M')
                                                                    .format('YYYY-MM-DD') <
                                                                moment(e).format('YYYY-MM-DD')
                                                            ) {
                                                                refendDay.current.setDate(
                                                                    new Date(moment().format('YYYY-MM-DD'))
                                                                );

                                                                e3.modal.alert(
                                                                    alertType.Error,
                                                                    '6개월 이상 신청하실수 없습니다.'
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <ISOTextField
                                                        ref={refendTime}
                                                        select={true}
                                                        type="text"
                                                        name="endTime"
                                                        variant="outlined"
                                                        required={true}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className={
                                        !(modifyFlag == true && (addType == 'ADD_TYPE_02' || addType == 'ADD_TYPE_04'))
                                            ? 'required'
                                            : null
                                    }
                                >
                                    <ISOTextField
                                        ref={refinType}
                                        select={true}
                                        textlabel={'방문구분'}
                                        margin="normal"
                                        variant="outlined"
                                        name="inType"
                                        required={true}
                                        codeId={'VISIT_CD'}
                                        onChange={(e) => {
                                            if (e.target.value == 'VISIT100') {
                                                setVisitCheck(true);
                                            } else {
                                                setVisitCheck(false);
                                            }
                                            if (e.target.value == 'VISIT300' || e.target.value == 'VISIT400') {
                                                setCarCheck('Y');
                                            } else {
                                                setCarCheck('N');
                                            }
                                            onVisitPorposSet(e.target.value);
                                            refinDtl.current.setValue(e.target.value + '_010');
                                            setInitemCheck(false);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="required">
                                    <ISOTextField
                                        select={true}
                                        ref={refinDtl}
                                        textlabel={'방문목적'}
                                        margin="normal"
                                        name="inDtl"
                                        variant="outlined"
                                        codeId={'VISIT100'}
                                        required={true}
                                        onChange={(e) => {
                                            if (e.target.value == 'VISIT300_040') {
                                                setInitemCheck(true);
                                            } else {
                                                setInitemCheck(false);
                                            }
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={initemCheck == true ? 'required' : null}>
                                    <div className="date_wrap">
                                        <ISOTextField
                                            ref={refinItem}
                                            textlabel={'방문목적(상세)'}
                                            margin="normal"
                                            name="inItem"
                                            variant="outlined"
                                            required={false}
                                            placeholder={
                                                initemCheck == true ? '운반할 물질을 이곳에 입력하세요.' : null
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className={
                                        !(modifyFlag == true && (addType == 'ADD_TYPE_02' || addType == 'ADD_TYPE_04'))
                                            ? 'required'
                                            : null
                                    }
                                >
                                    <div className="radio_wrap">
                                        <div className="MuiInputAdornment-root">
                                            <span>차량유무</span>
                                        </div>
                                        <RadioGroup
                                            name="caryn"
                                            value={carcheck}
                                            onChange={(e) => {
                                                if (
                                                    !(
                                                        modifyFlag == true &&
                                                        (addType == 'ADD_TYPE_02' || addType == 'ADD_TYPE_04')
                                                    )
                                                ) {
                                                    setCarCheck(e.target.value);
                                                }
                                            }}
                                        >
                                            <div>
                                                <FormControlLabel value="N" control={<Radio />} label={'도보이용'} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <FormControlLabel value="Y" control={<Radio />} label={'차량이용'} />
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </td>
                            </tr>
                            {carcheck == 'Y' ? (
                                <>
                                    <tr>
                                        <td
                                            className={
                                                !(
                                                    modifyFlag == true &&
                                                    (addType == 'ADD_TYPE_02' || addType == 'ADD_TYPE_04')
                                                )
                                                    ? 'required'
                                                    : null
                                            }
                                        >
                                            <div className="combo-input-botton">
                                                <ISOTextField
                                                    ref={refcarNo}
                                                    textlabel={'차량번호'}
                                                    margin="normal"
                                                    name="carNo"
                                                    type="text"
                                                    variant="outlined"
                                                    placeholder={'예시) 51도1234'}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            refcarNo.current.setValue(
                                                                e3.string.stringCarNumCheck(e.target.value)
                                                            );
                                                        }
                                                    }}
                                                />
                                                {/* <ISOButton
                                                type="button"
                                                color="secondary"
                                                fullWidth={true}
                                                onClick={onCarNo}
                                            >
                                                검색
                                            </ISOButton> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className={
                                                !(
                                                    modifyFlag == true &&
                                                    (addType == 'ADD_TYPE_02' || addType == 'ADD_TYPE_04')
                                                )
                                                    ? 'required'
                                                    : null
                                            }
                                        >
                                            <ISOTextField
                                                ref={refcarType}
                                                select={true}
                                                textlabel={'차종'}
                                                margin="normal"
                                                name="carType"
                                                variant="outlined"
                                                codeId={'CAR_TYPE'}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            className={
                                                !(
                                                    modifyFlag == true &&
                                                    (addType == 'ADD_TYPE_02' || addType == 'ADD_TYPE_04')
                                                )
                                                    ? 'required'
                                                    : null
                                            }
                                        >
                                            <ISOTextField
                                                ref={refcarDtl}
                                                textlabel={'차종상세'}
                                                margin="normal"
                                                name="carDtl"
                                                variant="outlined"
                                                placeholder={'예시) SM3, K5 등'}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        refcarDtl.current.setValue(
                                                            e3.string.stringDefaltCheck(e.target.value)
                                                        );
                                                    }
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </>
                            ) : null}
                            {visitcheck == true ? (
                                <>
                                    <tr>
                                        <td className="required">
                                            <div className="radio_wrap">
                                                <div className="MuiInputAdornment-root">
                                                    <span>내국인 여부</span>
                                                </div>
                                                <RadioGroup
                                                    name="localyn"
                                                    value={localcheck}
                                                    onChange={(e) => {
                                                        setLocalCheck(e.target.value);
                                                    }}
                                                >
                                                    <div>
                                                        <FormControlLabel
                                                            value="LOCAL_Y"
                                                            control={<Radio />}
                                                            label={'내국인'}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <FormControlLabel
                                                            value="LOCAL_N"
                                                            control={<Radio />}
                                                            label={'외국인'}
                                                        />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="required">
                                            <div className="radio_wrap">
                                                <div className="MuiInputAdornment-root">
                                                    <span>안전모 필요 여부</span>
                                                </div>
                                                <RadioGroup
                                                    name="hardhatyn"
                                                    value={hardhatcheck}
                                                    onChange={(e) => {
                                                        setHardhatCheck(e.target.value);
                                                    }}
                                                >
                                                    <div>
                                                        <FormControlLabel
                                                            value="HARDHAT_N"
                                                            control={<Radio />}
                                                            label={'아니오'}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <FormControlLabel
                                                            value="HARDHAT_Y"
                                                            control={<Radio />}
                                                            label={'예'}
                                                        />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ) : null}
                        </table>
                        {modifyFlag == true ? (
                            <Grid container spacing={2} className="btn_wrap">
                                <Grid item xs={6}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            e3.modal.confirm(
                                                '사용자 확인',
                                                '작성하신 정보로 방문수정을 하시겠습니까?',
                                                '수정하기',
                                                '취소',
                                                () => {
                                                    onCheck();
                                                }
                                            );
                                        }}
                                    >
                                        수정완료
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            e3.modal.confirm(
                                                '사용자 확인',
                                                '방문수정을 취소하시겠습니까?',
                                                '계속수정하기',
                                                '수정취소',
                                                null,
                                                () => {
                                                    onClose();
                                                }
                                            );
                                        }}
                                    >
                                        수정취소
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2} className="btn_wrap">
                                <Grid item xs={6}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            e3.modal.confirm(
                                                '사용자 확인',
                                                '작성하신 정보로 방문신청 등록을 하시겠습니까?',
                                                '등록하기',
                                                '등록취소',
                                                () => {
                                                    onCheck();
                                                }
                                            );
                                        }}
                                    >
                                        등록
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            e3.modal.confirm(
                                                '사용자 확인',
                                                '방문신청 등록을 취소하시겠습니까?',
                                                '계속등록하기',
                                                '취소',
                                                null,
                                                () => {
                                                    onClose();
                                                }
                                            );
                                        }}
                                    >
                                        취소
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
            <Company_PopUp1 ref={company_dialog} callbackAction={setFindCompany} selectedItem={selectedCompany} />
            <User_PopUp ref={user_dialog} callbackAction={setFindUser} selectedItem={selectedUser} />
            {/* <Car_PopUp ref={car_dialog} callbackAction={setFindCar} selectedItem={selectedCar} /> */}
            <Privacy_PopUp ref={privacy_dialog} />
        </React.Fragment>
    );
};

export default ApplicationForEntry;
