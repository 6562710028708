// const LOGIN = 'LOGIN';
const REDUCER_LOGIN = 'REDUCER_LOGIN';
const LOGOUT = 'LOGOUT';
const RESET_STORE = 'RESET_STORE';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const ALLOW_PRIVACY = 'ALLOW_PRIVACY';
const ALLOW_STEP1 = 'ALLOW_STEP1'; // 방문정보 등록
const ALLOW_STEP2 = 'ALLOW_STEP2'; // 안전수칙서약서 제출
const ALLOW_STEP3 = 'ALLOW_STEP3'; // 증명서 제출
const ALLOW_STEP4 = 'ALLOW_STEP4'; // 안전교육 학습
const ALLOW_STEP5 = 'ALLOW_STEP5'; // 안전교육 평가
const ALLOW_STEP6 = 'ALLOW_STEP6'; // 안면정보 등록
const LOGIN_SUCCESS6SAFE = 'LOGIN_SUCCESS6SAFE';
const E3USER = 'E3USER';
const LOGIN_SUCCESS_MDM = 'LOGIN_SUCCESS_MDM';

export const login = (data) => {
    // console.log("action login :: ", data);
    if (data.RESULT === 200 || data.RESULT === '200') {
        return {
            type: LOGIN_SUCCESS,
            target: REDUCER_LOGIN,
            result: data
        };
    }
    return {
        type: LOGIN_FAILURE,
        target: REDUCER_LOGIN,
        result: data
    };
};
export const logout = () => ({
    type: LOGOUT,
    target: REDUCER_LOGIN
});

export const clearStore = () => ({
    type: RESET_STORE,
    target: ''
});

export const loginWithOher = (data) => {
    if (data.RESULT === 200) {
        return {
            type: LOGIN_SUCCESS6SAFE,
            target: REDUCER_LOGIN,
            result: data
        };
    }
    return {
        type: LOGIN_FAILURE,
        target: REDUCER_LOGIN,
        result: data
    };
};
export const privacyAllow = () => ({
    type: ALLOW_PRIVACY,
    target: REDUCER_LOGIN
});
export const step1Allow = () => ({
    type: ALLOW_STEP1,
    target: REDUCER_LOGIN
});
export const step2Allow = () => ({
    type: ALLOW_STEP2,
    target: REDUCER_LOGIN
});
export const step3Allow = () => ({
    type: ALLOW_STEP3,
    target: REDUCER_LOGIN
});
export const step4Allow = () => ({
    type: ALLOW_STEP4,
    target: REDUCER_LOGIN
});
export const step5Allow = () => ({
    type: ALLOW_STEP5,
    target: REDUCER_LOGIN
});
export const step6Allow = () => ({
    type: ALLOW_STEP6,
    target: REDUCER_LOGIN
});
export const e3UserCheck = () => ({
    type: E3USER,
    target: REDUCER_LOGIN
});
export const loginSuccessMdm = () => ({
    type: LOGIN_SUCCESS_MDM,
    target: REDUCER_LOGIN
})

export const defaultState = {
    isLoggedIn: false,
    fetchingUpdate: false,
    privacyAllow: false,
    step1Allow: false,
    step2Allow: false,
    step3Allow: false,
    step4Allow: false,
    step5Allow: false,
    step6Allow: false,
    e3UserCheck: false,
    loginSuccessMdm: false,
    user: {}
};

export default function loginReducer(state = defaultState, action) {
    if (action.target !== REDUCER_LOGIN) {
        return state;
    }

    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                fetchingUpdate: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                fetchingUpdate: false,
                isLoggedIn: true,
                user: action.result
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                fetchingUpdate: false
            };
        case ALLOW_PRIVACY:
            return {
                ...state,
                privacyAllow: true
            };
        case ALLOW_STEP1:
            return {
                ...state,
                step1Allow: true
            };
        case ALLOW_STEP2:
            return {
                ...state,
                step2Allow: true
            };
        case ALLOW_STEP3:
            return {
                ...state,
                step3Allow: true
            };
        case ALLOW_STEP4:
            return {
                ...state,
                step4Allow: true
            };
        case ALLOW_STEP5:
            return {
                ...state,
                step5Allow: true
            };
        case ALLOW_STEP6:
            return {
                ...state,
                step6Allow: true
            };
        case E3USER:
            return {
                ...state,
                e3UserCheck: true
            };
        case LOGIN_SUCCESS_MDM:
            return {
                ...state,
                loginSuccessMdm: true
            };
        case LOGIN_SUCCESS6SAFE:
            return {
                ...state,
                fetchingUpdate: false,
                isLoggedIn: true,
                privacyAllow:
                    action.result?.PRIVACY_CONSENT_DT && action.result?.PRIVACY_CONSENT_DT !== 'null' ? true : false,
                step1Allow: !(action.result?.EMP_NM === '' && action.result?.EMP_TEL_NO === '') ? true : false,
                step2Allow: action.result?.VOW_CONSENT_DT != 'null' ? true : false,
                step3Allow:
                    action.result?.VISIT_CD == 'VISIT200' &&
                    action.result?.FILE_COUNT > 0 &&
                    action.result?.CHECK_DT != 'null' &&
                    Number(action.result?.BP_HIGHT) > 0 &&
                    Number(action.result?.BP_LOW) > 0
                        ? 'check-info active'
                        : action.result?.VISIT_PURPOSE == 'VISIT300_040' &&
                          action.result?.FILE_COUNT > 0 &&
                          action.result?.CHECK_DT != 'null'
                        ? 'check-info active'
                        : 'check-info',
                step4Allow: action.result?.SAFE_EDU_CMPLT_YN === 'Y' ? true : false,
                step5Allow: action.result?.PASS_YN === 'Y' ? true : false,
                step6Allow: action.result?.SAJIN === 'Y' ? true : false,
                user: action.result
            };
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
}
