import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Card, CardContent,
  Typography, 
  //Avatar,
  Button,
} from '@material-ui/core';
import ISONotification from 'src/framework/components/controls/ISONotification';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import Loader from 'src/components/loading/Loader';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';


const Download = (props) => {
	const { signinUser, getReloadUserData, goToPage } = useUserInfoPublicAccount();
    const [item, setItem] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true); // 다운로드 파일 로딩
	// Notify 출력 설정
	const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: '' });
    const mountedRef = React.useRef(true);

    // 안전 교육 정보를 가져온다.
	const getSafeSubject = async () => {
		let result = await ActionEvent.GetSafeSubject();
		// console.log('안전 교육 정보를 가져온다.', result[0].EDU_MTRL_TYPE, result);
		if(result[0].EDU_MTRL_TYPE === 'DOC') {
			// 문서 파일일 경우 다운로드 링크 제공
            setItem(result[0]);
            // result[0].FILE_PATH
            // result[0].L_CLOUD_FILE_ID
            setIsLoading(false);
		}
		else if(result[0].EDU_MTRL_TYPE === 'MOV') {
			goToPage('/app/videoplayer');
            if (!mountedRef.current) return;
		}
	}

    React.useEffect( async () => {
        let mounted = true;
        if(mounted) await getSafeSubject();
        return () => {
            mounted = false;
            mountedRef.current = false
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>안전 교육 문서</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Container maxWidth="lg">
                    <Grid container className="file-view">
                        <Grid item lg={12} md={12} xs={12}>
                            <FileTile title={'안전교육 문서'} subTitle={'롯데케미칼 안전교육'} item={item} reaload={getReloadUserData} goToPage={goToPage} setNotify={setNotify} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Loader isShow={isLoading} />
			<ISONotification notify={notify} setNotify={setNotify} />
        </React.Fragment>
    );
}


const FileTile = (props) => {
    const refFile = React.useRef(null);
    const { title, subTitle, item, reaload, goToPage, setNotify, ...other} = props;

    // 안전 교육 정보를 저장한다.
	const setSafeSubjectResult = async (passYn) => {
		await ActionEvent.SetSafeSubjectResult(passYn);
        await reaload();
        setNotify({ isOpen: true, message: '안전 교육 수강 완료 하였습니다.', type: alertType.Info });
        setTimeout(() => { goToPage('/app/dashboard'); }, 300);
	}

    const handleDownloadClick = () => {
        const link = document.createElement('a');
        link.href = item?.FILE_PATH;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSafeSubjectResult('Y');
    }

    const downloadFile = () => {
        fetch(item?.FILE_PATH, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            },
        }).then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]),);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download', 
                item?.EDU_MTRL_NM,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    }

    return (
        <Card className="Height100">
            <CardContent>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary"  variant="h3">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Box className="BoxCenter paddingTop-2">
                    <Grid container className="file-view">
                        <Grid item xs={9}>
                            <span className="user-file-name MuiInputBase-root MuiOutlinedInput-root">{item?.EDU_MTRL_NM || subTitle}</span>
                        </Grid>
                        <Grid item xs={3} align="right">
                            <Button size="small" variant="contained" onClick={handleDownloadClick}>
                                다운로드
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}


export default Download;
