import { useDispatch } from 'react-redux';
import {
    login,
    logout,
    clearStore,
    loginWithOher,
    privacyAllow,
    step1Allow,
    step2Allow,
    step3Allow,
    step4Allow,
    step5Allow,
    step6Allow,
    e3UserCheck,
    loginSuccessMdm
} from '../reducers/loginReducer';
import { e3 } from 'src/framework/core/utils/utils';

export const SAVE_SESSION_FOR_ACCOUNT_NAME = 'loginSign';
const APP_TYPE = process.env.REACT_APP_TYPE;
const LOGIN_ACCESS_CUSTOMER = APP_TYPE === 'E3PUBLIC' ? true : false; // 안전교육 대상자
/**
 * 로그인 처리 및 세션 저장소에 데이타 저장 처리
 */
export default function useSessionStorage(name) {
    const dispatcher = useDispatch();
    const callLogin = (data) => dispatcher(login(data));
    const callLogout = () => dispatcher(logout());
    const callLoginWithOher = (data) => dispatcher(loginWithOher(data));
    const callPrivacyAllow = () => dispatcher(privacyAllow());
    const callStep1Allow = () => dispatcher(step1Allow());
    const callStep2Allow = () => dispatcher(step2Allow());
    const callStep3Allow = () => dispatcher(step3Allow());
    const callStep4Allow = () => dispatcher(step4Allow());
    const callStep5Allow = () => dispatcher(step5Allow());
    const callStep6Allow = () => dispatcher(step6Allow());
    const calle3UserCheck = () => dispatcher(e3UserCheck());
    const callClearStore = () => dispatcher(clearStore());
    const callLoginSuccessMdm = () => dispatcher(loginSuccessMdm());
    const clearSessionStorage = (isLogoutReducer = false) => {
        window.sessionStorage.clear();
        if (isLogoutReducer) {
            callClearStore();
            callLogout();
        }
    };
    const saveSessionStorage = (value, isLoginReducer = false) => {
        const val = typeof value !== 'string' ? JSON.stringify(value) : value;
        window.sessionStorage.setItem(name, e3.base64Encode(val));
        if (LOGIN_ACCESS_CUSTOMER) {
            if (isLoginReducer) {
                callLoginWithOher(value);
                if (value.USER_GBN === 'U') calle3UserCheck();
            }
        } else {
            if (isLoginReducer) callLogin(value);
        }
    };
    const saveMdmSessionStorage = (value, isLoginReducer = false) => {
        const val = typeof value !== 'string' ? JSON.stringify(value) : value;
        window.sessionStorage.setItem(name, e3.base64Encode(val));
        if (isLoginReducer) callLoginSuccessMdm(value);
    };
    const loadSessionStorage = () => {
        const tmp = window.sessionStorage.getItem(name);
        const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
        return ret;
    };

    const setLoginWithOher = (value) => {
        const val = typeof value !== 'string' ? JSON.stringify(value) : value;
        window.sessionStorage.setItem(name, e3.base64Encode(val));
        callLoginWithOher(value);
        if (value.USER_GBN === 'U') calle3UserCheck();
    };
    const setPrivacyAllow = () => {
        callPrivacyAllow();
    };
    const setStep1Allow = () => {
        callStep1Allow();
    };
    const setStep2Allow = () => {
        callStep2Allow();
    };
    const setStep3Allow = () => {
        callStep3Allow();
    };
    const setStep4Allow = () => {
        callStep4Allow();
    };
    const setStep5Allow = () => {
        callStep5Allow();
    };
    const setStep6Allow = () => {
        callStep6Allow();
    };
    const sete3UserCheck = () => {
        calle3UserCheck();
    };
    const setLoginSuccessMdm = () => {
        callLoginSuccessMdm();
    };

    return {
        clearSessionStorage,
        saveSessionStorage,
        saveMdmSessionStorage,
        loadSessionStorage,
        setLoginWithOher,
        setPrivacyAllow,
        setStep1Allow,
        setStep2Allow,
        setStep3Allow,
        setStep4Allow,
        setStep5Allow,
        setStep6Allow,
        sete3UserCheck
    };
}

export function saveSession(sessionName, seaaionValue) {
    const val = typeof seaaionValue !== 'string' ? JSON.stringify(seaaionValue) : seaaionValue;
    window.sessionStorage.setItem(sessionName, val);
}

export function loadSession(sessionName) {
    const tmp = window.sessionStorage.getItem(sessionName);
    const ret = tmp && tmp.length > 0 ? tmp.toString() : undefined;
    return ret;
}
