import React from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search as SearchIcon, TrendingUp } from 'react-feather';
import ISOAxios, { DataPackage } from '../../core/apis/ISOAxios';

class ISOTextField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            textLabel: this.props.textlabel,
            disabled: this.props.disabled,
            select: this.props.select,
            selectList: this.props.selectList,
            value: this.props.value,
            dataPackage: this.props.dataPackage,
            errormessage: this.props.errormessage
        };

        this.enable = this.enable.bind(this);
        this.disable = this.disable.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.setLabel = this.setLabel.bind(this);
        this.getLabel = this.getLabel.bind(this);
        this.setReadOnly = this.setReadOnly.bind(this);
        this.clear = this.clear.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.reloadCombo = this.reloadCombo.bind(this);
    }

    componentDidMount = async function () {
        if (this.state.dataPackage) {
            try {
                let codeComboResponse = (await ISOAxios.Execute(this.state.dataPackage)).JsonData;
                if (codeComboResponse) {
                    let bindingSource =
                        typeof codeComboResponse === 'string' ? JSON.parse(codeComboResponse) : codeComboResponse;
                    let convertSource = this.convertSource(bindingSource);
                    if (this.props.isaddall) {
                        convertSource = this.addAll(convertSource);
                    }
                    if (this.props.isaddblank) {
                        convertSource = this.addBlank(convertSource);
                    }
                    // console.log('componentDidMount', bindingSource, convertSource);
                    await this.setState({
                        select: true,
                        selectList: convertSource
                    });
                }
            } catch (error) {
                this.setState({
                    select: true,
                    selectList: [{ value: '', label: '자료없음' }]
                });
            }
        }
        if (this.props.codeId) {
            //let params = DataPackage.create('SYS100', 'getCodes');
            let params = DataPackage.init();
            params.KeyValues.push(DataPackage.NewKeyValue('codeId', this.props.codeId));
            if (this.props.plantid && this.props.plantid !== 'null') {
                params.KeyValues.push(DataPackage.NewKeyValue('plantId', this.props.plantid));
            }
            params.KeyValues.push(DataPackage.NewKeyValue('defaultValue', this.props.defaultValue || ''));
            let result = (await ISOAxios.GetCodes(params)).JsonData;
            if (this.props.filter && this.props.filter !== 'null') {
                result = JSON.parse(result).filter((item) => item.VALUE === this.props.filter);
            }
            this.setDataBinding(result);
        }
    };

    async reloadCombo(plantId) {
        if (this.props.codeId) {
            //let params = DataPackage.create('SYS100', 'getCodes');
            let params = DataPackage.init();
            params.KeyValues.push(DataPackage.NewKeyValue('codeId', this.props.codeId));
            if (plantId && plantId !== 'null') {
                params.KeyValues.push(DataPackage.NewKeyValue('plantId', this.props.plantid));
            }
            params.KeyValues.push(DataPackage.NewKeyValue('defaultValue', this.props.defaultValue || ''));
            let result = (await ISOAxios.GetCodes(params)).JsonData;
            if (this.props.filter && this.props.filter !== 'null') {
                result = JSON.parse(result).filter((item) => item.VALUE === this.props.filter);
            }
            this.setDataBinding(result);
        }
    }

    addBlank(list) {
        const blankData = { value: '', label: '선택없음' };
        list.unshift(blankData);
        return list;
    }

    addAll(list) {
        const allData = { value: '', label: '전체' };
        list.unshift(allData);
        return list;
    }

    enable() {
        if (this.state.disabled) {
            this.setState({
                disabled: false
            });
        }
    }

    disable() {
        if (!this.state.disabled) {
            this.setState({
                disabled: true
            });
        }
    }

    convertSource(list) {
        return list.map((e) => ({ label: e[this.props.displayMember], value: e[this.props.valueMember] }));
    }

    async setDataBinding(source) {
        if (source) {
            let selected;
            let bindingSource = typeof source === 'string' ? JSON.parse(source) : source;

            if (this.props.placeholder && this.props.defaultValue) {
                bindingSource = [{ label: this.props.placeholder, value: '' }, ...bindingSource];
            }
            let convertSource = bindingSource.map((e) => ({
                label: e[this.props.displayMember],
                value: e[this.props.valueMember]
            }));

            if (this.props.isaddall) {
                convertSource = this.addAll(convertSource);
            }
            if (this.props.isaddblank) {
                convertSource = this.addBlank(convertSource);
            }

            // default로 선택 했을 때의 처리
            if (this.state.source && this.props.placeholder) {
                selected = this.getElementByIndex(convertSource, 0);
            } else if (this.props.defaultindex > -1) {
                selected = this.getElementByIndex(convertSource, this.props.defaultindex);
            }

            // 이벤트 강제 발생
            if (selected) {
                //   this.onChange(selected);
                this.setState({
                    value: selected.value
                });
            }

            await this.setState({
                select: true,
                selectList: convertSource
            });
        }
    }

    getElementByIndex = (source, index) => {
        const element = source.find((e, i) => i === index);
        return element;
    };

    setSelectList(list) {
        this.setState({
            selectList: list
        });
    }

    setValue(source) {
        this.setState({
            value: source
        });
    }

    getValue() {
        return this.state.value;
    }

    setLabel(source) {
        if (source) {
            this.setState({
                textLabel: source
            });
        }
    }

    getLabel() {
        return this.state.textLabel;
    }

    setReadOnly(source) {
        if (source && typeof source === 'boolean') {
            this.setState({
                inputProps: {
                    readOnly: source
                }
            });
        }
    }

    setErrorMessage(message) {
        this.setState({
            errormessage: message
        });
    }

    clear() {
        this.setState({
            value: '',
            errormessage: null
        });
    }

    onClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value
        });

        if (this.props.onChange) {
            this.props.onChange(e);
        }
        if (this.props.required && e.target.value === '') {
            this.setState({
                errormessage: '필수 입력입니다.'
            });
        } else {
            this.setState({ errormessage: null });
        }
    };

    onKeyPress = (e) => {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(e);
        }
    };

    control = (other, errormessage) => (
        <TextField
            {...other}
            fullWidth
            select={this.state.select}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <span className="ISOTextField4Label"> {this.state.textLabel} </span>
                    </InputAdornment>
                )
            }}
            variant="outlined"
            size="small"
            disabled={this.state.disabled}
            value={this.state.value ? this.state.value : ''}
            onClick={this.onClick}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            className="ISOTextField"
            {...(errormessage && { error: true, helperText: errormessage })}
        >
            {this.state.select && other.placeholder && (
                <MenuItem value="" disabled>
                    <em>{other.placeholder}</em>
                </MenuItem>
            )}
            {this.state.select &&
                this.state.selectList &&
                this.state.selectList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {' '}
                        {option.label}{' '}
                    </MenuItem>
                ))}
        </TextField>
    );

    render() {
        const {
            select,
            selectList,
            dataPackage,
            isaddblank,
            isaddall,
            clearable,
            searchable,
            multiable,
            codeId,
            displayMember,
            valueMember,
            ...other
        } = this.props;
        const { errormessage } = this.state;
        return this.control(other, errormessage);
    }
}

ISOTextField.propTypes = {
    colspan: PropTypes.number,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    codeId: PropTypes.string,
    textlabel: PropTypes.string,
    clearable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    searchable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool,
    multiable: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    displayMember: PropTypes.string,
    valueMember: PropTypes.string,
    onChange: PropTypes.func,
    isaddall: PropTypes.bool,
    isaddblank: PropTypes.bool,
    defaultindex: PropTypes.number,
    select: PropTypes.bool,
    selectList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
    dataPackage: PropTypes.object,
    errormessage: PropTypes.string
};

ISOTextField.defaultProps = {
    clearable: false,
    searchable: false,
    disabled: false,
    multiable: false,
    displayMember: 'LABEL',
    valueMember: 'VALUE',
    onChange: null,
    isaddall: false,
    isaddblank: false
};

export default ISOTextField;
export { ISOAxios, DataPackage };
