import { useDispatch } from 'react-redux';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import StorageHandler from './StorageHandler';
import { dataList, dataReset } from '../reducers/FavoritMenuReducer';
import { e3, alertType } from 'src/framework/core/utils/utils';

const MenuHandler = {
    Save: async (userId, plantId) => {
        const params = DataPackage.create('SGN100', 'GetMenus');
        // 사용자 권한에 맞는 메뉴 정보를 수집 (Biz와 협의)
        params.KeyValues.push(DataPackage.setKeyValue('PLT_ID', plantId));
        params.KeyValues.push(DataPackage.setKeyValue('USER_ID', userId));
        try {
            const response = await ISOAxios.Execute(params);
            if (response) {
                // const jsonData = JSON.parse(response.JsonData);
                StorageHandler.SetLocalStorage('MENU', response.JsonData);
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    Load: () => StorageHandler.GetLocalStorage('MENU'),
    Remove: () => StorageHandler.RemoveLocalStorage('MENU'),
    GetFavoritMenu: async (userId, plantId) => {
        const dispathcer = useDispatch();
        const dispatchFavoritMenu = (data) => dispathcer(dataList(data));
        let params = DataPackage.create('SGN100', 'GetFavoritMenu');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', plantId));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', userId));
        try {
            const response = await ISOAxios.Execute(params);
            if (response) {
                StorageHandler.SetLocalStorage('FAVORITMENU', response.JsonData);
                const jsonData = JSON.parse(response.JsonData);
                dispatchFavoritMenu(jsonData);
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SetFavoritMenu: async (MNU_ID) => {
        const dispathcer = useDispatch();
        const dispatchFavoritMenu = (data) => dispathcer(dataList(data));
        const signinUser = StorageHandler.GetSessionStorage('loginSign');
        let params = DataPackage.create('SGN100', 'SetFavoritMenu');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', signinUser.PLT_ID));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', signinUser.USER_ID));
        params.KeyValues.push(DataPackage.NewKeyValue('MNU_ID', MNU_ID));
        params.KeyValues.push(DataPackage.NewKeyValue('CLT_IP', ''));
        try {
            const response = await ISOAxios.Execute(params);
            if (response) {
                StorageHandler.SetLocalStorage('FAVORITMENU', response.JsonData);
                const jsonData = JSON.parse(response.JsonData);
                dispatchFavoritMenu(jsonData);
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    LoadFavoritMenu: () => StorageHandler.GetLocalStorage('FAVORITMENU'),
    RemoveFavoritMenu: () => {
        const dispathcer = useDispatch();
        const dispatchFavoritMenuReset = () => dispathcer(dataReset());
        StorageHandler.RemoveLocalStorage('FAVORITMENU');
        dispatchFavoritMenuReset();
    },
    Flat2Hierarchy: () => {
        const flatMenu = JSON.parse(MenuHandler.Load());
        // eslint-disable-next-line no-use-before-define
        return MenuHandler.Flat2Hierarchy4Args(flatMenu);
    },
    Flat2Hierarchy4Args: (flatMenu) => {
        const hierarchyMenu = [];
        const mappedArr = {};
        let arrElem;
        let mappedElem;
        for (let i = 0, len = flatMenu.length; i < len; i++) {
            arrElem = flatMenu[i];
            mappedArr[arrElem.MNU_ID] = arrElem;
            mappedArr[arrElem.MNU_ID].children = [];
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const MNU_ID in mappedArr) {
            // eslint-disable-next-line no-prototype-builtins
            if (mappedArr.hasOwnProperty(MNU_ID)) {
                mappedElem = mappedArr[MNU_ID];
                if (mappedElem.PRNT_MNU_ID) {
                    mappedArr[mappedElem.PRNT_MNU_ID].children.push(mappedElem);
                } else {
                    hierarchyMenu.push(mappedElem);
                }
            }
        }
        return hierarchyMenu[0].children;
    }
};

export default MenuHandler;
