import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import Logo from './Logo';

const APP_TYPE = process.env.REACT_APP_TYPE;

const MainNavbar = (props) => {
// eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    const isMdmRoute = (location.pathname.includes('/mdm') || location.pathname.includes('/m_eats'));

    return (
        <AppBar elevation={0} {...props} className="DashboardHeader">
            <Toolbar className="MainLayoutToolbar">
                <RouterLink to={isMdmRoute ? "/m_eats" : "/"} className="DashboardNavbarLogo">
                    <Logo/>
                    {APP_TYPE === 'E3MOBILE' ? <span>작업 관리</span> : isMdmRoute ? <span>기숙사 식수관리</span> : <span>스마트 안전출입관리시스템</span> }
                </RouterLink>
            </Toolbar>
        </AppBar>
    )
};

export default MainNavbar;
