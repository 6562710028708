import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import ActionEvent from '../actionevent/ActionEvent';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';

const DataCheckCard = (props) => {
    const { signinUser, stateUser, getReloadUserData } = useUserInfoPublicAccount();
    const { title, basset, check, step, method, handleGotoPage, ...others } = props;
    const [info, setInfo] = React.useState('미완료');
    const [strValue, setStrValue] = React.useState('');
    //const [flag, setFlag] = React.useState(false);
    //const backgroundcolor = dateinfo ? "Height100" : "Height100 BackgroundColorEf";
    const callMethod = () => {
        return method();
    };

    const handleClick = () => {
        handleGotoPage(basset);
    };

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            switch (basset) {
                case 1:
                    // 방문정보 등록
                    if (check == true) {
                        setInfo('완료');
                    } else {
                        setInfo('미완료');
                    }
                    setStrValue('내용보기');
                    break;
                case 2:
                    // 안전수칙서약서 제출
                    if (check == true) {
                        setInfo('완료');
                        setStrValue('내용보기');
                    } else {
                        setInfo('미완료');
                        setStrValue('제출하기');
                    }
                    break;
                case 3:
                    // 증명서 제출
                    if (check == true) {
                        setInfo('완료');
                        setStrValue('내용보기');
                    } else {
                        setInfo('미완료');
                        setStrValue('제출하기');
                    }
                    break;
                case 4:
                    // 안전교육 학습
                    if (check == true) {
                        setInfo(`완료`);
                    } else {
                        setInfo('미완료');
                    }
                    setStrValue('학습하기');
                    break;
                case 5:
                    // 안전교육 평가
                    if (check == true) {
                        setInfo(`완료`);
                    } else {
                        setInfo('미완료');
                    }
                    setStrValue('평가하기');
                    break;
                case 6:
                    // 안면정보 등록
                    if (check == true) {
                        setInfo('완료');
                        setStrValue('다시등록');
                    } else {
                        setInfo('미완료');
                        setStrValue('등록하기');
                    }
                    break;
            }
        }
        setTimeout(() => {}, 600);
        return () => {
            mounted = false;
        };
    }, [step]);

    return (
        <>
            {(signinUser.ADD_TYPE == 'ADD_TYPE_01' && signinUser.STATUS != 'O') ||
            (signinUser.ADD_TYPE == 'ADD_TYPE_02' && signinUser.STATUS != 'Y') ||
            (signinUser.ADD_TYPE == 'ADD_TYPE_03' && signinUser.STATUS != 'O') ||
            (signinUser.ADD_TYPE == 'ADD_TYPE_04' && signinUser.STATUS != 'Y') ||
            (signinUser.ADD_TYPE == 'ADD_TYPE_05' && signinUser.STATUS != 'Y') ? (
                <Card className="Height100" {...others}>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom variant="h3">
                            {title}
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                            <span>{info}</span>
                        </Typography>
                        {/*  <a>{strValue}</a> */}
                    </CardContent>
                </Card>
            ) : (
                <Card className="Height100" {...others}>
                    <CardContent onClick={handleClick} style={{ cursor: 'pointer' }}>
                        <Typography color="textSecondary" gutterBottom variant="h3">
                            {title}
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                            <span>{info}</span>
                        </Typography>
                        <a>{strValue}</a>
                    </CardContent>
                </Card>
            )}
        </>
    );
};
export default DataCheckCard;
