import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';

import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Typography,
    RadioGroup,
    Radio
} from '@material-ui/core';
import useSignInPublicAccount from 'src/framework/core/hooks/useSignInPublicAccount';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';

// 사내 출입자 안전수칙준수 서약서(작업자)
const SafeRulePledge = (props) => {
    const userInfo = useSelector((state) => state.user);
    const { setStep2Allow } = useSessionStorage(e3.sessionKeys.LoginSign);
    const { SignIn, loadLoginInfo } = useSignInPublicAccount();
    const { signinUser, stateUser, goToPage } = useUserInfoPublicAccount();
    const { userid, userpw, remember } = loadLoginInfo();
    const navigate = useNavigate();

    const [value1, setValue1] = React.useState('N');

    React.useEffect(() => {}, []);

    const onClose = () => {
        goToPage('/app/dashboard');
    };

    function handleClick(e) {
        if (value1 === 'Y') {
            if (userInfo.isLoggedIn === true) {
                ActionEvent.AllowVow((response, error) => {
                    if (error === null && response) {
                        setStep2Allow();
                        setTimeout(() => {
                            onClose();
                        }, 300);
                    }
                });
            } else {
                setStep2Allow();
                onClose();
            }
        } else {
            e3.modal.alert(alertType.Info, '전체 동의 후 진행 가능합니다.');
        }
    }

    return (
        <>
            <React.Fragment>
                <Helmet>
                    <title>방문신청</title>
                </Helmet>
                <Box className="DashboardLayoutContentBox privacy_wrap">
                    <Box>
                        <Typography color="textPrimary" variant="h2">
                            안전수칙준수 서약서
                        </Typography>
                    </Box>
                    <Container maxWidth="lg">
                        <div>
                            <form {...props}>
                                <div>
                                    <div className="agree-page-block marginTop-20">
                                        <p className="agree-page-line">
                                            본인은 귀사 구내에서 작업을 행함에 있어 다음 사항을 성실히 이행함은 물론,
                                            특히 환경안전사고 발생시 인적·물적피해에 대한 모든 책임은 본인에게 있을을
                                            서약합니다.
                                        </p>
                                        <p className="agree-page-line center marginTop-10">- 다음 -</p>
                                    </div>
                                    <div className="agree-page-block">
                                        <h3 className="marginTop-10">- 작업시는 규정된 복장 착용</h3>
                                        <h3>- 승인없는 화기취급 절대 금지</h3>
                                        <h3>- 지정된 장소에서의 흡연</h3>
                                        <h3>- 주차는 허가된 곳에 주차</h3>
                                        <h3>- 작업기구는 사전 점검 후 사용</h3>
                                        <h3>- 출입시 음주, 오락행위 일체 금지</h3>
                                        <h3>- 작업장 주위 환경 정리정돈 철저</h3>
                                        <h3>- 작업시 안전수칙 절대 준수</h3>
                                        {userInfo.user.PLANT_ID == 'PLNT100' ? <h3>- 사내차량 속도 19km</h3> : null}
                                        <div className="agree-page-block marginTop-20">
                                            <p className="agree-page-line">
                                                ※ 안전수칙 위반시 출입증 회수 및 퇴문조치
                                                <br />※ 출입증 교부 불가
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/*}
                                <p className="agree-page-line marginTop-20">
                                    상기와 같이 개인정보를 수집하고 이용함에 있어 충분히 내용을 확인하고 이에
                                    동의하십니까?
                                </p>
                                */}
                                <Card className="MuiPaper-common marginTop-20">
                                    <CardContent className="cardcontent-common">
                                        <Grid container wrap="wrap">
                                            <Grid
                                                item
                                                md={12}
                                                sm={12}
                                                className="CardContentGridItem agree-wrap"
                                                xs={12}
                                            >
                                                <Box className="CardContentGridItem">
                                                    <h3 className="marginTop-0">안전수칙준수 서약서에</h3>
                                                    <RadioGroup
                                                        name="agree1"
                                                        value={value1}
                                                        onChange={(e) => {
                                                            setValue1(e.target.value);
                                                        }}
                                                    >
                                                        <div>
                                                            <FormControlLabel
                                                                value="Y"
                                                                control={<Radio />}
                                                                label={'동의함'}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <FormControlLabel
                                                                value="N"
                                                                control={<Radio />}
                                                                label={'동의하지 않음'}
                                                            />
                                                        </div>
                                                    </RadioGroup>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <br />
                                    <Button color="primary" size="large" variant="contained" onClick={handleClick}>
                                        확인
                                    </Button>
                                </Card>
                            </form>
                        </div>
                    </Container>
                </Box>
            </React.Fragment>
        </>
    );
};
export default SafeRulePledge;
