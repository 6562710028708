import React, { useState } from 'react'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'

const MoveStuffAround = () => {
    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    return (
        <PageVisibility onChange={handleVisibilityChange}>
            {pageIsVisible && (
                <Ticker>
                    {({ index }) => (
                        <>
                            <h1 style={{fontSize: '20px', color: 'red'}}>&nbsp;&nbsp;롯데정밀화학 캡쳐 방지 </h1>
                        </>
                    )}
                </Ticker>
            )}
        </PageVisibility>
    )
}

export default MoveStuffAround
