import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    //   Divider,
    FormControl,
    FormLabel
} from '@material-ui/core';

const QuizCardResut = ({ quiz, index, point, sel, ...rest }) => {
    let backgroundImage = '';
    if (parseInt(point) >= 5) {
        backgroundImage = 'OkImageClass';
    } else {
        backgroundImage = 'NoImageClass';
    }
    return (
        <Card className="CardContentGridItem Height100" {...rest}>
            <CardContent>
                <Box className={('CardContentGridItemCenter paddingBottom-3', backgroundImage)}>
                    <div style={{ width: '100%', height: '100%' }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                <span className="number">{index + 1}</span>
                                {quiz.question}
                            </FormLabel>
                            {/*<Divider />*/}
                            <ol className="quizlist">
                                {quiz.a ? (
                                    <li className={quiz.sol === 'a' ? 'okStyle' : 'noStyle'}>
                                        {' '}
                                        {quiz.a}{' '}
                                        {sel[index] === 'a' && quiz.sol === 'a' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : quiz.sol === 'a' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : sel[index] === 'a' ? (
                                            <em className={'sel'}>선택</em>
                                        ) : null}
                                    </li>
                                ) : null}
                                {quiz.b ? (
                                    <li className={quiz.sol === 'b' ? 'okStyle' : 'noStyle'}>
                                        {' '}
                                        {quiz.b}{' '}
                                        {sel[index] === 'b' && quiz.sol === 'b' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : quiz.sol === 'b' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : sel[index] === 'b' ? (
                                            <em className={'sel'}>선택</em>
                                        ) : null}
                                    </li>
                                ) : null}
                                {quiz.c ? (
                                    <li className={quiz.sol === 'c' ? 'okStyle' : 'noStyle'}>
                                        {' '}
                                        {quiz.c}{' '}
                                        {sel[index] === 'c' && quiz.sol === 'c' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : quiz.sol === 'c' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : sel[index] === 'c' ? (
                                            <em className={'sel'}>선택</em>
                                        ) : null}
                                    </li>
                                ) : null}
                                {quiz.d ? (
                                    <li className={quiz.sol === 'd' ? 'okStyle' : 'noStyle'}>
                                        {' '}
                                        {quiz.d}{' '}
                                        {sel[index] === 'd' && quiz.sol === 'd' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : quiz.sol === 'd' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : sel[index] === 'd' ? (
                                            <em className={'sel'}>선택</em>
                                        ) : null}
                                    </li>
                                ) : null}
                                {quiz.e ? (
                                    <li className={quiz.sol === 'e' ? 'okStyle' : 'noStyle'}>
                                        {' '}
                                        {quiz.e}{' '}
                                        {sel[index] === 'e' && quiz.sol === 'e' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : quiz.sol === 'e' ? (
                                            <em className={'sol'}>정답</em>
                                        ) : sel[index] === 'e' ? (
                                            <em className={'sel'}>선택</em>
                                        ) : null}
                                    </li>
                                ) : null}
                            </ol>
                        </FormControl>
                    </div>
                </Box>
            </CardContent>
            <Box className="CardContentBoxFlexGrow1" />
        </Card>
    );
};
QuizCardResut.propTypes = {
    quiz: PropTypes.object.isRequired
};

export default QuizCardResut;
