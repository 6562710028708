import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import AttachFiles from './pages/AttachFiles';
import TableList from './pages/TableList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import LoginE3 from './pages/LoginE3';
import NotFound from './pages/NotFound';
import CardList from './pages/CardList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import VideoPlayer from './pages/VideoPlayer';
import CameraCheck from './pages/CameraCheck';
import ImageCarousel from './pages/ImageCarousel';
import QuizList from './pages/QuizList';
import Download from './pages/Download';
import CameraArea from './pages/CameraArea';
import Home from './pages/Home'; // 메인화면
import ApplicationForEntry from './pages/ApplicationForEntry'; // 출입신청
import VisitHistory from './pages/VisitHistory';
import VisitHistoryView from './pages/VisitHistoryView';
import SafeComplState from './pages/SafeComplState';
import SafeRulePledge from './pages/SafeRulePledge';
import E3Dashboard from './pages/E3Dashboard';
import MdmHome from './pages/MdmHome';
import MdmLayout from './components/MdmLayout';
import MdmQrScan from './pages/MdmQrScan';
import MdmPayment from './pages/MdmPayment';
import MdmVendSelect from './pages/MdmVendSelect';
import MdmLogin from './pages/MdmLogin';
import MdmReceipt from './pages/MdmReceipt';
import MdmGuide from './pages/MdmGuide';


const APP_TYPE = process.env.REACT_APP_TYPE;

const routes = (userInfo) => [
    {
        path: '/app',
        element: userInfo.isLoggedIn ? <DashboardLayout /> : <Navigate to="/" replace={true} />,
        children: [
            { path: 'account', element: <Account /> },
            { path: 'attachfiles', element: <AttachFiles /> },
            {
                path: 'dashboard',
                element: userInfo.e3UserCheck ? <Navigate to="/app/e3dashboard" replace={true} /> : <Dashboard />
            },
            {
                path: 'settings',
                element: userInfo.privacyAllow ? (
                    <Navigate to="/app/dashboard" replace={true} />
                ) : (
                    <ApplicationForEntry />
                )
            },
            {
                path: 'e3dashboard',
                element: <E3Dashboard />
            },
            { path: 'safequiz', element: <QuizList /> },
            { path: 'videoplayer', element: <VideoPlayer /> }, // 안전교육
            { path: 'download', element: <Download /> },
            { path: 'camera', element: <CameraCheck /> },
            { path: 'visithistory', element: <VisitHistory /> },
            { path: 'visithistoryview', element: <VisitHistoryView /> },
            { path: 'safecomplstate', element: <SafeComplState /> },
            { path: 'saferulepledge', element: <SafeRulePledge /> },
            { path: 'test', element: <CameraArea /> },
            { path: 'equipment', element: <CardList /> },
            { path: 'applicationforentry', element: <ApplicationForEntry /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: '/',
        element: !userInfo.isLoggedIn? (
            <MainLayout />
        ) : userInfo.privacyAllow ? (
            userInfo.e3UserCheck ? (
                <Navigate to="/app/e3dashboard" replace={true} />
            ) : (
                <Navigate to="/app/dashboard" replace={true} />
            )
        ) : (
            <Navigate to="/app/settings" />
        ),
        children: [
            { path: '/', element: <Navigate to="/home" replace={true} /> },
            {
                path: 'home',
                element: <Home />
            },
            {
                path: 'privacySetting',
                element: <Navigate to="/applicationforentry" replace={true} />
            },
            {
                path: 'm_eats',
                element: <MdmHome/>
            },
            { path: 'm_eats/:param', element: <MdmLogin /> },
            {
                path: 'mdmLogin',
                element: <MdmLogin/>
            },
            {
                path: 'mdmQr',
                element: <MdmQrScan/>
            },
            {
                path: 'mdmPayment',
                element: userInfo.loginSuccessMdm? <MdmPayment/> : <Navigate to="404" replace={true} />
            },
            {
                path: 'mdmReceipt',
                element: userInfo.loginSuccessMdm? <MdmReceipt/> : <Navigate to="404" replace={true} />
            },
            {
                path: 'mdmVendSelect',
                element: <MdmVendSelect/>
            },
            {
                path: 'mdmGuide',
                element: <MdmGuide/>
            },
            { path: 'login', element: <Login /> },
            { path: 'empLogin', element: <LoginE3 /> },
            {
                path: 'applicationforentry',
                element: <ApplicationForEntry />
            },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    }
];

export default routes;
