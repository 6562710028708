import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    Checkbox,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import { ISOTextField, ISODateTime } from 'src/framework/components/controls';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import useSignInPublicAccount from 'src/framework/core/hooks/useSignInPublicAccount';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';

const VisitHistoryView = (props) => {
    const userInfo = useSelector((state) => state.user);
    const { SignIn, loadLoginInfo } = useSignInPublicAccount();
    const { signinUser, stateUser, goToPage } = useUserInfoPublicAccount();
    const { userid, userpw, remember } = loadLoginInfo();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState({
        isLoading: false
    });
    const [cmpid, setCmpId] = React.useState(); // 회사 코드
    const [addType, setAddType] = React.useState('');
    const [visitcheck, setVisitCheck] = React.useState(true);

    const [pageCheck, setPageCheck] = React.useState(false); // 페이지 체크 유무 (visithistory에서 이동했는지 Home에서 이동했는지 체크)
    const [privacycheck, setPrivacyCheck] = React.useState(true);
    const [carnumcheck, setCarNumCheck] = React.useState(true);
    const [carcheck, setCarCheck] = React.useState('N');
    const [localcheck, setLocalCheck] = React.useState('LOCAL_Y');
    const [status, setStatus] = React.useState('');
    const [hardhatcheck, setHardhatCheck] = React.useState('HARDHAT_N');
    const [flag, setFlag] = React.useState('H'); // D : Dashboard.js , H : VisitHistory.js

    const refplantId = useRef(ISOTextField);
    const refmngName = useRef(ISOTextField);
    const refstartDay = useRef(ISODateTime);
    const refstartTime = useRef(ISOTextField);
    const refendDay = useRef(ISODateTime);
    const refendTime = useRef(ISOTextField);
    const refinType = useRef(ISOTextField);
    const refinDtl = useRef(ISOTextField);
    const refinItem = useRef(ISOTextField);
    const refuserName = useRef(ISOTextField);
    const refuserPhone = useRef(ISOTextField);
    const refcmpName = useRef(ISOTextField);
    const refcarNo = useRef(ISOTextField);
    const refcarType = useRef(ISOTextField);
    const refcarDtl = useRef(ISOTextField);

    React.useEffect(() => {
        setFlag(location.state.flag);
        onUserInfo();
    }, []);

    /*  방문신청정보 페이지에서 넘어올 경우 데이터 채워줘야함 */
    const onUserInfo = async () => {
        try {
            let param = DataPackage.init();
            param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', location.state.usePlantId));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_NM', location.state.useEmpNm));
            param.KeyValues.push(DataPackage.NewKeyValue('EMP_TEL_NO', location.state.useEmpTelNo));
            param.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', location.state.req));

            let result = await ISOAxios.GetUserInfo(param);
            result = JSON.parse(result.JsonData);
            if (result && result.length > 0) {
                onUserInfoSet(result);
            } else {
                e3.modal.alert(alertType.Info, '검색하신 정보가 없습니다.');
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    const onVisitPorposSet = async (value) => {
        let params = DataPackage.create('SYS100', 'getCodes');
        params.KeyValues.push(DataPackage.NewKeyValue('codeId', value));

        let result = (await ISOAxios.Execute(params)).JsonData;
        if (result) {
            refinDtl.current.setDataBinding(result);
        }
    };

    const onTimeSet = async () => {
        let params = DataPackage.create('SYS100', 'getTimeCodes');
        params.KeyValues.push(DataPackage.NewKeyValue('codeId', 'TIME_CD'));

        let result = (await ISOAxios.Execute(params)).JsonData;
        if (result) {
            refstartTime.current.setDataBinding(result);
            refendTime.current.setDataBinding(result);
        }
    };

    const onUserInfoSet = (info) => {
        onTimeSet();
        onVisitPorposSet(info[0].VISIT_CD);
        setAddType(info[0].ADD_TYPE);
        refplantId.current.setValue(info[0].PLANT_ID);
        refmngName.current.setValue(info[0].MANAGER_NAME);
        refstartDay.current.setDate(new Date(moment(`${decodeURI(info[0].START_DATE)}`).format('YYYY-MM-DD')));
        refstartTime.current.setValue(moment(`${decodeURI(info[0].START_DATE)}`).format('HH:mm'));
        refendDay.current.setDate(new Date(moment(`${decodeURI(info[0].END_DATE)}`).format('YYYY-MM-DD')));
        refendTime.current.setValue(moment(`${decodeURI(info[0].END_DATE)}`).format('HH:mm'));
        refinType.current.setValue(info[0].VISIT_CD);
        refinDtl.current.setValue(info[0].VISIT_PURPOSE);
        refinItem.current.setValue(info[0].IN_ITEM);
        refuserName.current.setValue(info[0].EMP_NM);
        refuserPhone.current.setValue(info[0].EMP_TEL_NO);
        refcmpName.current.setValue(info[0].CMP_NM);

        if (info[0].CAR_NUM) {
            setCarCheck('Y');
            refcarNo.current.setValue(info[0].CAR_NUM);
            refcarType.current.setValue(info[0].CAR_TYPE);
            refcarDtl.current.setValue(info[0].CAR_DETAIL);
        }
        if (info[0].VISIT_CD == 'VISIT100') {
            setVisitCheck(true);
        } else {
            setVisitCheck(false);
        }
        setLocalCheck(info[0].LOCAL_YN);
        setHardhatCheck(info[0].HARDHAT_YN);
        setStatus(info[0].STATUS);

        let privacyday = moment(info[0].PRIVACY_CONSENT_DT).add(1, 'year').format('YYYY-MM-DD HH:mm:ss');
        let nowday = moment().format('YYYY-MM-DD HH:mm:ss');
        if (privacyday < nowday) {
            onPrivacyShow();
        }
    };

    const onClose = () => {
        if (flag == 'H') {
            goToPage('/app/visithistory');
        } else {
            goToPage('/app/dashboard');
        }
    };

    const onAddPage = () => {
        navigate('/app/applicationforentry', {
            state: {
                usePlantId: location.state.usePlantId,
                useEmpNm: location.state.useEmpNm,
                useEmpTelNo: location.state.useEmpTelNo,
                req: location.state.req,
                addType: addType,
                flag: false
            }
        });
    };

    const onModPage = () => {
        navigate('/app/applicationforentry', {
            state: {
                usePlantId: location.state.usePlantId,
                useEmpNm: location.state.useEmpNm,
                useEmpTelNo: location.state.useEmpTelNo,
                req: location.state.req,
                addType: addType,
                flag: true
            }
        });
    };

    const onDelete = async () => {
        let result = await ActionEvent.DeleteUserInfo(location.state.req);
        if (result && result.KeyValues[1].Value == '200') {
            e3.modal.alert(alertType.Info, '삭제가 완료되었습니다.');
            goToPage('/app/dashboard');
        } else {
            e3.modal.alert(alertType.Info, '검색하신 정보가 없습니다.');
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>방문신청</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        방문이력 상세정보
                    </Typography>
                </Box>
                <Container maxWidth="sm">
                    <Grid container wrap="wrap">
                        <div className="radio_wrap marginTop-0">
                            <Checkbox color="primary" checked={privacycheck} name="agree" />
                            <label>
                                <span>
                                    <a className="bold">개인정보 수집 및 이용 동의</a>
                                    <em className="f_note marginLeft-0">(클릭하시면 내용을 확인하실 수 있습니다.)</em>
                                </span>
                            </label>
                        </div>
                        <table>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refuserName}
                                        textlabel={'방문자명'}
                                        margin="normal"
                                        name="userName"
                                        variant="outlined"
                                        required={false}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refuserPhone}
                                        margin="normal"
                                        type="text"
                                        name="userPhone"
                                        variant="outlined"
                                        required={false}
                                        textlabel={'휴대폰번호'}
                                        disabled={true}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refcmpName}
                                        textlabel={'회사명'}
                                        margin="normal"
                                        name="cmpName"
                                        variant="outlined"
                                        disabled={true}
                                        required={false}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refmngName}
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        name="mngName"
                                        required={false}
                                        textlabel={'담당자'}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refplantId}
                                        select={true}
                                        type="text"
                                        name="plantId"
                                        variant="outlined"
                                        required={false}
                                        textlabel={'방문사업장'}
                                        codeId={'PLANTID'}
                                        disabled={true}
                                        margin="normal"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="date_wrap">
                                        <div className="MuiInputAdornment-root">
                                            <span>방문기간 </span>
                                        </div>
                                        <div className="term">
                                            <Grid container spacing={2}>
                                                <Grid item xs={7}>
                                                    <ISODateTime
                                                        ref={refstartDay}
                                                        dateFormat={'yyyy-MM-dd'}
                                                        showMonthYearPicker={false}
                                                        required={false}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <ISOTextField
                                                        ref={refstartTime}
                                                        select={true}
                                                        type="text"
                                                        name="startTime"
                                                        variant="outlined"
                                                        required={false}
                                                        disabled={true}
                                                        codeId={'TIME_CD'}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <span>~</span>
                                            <Grid container spacing={2}>
                                                <Grid item xs={7}>
                                                    <ISODateTime
                                                        ref={refendDay}
                                                        dateFormat={'yyyy-MM-dd'}
                                                        showMonthYearPicker={false}
                                                        required={false}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <ISOTextField
                                                        ref={refendTime}
                                                        select={true}
                                                        type="text"
                                                        name="endTime"
                                                        variant="outlined"
                                                        required={false}
                                                        codeId={'TIME_CD'}
                                                        disabled={true}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refinType}
                                        select={true}
                                        textlabel={'방문구분'}
                                        margin="normal"
                                        variant="outlined"
                                        name="inType"
                                        required={true}
                                        codeId={'VISIT_CD'}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refinDtl}
                                        select={true}
                                        textlabel={'방문목적'}
                                        margin="normal"
                                        name="inDtl"
                                        variant="outlined"
                                        required={false}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ISOTextField
                                        ref={refinItem}
                                        textlabel={'방문목적(상세)'}
                                        margin="normal"
                                        name="inItem"
                                        variant="outlined"
                                        required={false}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="radio_wrap">
                                        <div className="MuiInputAdornment-root">
                                            <span>차량유무</span>
                                        </div>
                                        <RadioGroup name="caryn" value={carcheck}>
                                            <div>
                                                <FormControlLabel value="N" control={<Radio />} label={'도보'} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <FormControlLabel value="Y" control={<Radio />} label={'차량'} />
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </td>
                            </tr>
                            {carcheck == 'Y' ? (
                                <>
                                    <tr>
                                        <td>
                                            <ISOTextField
                                                ref={refcarNo}
                                                textlabel={'차량번호'}
                                                margin="normal"
                                                name="carNo"
                                                variant="outlined"
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ISOTextField
                                                ref={refcarType}
                                                select={true}
                                                textlabel={'차종'}
                                                margin="normal"
                                                name="carType"
                                                variant="outlined"
                                                codeId={'CAR_TYPE'}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ISOTextField
                                                ref={refcarDtl}
                                                textlabel={'차종상세'}
                                                margin="normal"
                                                name="carDtl"
                                                variant="outlined"
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                </>
                            ) : null}
                            {visitcheck == true ? (
                                <>
                                    <tr>
                                        <td>
                                            <div className="radio_wrap">
                                                <div className="MuiInputAdornment-root">
                                                    <span>내국인 여부</span>
                                                </div>
                                                <RadioGroup name="localyn" value={localcheck}>
                                                    <div>
                                                        <FormControlLabel
                                                            value="LOCAL_Y"
                                                            control={<Radio />}
                                                            label={'내국인'}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <FormControlLabel
                                                            value="LOCAL_N"
                                                            control={<Radio />}
                                                            label={'외국인'}
                                                        />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="radio_wrap">
                                                <div className="MuiInputAdornment-root">
                                                    <span>안전모필요 여부</span>
                                                </div>
                                                <RadioGroup name="hardhatyn" value={hardhatcheck}>
                                                    <div>
                                                        <FormControlLabel
                                                            value="HARDHAT_N"
                                                            control={<Radio />}
                                                            label={'아니오'}
                                                        />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <FormControlLabel
                                                            value="HARDHAT_Y"
                                                            control={<Radio />}
                                                            label={'예'}
                                                        />
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ) : null}
                        </table>
                        {flag == 'H' ? (
                            addType == 'ADD_TYPE_01' && status != 'C' ? (
                                <Grid container spacing={2} className="btn_wrap">
                                    <Grid item xs={6}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            onClick={onAddPage}
                                        >
                                            이 정보로 방문신청
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            onClick={() => {
                                                e3.modal.confirm(
                                                    '사용자 확인',
                                                    '해당 방문신청건을 삭제하시겠습니까?',
                                                    '삭제하기',
                                                    '취소',
                                                    () => {
                                                        onDelete();
                                                    },
                                                    null
                                                );
                                            }}
                                        >
                                            삭제
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button fullWidth type="submit" variant="contained" onClick={onClose}>
                                            닫기
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={2} className="btn_wrap">
                                    <Grid item xs={8}>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            onClick={onAddPage}
                                        >
                                            이 정보로 방문신청
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth type="submit" variant="contained" onClick={onClose}>
                                            닫기
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        ) : (
                            <Grid container spacing={2} className="btn_wrap">
                                <Grid item xs={4}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={onModPage}
                                    >
                                        수정
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            e3.modal.confirm(
                                                '사용자 확인',
                                                '해당 방문신청건을 삭제하시겠습니까?',
                                                '삭제하기',
                                                '취소',
                                                () => {
                                                    onDelete();
                                                },
                                                null
                                            );
                                        }}
                                    >
                                        삭제
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth type="submit" variant="contained" onClick={onClose}>
                                        닫기
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
        </React.Fragment>
    );
};

export default VisitHistoryView;
