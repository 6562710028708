import React from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    // Avatar,
    Box,
    // Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    IconButton,
    Button
} from '@material-ui/core';
import NavItem from './NavItem';
// import { Input as InputIcon } from '@material-ui/icons';
import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import { phoneNumberFomatter } from 'src/framework/core/utils/utils';
import ActionEvent from './actionevent/ActionEvent';
import guestItems from 'src/components/utils/MenuGuestItem'; // 방문객 Navi
import patnerItems from 'src/components/utils/MenuPatnerItem'; // 협력사 출입 Navi
import userItems from 'src/components/utils/MenuItem4Mobile'; // 임직원 출입 Navi

/* const APP_TYPE = process.env.REACT_APP_TYPE; */

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
    const location = useLocation();
    const { clearSessionStorage } = useSessionStorage('loginSign');
    const signinUser = ActionEvent.GetUser();
    const userName =
        signinUser && signinUser.EMP_NM
            ? `${decodeURI(signinUser.EMP_NM)}`
            : signinUser.USER_NAME
            ? `${decodeURI(signinUser.USER_NAME)}`
            : '무명인';
    /* const userId =
        signinUser && signinUser.EMP_TEL_NO ? `[${phoneNumberFomatter(decodeURI(signinUser.EMP_TEL_NO), 0)}]` : ''; */

    const handleLogoutClick = () => {
        clearSessionStorage(true);
        // window.document.location.reload();
    };
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box className="DashboardSidebar">
            <Box className="DashboardSidebarUserInfo">
                <div className="user_profile">
                    <img id="img" src={ActionEvent.GetPersonalSajin()} />
                </div>
                <div className="user_info">
                    <Typography color="textPrimary" variant="h5">
                        {userName}님
                    </Typography>
                    <a className="logout" onClick={handleLogoutClick}>
                        로그아웃
                    </a>
                    <a className="close" onClick={onMobileClose} />
                </div>
            </Box>
            <hr />
            {/*<Divider />*/}
            <Box>
                <List className="MainLayoutNavList">
                    {signinUser && signinUser.USER_ID != undefined && signinUser.RESULT === 200
                        ? userItems.map((item) =>
                              item.href == '$' ? (
                                  <div className="MainLayoutNavListitem">
                                      <div className="MainLayoutNavListitemButton">
                                          <a href="https://www.lottefinechem.com/privacy/" target="_blank">
                                              <span>{item.title}</span>
                                          </a>
                                      </div>
                                  </div>
                              ) : (
                                  <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
                              )
                          )
                        : signinUser.REQ_TYPE == 'REQ_TYPE_001'
                        ? patnerItems.map((item) =>
                              item.href == '$' ? (
                                  <div className="MainLayoutNavListitem">
                                      <div className="MainLayoutNavListitemButton">
                                          <a href="https://www.lottefinechem.com/privacy/" target="_blank">
                                              <span>{item.title}</span>
                                          </a>
                                      </div>
                                  </div>
                              ) : (
                                  <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
                              )
                          )
                        : guestItems.map((item) =>
                              item.href == '$' ? (
                                  <div className="MainLayoutNavListitem">
                                      <div className="MainLayoutNavListitemButton">
                                          <a href="https://www.lottefinechem.com/privacy/" target="_blank">
                                              <span>{item.title}</span>
                                          </a>
                                      </div>
                                  </div>
                              ) : (
                                  <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
                              )
                          )}
                </List>
            </Box>
            <Box className="DashboardNavbarItemBox" />
        </Box>
    );

    return (
        <React.Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="right"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{ className: 'DashboardSidebarSizeUp' }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer anchor="left" open variant="persistent" PaperProps={{ className: 'DashboardSidebarSizeDown' }}>
                    {content}
                </Drawer>
            </Hidden>
        </React.Fragment>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default DashboardSidebar;
