import React from 'react';
import {
	Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Button,
} from '@material-ui/core';

export default function ISOCarouselBanner (props) {
	if (props.newProp) console.log(props.newProp)
    const showContent = props.showContent ? props.showContent : false;
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 2;
    const mediaLength = totalItems - 1;

    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} key="content">
            <CardContent className="Content">
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

                <Button variant="outlined" className="ViewButton">
                    View Now
                </Button>
            </CardContent>
        </Grid>
    )

    if( totalItems > 1) {
        for (let i = 0; i < mediaLength; i++) {
            const item = props.item.Items[i];

            const media = (
                <Grid item xs={12} key={item.Name}>
                    <CardMedia
                        className="Media"
                        image={item.Image}
                        title={item.Name}
                    >
                        <Typography className="MediaCaption">
                            {item.Name}
                        </Typography>
                    </CardMedia>

                </Grid>
            )
            items.push(media);
        }
    } else {
        const item = props.item;
        const media = (
            <Grid item xs={12} key={item.Name}>
                <CardMedia
                    className="Media"
                    image={item.Image}
                    title={item.Name}
                >
                    <Typography className="MediaCaption">
                        {item.Name}
                    </Typography>
                </CardMedia>

            </Grid>
        )
        items.push(media);
    }

    if (showContent && totalItems > 1) {
        if (contentPosition === "left") {
            items.unshift(content);
        } else if (contentPosition === "right") {
            items.push(content);
        } else if (contentPosition === "middle") {
            items.splice(items.length / 2, 0, content);
        }
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
};