import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { MessageOnlyDialog } from '../framework/components/controls/ISODialog';
import AccountProfile from 'src/components/account/AccountProfile';
import CameraPopup from 'src/components/camera/CameraPopup';
import Loader from 'src/components/loading/Loader';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';

const Account = (props) => {
    const { signinUser, stateUser, getReloadUserData, goToPage } = useUserInfoPublicAccount();
    const msgRef = React.useRef();
    const camera = React.useRef(CameraPopup);
    const [image, setImage] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const closeDialog = () => {
        msgRef.current.hideDialog();
    };

    React.useEffect(() => {
        if (stateUser.privacyAllow === false) {
            goToPage('/app/settings');
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>안면정보 등록</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        안면정보 등록
                    </Typography>
                </Box>
                <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="sample-wrap">
                            <div className="sample-image">
                                <img src="./static/images/sample.jpg" alt="" />
                                얼굴촬영 샘플
                            </div>
                            <div className="sample-detail">
                                <Box className="camera-note">
                                    <h5>촬영 방법 Tip</h5>
                                    <ul>
                                        <li>모자와 마스크를 벗고 눈썹이 가려지지 않도록 머리카락을 정리하세요.</li>
                                        <li>정면을 응시한 후 촬영하세요.</li>
                                    </ul>
                                </Box>
                            </div>
                        </Grid>
                        <hr />
                        <Grid item xs={12}>
                            <AccountProfile Camera={camera} sajin={image} setIsLoading={setIsLoading} />
                        </Grid>
                    </Grid>
                </Container>
                <MessageOnlyDialog title="결과 확인" closeDialog={closeDialog} ref={msgRef} />
                <CameraPopup ref={camera} setImage={setImage} setIsLoading={setIsLoading} messageBox={msgRef} />
                <Loader isShow={isLoading} />
            </Box>
        </React.Fragment>
    );
};

export default Account;
