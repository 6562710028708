/* eslint-disable array-callback-return */
/* eslint-disable func-names */
// import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import { e3, alertType } from 'src/framework/core/utils/utils';
import useSessionStorage from './useSessionStorage';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import { loginWithOher } from 'src/framework/core/reducers/loginReducer';

/**
 * 로그인 사용자 정보 확인 및 수정
 * 비밀번호 변경
 */
export default function useUserInfoPublicAccount() {
    const dispatcher = useDispatch();
    const callLoginWithOher = (data) => dispatcher(loginWithOher(data));
    const { loadSessionStorage, saveSessionStorage } = useSessionStorage('loginSign');
    const navigate = useNavigate();
    const signinUser = loadSessionStorage();
    const stateUser = useSelector((state) => state.user);

    /**
     * 로그인한 사용자정보를 요청한다.
     * @param {*} Callback (result, error)
     */
    const getProfile = async function () {
        let params = DataPackage.create('MAPI100', 'SelectAccountInfo');
        params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
        params.KeyValues.push(DataPackage.NewKeyValue('EMP_ID', signinUser.EMP_ID));
        try {
            const response = await ISOAxios.Execute(params);
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };

    const getReloadUserData = async function () {
        const resetUser = await ActionEvent.ReloadUserData();
        if (resetUser) callLoginWithOher(resetUser);
    };

    /* const getAllowFiles = async function () {
        try {
            let result = await ActionEvent.AllowFilesCount();
            if (result) {
                // console.log('AllowFilesCount --> AllowFiles :: ',result);
                window.sessionStorage.setItem('AllowFilesCount', JSON.stringify(result));
                if (
                    result.totalLength > 0 &&
                    result.totalLength === result.uploadCount &&
                    result.uploadCount === result.allowCount
                )
                    return true;
                else return false;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }; */

    const goToPage = function (routerPath) {
        navigate(routerPath);
    };

    return {
        signinUser,
        stateUser,
        getProfile,
        getReloadUserData,
        goToPage
    };
}
