import React, { useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Grid, Button, Container, Typography } from '@material-ui/core';
import { ISOTextField } from 'src/framework/components/controls';
import { MessageOnlyDialog } from 'src/framework/components/controls/ISODialog';
import useSignInPublicAccount from 'src/framework/core/hooks/useSignInPublicAccount';
import AccountHandler from 'src/framework/core/handlers/AccountHandler';
import { e3, alertType, localStorageSave } from 'src/framework/core/utils/utils';
import useLocalStorage from '../framework/core/hooks/useLocalStorage';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';

const MdmLogin = () => {
    const navigate = useNavigate();
    const title = `mdmLogin`; // 로그인 페이지 제목

    // 로그인 및 메뉴 정보 처리
    const { MdmSignIn, loadLoginInfo } = useSignInPublicAccount();
    const { userid, userpw, remember } = loadLoginInfo();
    const { localStorageLoad, localStorageSave } = useLocalStorage();
    const [loading, setLoading] = React.useState({
        showPassword: false,
        isLoading: false
    });
    const location = useLocation();
    const { param } = useParams();

    const userName = React.useRef(ISOTextField);
    const userPhone = React.useRef(ISOTextField);
    const userCrtic = React.useRef(ISOTextField);

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            navigate('/m_eats',
                {
                    state: {
                        from: 'back',
                        code: '',
                    }
                });  // 홈 페이지로 이동
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    useEffect(() => {
        if (param) {
            window.history.replaceState(null, '', '#/m_eats');
        }
    }, [param, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            if (param) {
                let params = DataPackage.create("MDM100", "chktVendor");
                params.KeyValues.push(
                    DataPackage.NewKeyValue("VENDER_CODE", param.toString())
                );

                try {
                    const rawResponse = await ISOAxios.Execute(params);
                    const response = JSON.parse(rawResponse.JsonData);
                    if (response && Array.isArray(response) && response.length > 0) {
                        const venderCode = response[0].VENDER_CODE;
                        const venderName = response[0].VENDER_NAME;

                        e3.modal.confirm(
                            '가맹점 확인',
                            `선택된 가맹점 : ${venderName}`,
                            '확인',
                            '취소',
                            () => saveCode(venderCode, venderName),
                            () => goBackHome()
                        );


                    } else {
                        e3.modal.alert(alertType.Warning, '존재하지 않는 가맹점 입니다.');
                    }
                } catch (error) {
                    e3.modal.alert(alertType.Error, error.message);
                    navigate('/m_eats');
                }
            }
        };

        fetchData();
    }, [param]);

    useEffect(() => {
        if(!localStorageLoad('vender_name') && !param) navigate('/m_eats')
    }, [navigate]);

    const saveCode = async (vc = '', vn = '') => {
        localStorageSave('vender_code', vc)
        localStorageSave('vender_name', vn)
    }
    const goBackHome = async() => {
        navigate('/m_eats',
            {
                state: {
                    from: 'back',
                    code: '',
                }
            });
    }

    const onSignIn = () => {
        let name = userName.current.getValue() || '';
        let phone = e3.string.stringNumCheck(userPhone.current.getValue()) || '';
        let crtic = userCrtic.current.getValue() || '';

        if (name == '' || name.length < 2) {
            e3.modal.alert(alertType.Warning, '이름을 정확히 입력해 주세요.');
            return;
        } else if (phone == '' || phone.length < 11) {
            e3.modal.alert(alertType.Warning, '전화번호를 정확히 입력해 주세요.');
            return;
        } else if (crtic == '' || crtic.length != 6) {
            e3.modal.alert(alertType.Warning, '인증번호를 정확히 입력해 주세요.');
            return;
        } else {
            MdmSignIn(
                name,
                phone,
                crtic,
                (ret, err) => {
                    let isauth = false;
                    try {

                        if (err) {
                            if (typeof err === 'string') {
                                e3.modal.alert(alertType.Warning, err);
                            } else {
                                e3.modal.alert(alertType.Warning, '접속 오류입니다. 다시 시도해 주세요!');
                            }
                        }
                        if (ret && ret.ErrorMessage && ret.ErrorMessage.length > 0) {
                            e3.modal.alert(alertType.Warning, ret.ErrorMessage);
                        } else {
                            isauth = true;
                        }
                    } catch (e) {
                        e3.modal.alert(alertType.Error, e.message);
                    } finally {
                        setLoading({ ...loading, isLoading: false });
                        if (isauth) {
                            navigate('/mdmPayment');
                        }
                    }
                },
                true
            );
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Box className="MainLayoutBox login-wrap-mdm">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            username: '',
                            userphone: '',
                            crticNo: ''
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <Typography color="textPrimary" variant="h2" style={{color: '#0058b2'}}>
                                        로그인
                                    </Typography>
                                </Box>
                                <ISOTextField
                                    ref={userName}
                                    error={Boolean(touched.username && errors.username)}
                                    fullWidth
                                    helperText={touched.username && errors.username}
                                    label="사원번호"
                                    margin="normal"
                                    name="username"
                                    onBlur={handleBlur}
                                    value={values.username}
                                    variant="outlined"
                                    placeholder="사원번호"
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <ISOTextField
                                            ref={userPhone}
                                            error={Boolean(touched.userphone && errors.userphone)}
                                            fullWidth
                                            helperText={touched.userphone && errors.userphone}
                                            label="핸드폰번호"
                                            margin="normal"
                                            name="userphone"
                                            onBlur={handleBlur}
                                            value={values.userphone}
                                            variant="outlined"
                                            placeholder="핸드폰번호"
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    userPhone.current.setValue(
                                                        e3.string.toPhoneFormat(
                                                            e3.string.stringNumCheck(e.target.value)
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            onClick={async () => {
                                                setLoading(true);
                                                if (
                                                    (e3.string.stringCmpCheck(userName.current.getValue()) || '') == '' ||
                                                    (e3.string.stringNumCheck(userPhone.current.getValue()) || '') == ''
                                                ) {
                                                    e3.modal.alert(
                                                        alertType.Warning,
                                                        '이름 또는 전화번호를 정확히 기입해 주세요.'
                                                    );
                                                    return;
                                                } else if (
                                                    !e3.validation.isMobileNumber(
                                                        e3.string.stringNumCheck(userPhone.current.getValue())
                                                    )
                                                ) {
                                                    e3.modal.alert(
                                                        alertType.Warning,
                                                        '전화번호의 형태가 정확하지 않습니다.'
                                                    );
                                                    return;
                                                }
                                                try {
                                                    const response = await AccountHandler.RequestMdmCertificationCode(
                                                        e3.string.stringCmpCheck(userName.current.getValue()),
                                                        e3.string.stringNumCheck(userPhone.current.getValue())
                                                    );
                                                    if (response.KeyValues[0].Value == '200') {
                                                        setLoading(false);
                                                        e3.modal.alert(alertType.Info, '인증번호가 전송되었습니다.');
                                                    }
                                                } catch (e) {
                                                    e3.modal.alert(alertType.Info, e.ErrorMessage);
                                                } finally {
                                                    setLoading(false);
                                                }
                                            }}
                                        >
                                            인증번호요청
                                        </Button>
                                    </Grid>
                                    <p className="f_note"> - 없이 숫자만 입력하세요.</p>
                                </Grid>
                                <ISOTextField
                                    ref={userCrtic}
                                    error={Boolean(touched.crticNo && errors.crticNo)}
                                    fullWidth
                                    helperText={touched.crticNo && errors.crticNo}
                                    label="인증번호"
                                    margin="normal"
                                    name="crticNo"
                                    onBlur={handleBlur}
                                    type="password"
                                    value={values.crticNo}
                                    variant="outlined"
                                    placeholder="인증번호"
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            userCrtic.current.setValue(e3.string.stringNumCheck(e.target.value));
                                        }
                                    }}
                                />
                                <Box className="paddingTop-2">
                                    <Button
                                        color="secondary"
                                        onClick={onSignIn}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        로그인
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
            <footer>
                <p className="copyright">COPYRIGHT LOTTE Fine Chemical Co., Ltd.</p>
            </footer>
        </React.Fragment>
    );
};

export default MdmLogin;
