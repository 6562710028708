import React, { useEffect, useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISOCombobox, ISOInput } from '../framework/components/controls';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';
import { alertType, e3 } from '../framework/core/utils/utils';
import { Helmet } from 'react-helmet';
import ActionEvent from '../components/actionevent/ActionEvent';
import useSignInPublicAccount from '../framework/core/hooks/useSignInPublicAccount';
import useSessionStorage from '../framework/core/hooks/useSessionStorage';
import useLocalStorage from '../framework/core/hooks/useLocalStorage';
import Ticker from 'react-ticker';
import MoveStuffAround from './MoveStuffAround';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        width: '350px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9'
    },
    blockBox: {
        margin: '30px 0',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    content: {
        textAlign: 'left',
    },
    row: {
        marginBottom: '5px',
        display: 'flex',
        verticalAlign: 'middle',
    },
    title: {
        marginBottom: '20px',
        fontSize: '30px',
        color: '#0058b2'
    },
    input: {
        width: '100%',
        padding: '5px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    isoInput : {
        width: '100%',
        fontSize: '14px',
    },
    text: {
        display: 'block',
        padding: '5px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: '#fff',
    },
    selectorContainer: {
        marginBottom: '15px'
    },
    label: {
        display: 'inline-block',
        width: '25%',
        color: '#666'

    },
    select: {
        width: '100%',
        padding: '5px',
        fontSize: '14px'
    },
    button: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#0d6efd',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        width: '100%',
    }
}));

const MdmReceipt = () =>{
    const classes = useStyles();
    const location = useLocation();
    const { clearSessionStorage } = useSessionStorage('loginSign');
    const signinUser = ActionEvent.GetUser();
    const userName =
        signinUser && signinUser.EMP_NM
            ? `${decodeURI(signinUser.EMP_NM)}`
            : signinUser.USER_NAME
                ? `${decodeURI(signinUser.USER_NAME)}`
                : '무명인';
    const empNo = signinUser && signinUser.EMP_NO
        ? `${decodeURI(signinUser.EMP_NO)}` : '';
    const {localStorageSave, localStorageLoad} = useLocalStorage();
    const {  loadLoginInfo } = useSignInPublicAccount();
    const { username } = loadLoginInfo();
    const [venderName, setVenderName] = useState('');
    const [applyNumber,setApplyNumber] = useState('');
    const [payTime,setPayTime] = useState('');
    const [payUser,setPayUser] = useState('');
    const [payAmount,setPayAmount] = useState('');
    const [amountLimit, setAmountLimit] = useState('0');
    const [amountLimitOrigin, setAmountLimitOrigin] = useState('0');


    const [ time ,setTime] = useState();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const ref_VENDER = useRef();
    const ref_DATE = useRef();
    const ref_USER = useRef();
    const ref_AMOUNT = useRef();
    const ref_REQUEST_AMOUNT = useRef(ISOInput);
    const MINUTES_IN_MS = 15;
    const INTERVAL = 1000;
    const [timeLeft, setTimeLeft] = useState(MINUTES_IN_MS);
    let timer = 0;

    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        onSearchReceipt().then(r => onSearchAmount(empNo));
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            clearInterval(timer);
            clearSessionStorage();
            navigate('/m_eats',
                {
                    state: {
                        from: 'back',
                        code: '',
                    }
                });  // 홈 페이지로 이동
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, INTERVAL);

        if (timeLeft <= 0) {
            clearInterval(timer);
            clearSessionStorage();
            navigate('/m_eats');
        }

        return () => {
            clearInterval(timer);
        };
    }, [timeLeft]);

    const onSearchReceipt = async() => {
        let param = DataPackage.create("MDM400", "SelectPaymentReceipt");
        param.KeyValues.push(
            DataPackage.NewKeyValue("EMP_NO", userName)
        );
        try {
            let response = (await (ISOAxios.Execute(param))).JsonData;
            if (response) {
                setApplyNumber(JSON.parse(response)[0].APPLY_CODE);
                setVenderName(JSON.parse(response)[0].VENDER_NAME);
                setPayTime(JSON.parse(response)[0].PY_TIME);
                setPayUser(JSON.parse(response)[0].PAY_USER_NAME);
                setPayAmount(JSON.parse(response)[0].AMOUNT);
            }
            else{
                e3.modal.alert(alertType.Error, '영수증을 조회할수 없습니다', () => goHome());

            }
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    }

    const handleChange = event =>
        setAmountLimit(addCommas(removeNonNumeric(event.target.value)));

    const onSearchAmount = async(value) => {
        let param = DataPackage.create("MDM400", "SelectAmountLimit");
        param.KeyValues.push(
            DataPackage.NewKeyValue("EMP_NO", userName)
        );
        try {
            let response = (await (ISOAxios.Execute(param))).JsonData;
            if (response) {
                await setAmountLimit(addCommas(removeNonNumeric(JSON.parse(response)[0].AMOUNT_LIMIT)) + '원');
                await setAmountLimitOrigin(JSON.parse(response)[0].AMOUNT_LIMIT);
            }
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    }

    const goHome = () => {
        clearInterval(timer);
        clearSessionStorage();
        navigate('/m_eats');
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>롯데정밀화학 기숙사 식비 관리</title>
            </Helmet>
            <Box className="MainLayoutBox intro-wrap-mdm">
                <Container className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.header}>
                            <h2 className={classes.title}>영수증</h2>
                        </div>
                        <div className={classes.content}>
                            <MoveStuffAround />
                        </div>
                        <div className={classes.blockBox}>
                            <div className={classes.row}>
                                <label className={classes.label}>승인번호</label>
                                <input type="text" value={applyNumber} readOnly disabled={true}
                                       className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>가맹점</label>
                                <input ref={ref_VENDER} type="text" value={venderName} readOnly disabled={true}
                                       className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>일시</label>
                                <input ref={ref_VENDER} type="text" value={payTime} readOnly disabled={true}
                                       className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>사용자</label>
                                <input ref={ref_VENDER} type="text" value={payUser} readOnly disabled={true}
                                       className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>승인금액</label>
                                <input ref={ref_VENDER} type="text" value={payAmount} readOnly disabled={true}
                                       className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>잔여금액</label>
                                <input type="text" readOnly disabled={true} value={amountLimit}
                                       className={classes.input} onInput={handleChange} />
                            </div>
                        </div>
                        <div className={classes.content}>
                            <MoveStuffAround />
                        </div>
                        <div>
                            <a>{timeLeft} 초 후 메인화면으로 이동합니다</a>
                        </div>
                    </div>

                </Container>
            </Box>
            <footer>
                <p className="copyright">COPYRIGHT LOTTE Fine Chemical Co., Ltd.</p>
            </footer>
        </React.Fragment>
    )
        ;
};

export default MdmReceipt
