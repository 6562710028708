import React, { useEffect, useRef } from 'react';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { alertType, e3, validationType } from 'src/framework/core/utils/utils';
import { Box, Button, Container, Grid, Typography, TextField, Select, Checkbox } from '@material-ui/core';
import {
    ISODialog,
    ISODialogTitle,
    ISODialogContent,
    ISODialogActions
} from '../../framework/components/controls/ISODialog';
import moment from 'moment';
import ActionEvent from 'src/components/actionevent/ActionEvent';

/**
 * 출입증 - 팝업
 * @returns {JSX.Element}
 * @constructor
 */

const AccessCard_PopUp = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { item, ...others } = props;
    const [userInfo, setUserInfo] = React.useState([]);
    const [startToEndDate, setStartToEndDate] = React.useState('');
    const [inOutPlace, setInOutPlace] = React.useState('');

    React.useImperativeHandle(ref, () => ({
        showDialog() {
            setIsOpen(true);
            onSearch();
            setTimeout(() => {}, 600);
        }
    }));

    const onSearch = async () => {
        let info = JSON.parse(item.JsonData)[0];
        let startDate = `${decodeURI(info.START_DATE)}`;
        let endDate = info.END_DATE ? `${decodeURI(info.END_DATE)}` : '기간없음';

        setUserInfo(info);
        setStartToEndDate(startDate + ' ~ ' + endDate);
        if (info.REQ_TYPE == 'REQ_TYPE_001') {
            let place = await ActionEvent.GetInOutPlace();
            place = JSON.parse(place.JsonData)[0];
            setInOutPlace(place.INOUT_PLACE);
        }
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <React.Fragment>
                <ISODialog
                    isOpen={isOpen}
                    onClose={onClose}
                    isReasize={false}
                    isDraggable={false}
                    dialogId="ACCESS_CARD_Dialog"
                    fullScreen
                    fullWidth={true}
                >
                    <Box className="DashboardLayoutContentBox pass_card">
                        <Box>
                            <Typography color="textPrimary" variant="h2">
                                출입증
                            </Typography>
                        </Box>
                        <Container maxWidth="sm">
                            <div className="profile_wrap">
                                <div className="profile">
                                    <img id="picture" src={ActionEvent.GetPersonalSajin()} />
                                </div>
                                <h4>
                                    {userInfo.CMP_NAME}
                                    <span>{userInfo.EMP_NM}</span>
                                </h4>
                            </div>
                            {userInfo.REQ_TYPE && userInfo.REQ_TYPE == 'REQ_TYPE_001' ? (
                                <div className="pass_info">
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">출입기간</span>{' '}
                                                <span className="dashboad_value">{startToEndDate}</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">출입목적</span>{' '}
                                                <span className="dashboad_value">상주협력사 출입</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">출입구역</span>{' '}
                                                <span className="dashboad_value">
                                                    {/* 바꿔야 된다 */}
                                                    {inOutPlace ? inOutPlace : ''}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">차 량</span>{' '}
                                                <span className="dashboad_value">
                                                    {userInfo.CAR_NUM ? userInfo.CAR_NUM : '도보'}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : (
                                <div className="pass_info">
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">방문기간</span>{' '}
                                                <span className="dashboad_value">{startToEndDate}</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">담당자</span>{' '}
                                                <span className="dashboad_value">
                                                    {userInfo.MANAGER_NAME}({userInfo.MANAGER_DEPT_NAME})
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">방문구역</span>{' '}
                                                <span className="dashboad_value">
                                                    {userInfo.VISIT_PLACE ? userInfo.VISIT_PLACE : ''}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">방문목적</span>{' '}
                                                <span className="dashboad_value">{userInfo.VISIT_PURPOSE_NAME}</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">차 량</span>{' '}
                                                <span className="dashboad_value">
                                                    {userInfo.CAR_NUM ? userInfo.CAR_NUM : '도보'}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">차 종</span>{' '}
                                                <span className="dashboad_value">
                                                    {userInfo.CAR_TYPE
                                                        ? userInfo.CAR_DETAIL + '(' + userInfo.CAR_TYPE + ')'
                                                        : ''}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} className="BoxBetween">
                                        <Grid item>
                                            <Typography color="textSecondary" gutterBottom variant="h4">
                                                <span className="dashboad_label">공정출입차량</span>{' '}
                                                <span className="dashboad_value">
                                                    {userInfo.CAR_NUM
                                                        ? userInfo.PROCESS_AREA_YN == 'Y'
                                                            ? '공정'
                                                            : '일반'
                                                        : ''}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            <div className="f_logo">
                                <img src="./static/images/logo.png" alt="" />
                            </div>
                            <Grid item className="btn_wrap">
                                <Button fullWidth size="large" type="submit" variant="contained" onClick={onClose}>
                                    닫기
                                </Button>
                            </Grid>
                        </Container>
                    </Box>
                </ISODialog>
            </React.Fragment>
        </>
    );
});

export default AccessCard_PopUp;
