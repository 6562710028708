import React from 'react';
import loadImage from 'blueimp-load-image';
import { Box, Button, Card, CardActions, CardContent } from '@material-ui/core';
import { e3, alertType } from 'src/framework/core/utils/utils';
import ActionEvent from '../actionevent/ActionEvent';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';
//CR30876 LFC EQS담당 박유적 책임 요청사항
//모바일 오류 수정
import { elementType } from 'prop-types';
import { RemainingTimeDisplay } from 'video-react';

const AccountProfile = (props) => {
    const { Camera, setIsLoading, ...others } = props;
    const { signinUser, stateUser, getReloadUserData, goToPage } = useUserInfoPublicAccount();
    const imageInput = React.useRef();
    //CR30876 LFC EQS담당 박유적 책임 요청사항
    //모바일 오류 수정    
    const refImg = React.useRef();

    const loadCamera = (e) => {
        setIsLoading(true);
        Camera.current.showDialog();
        setIsLoading(false);
    };
    const handleGoToPage = async () => {
        await getReloadUserData();
        setTimeout(() => {
            goToPage('/app/videoplayer');
        }, 300);
    };

    const onClose = () => {
        goToPage('/app/dashboard');
    };

    const onCickImageUpload = () => {
        imageInput.current.click();
    };

    const handleChangeFile = (event) => {
        //CR30876 LFC EQS담당 박유적 책임 요청사항
        //모바일 오류 수정        
        //수정된 소스 (2022 1125) 사진 첨부시 돌아가는 문제 수정
        const element = event.target;
        const file = element.files && element.files[0];
        if(file){
            loadImage(file,{meta : true, canvas : true, orientation : true}).then((img)=>{

                img.image.toBlob((blob) =>{
                    const reader = new FileReader();
                    const rotate = new File([blob],file.name,{type : file.type});
                    reader.readAsDataURL(rotate);
                    reader.onload =() =>{
                        const base64 = reader.result;
                        refImg.current.src  = base64;
                        sendendImg(base64);
                    }
                },'image/jpeg');
            });
        }
        //기존소스(2022 11 25)
        // if (event.target.files) {
        //     // 모바일 업로드 시 회전되는 문제
        //     loadImage(
        //         event.target.files[0],
        //         function (img, data) {
        //             // 2. 이미지 파일 데이터에 imageHead와 exif가 있는지 확인
        //             if (data.imageHead && data.exif) {
        //                 // 3. exif 값이 있다면 orientation 값을 1로 변경
        //                 loadImage.writeExifData(data.imageHead, data, 'Orientation', 1);
        //                 img.toBlob(function (blob) {
        //                     loadImage.replaceHead(blob, data.imageHead, async function (newBlob) {
        //                         newBlob.name = event.target.files[0].name;
        //                         // 4. 기존 메서드로 파일 업로드
        //                         let reader = new FileReader();
        //                         reader.readAsDataURL(newBlob);
        //                         reader.onloadend = () => {
        //                             const base64 = reader.result;
        //                             sendendImg(base64);
        //                         };
        //                     });
        //                 }, 'image/jpeg');
        //             } else {
        //                 // exif 값 없으면 업로드
        //                 let reader = new FileReader();
        //                 reader.readAsDataURL(event.target.files[0]);
        //                 reader.onloadend = () => {
        //                     const base64 = reader.result;
        //                     sendendImg(base64);
        //                 };
        //             }
        //         },
        //         { meta: true, orientation: true, canvas: true }
        //     );
        // }
    };

    const sendendImg = async (dataUri) => {
        setIsLoading(true);

        let message = '';
        let resultCode = '';
        try {
            const response = await ActionEvent.SetUserSajin(dataUri);
            resultCode = response.KeyValues.find((item) => item.Key === 'CODE').Value;
            message = response.KeyValues.find((item) => item.Key === 'Message').Value;

            if (parseInt(resultCode) === 200) {
                ActionEvent.SaveSessionPersonalSajin(dataUri);
                await ActionEvent.Save6ReloadUserData();
                e3.modal.alert(alertType.Info, message);
            } else if (parseInt(resultCode) === 510) {
                e3.modal.alert(alertType.Error, message);
            } else if (parseInt(resultCode) === 511) {
                e3.modal.alert(alertType.Error, message);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            e3.modal.alert(alertType.Error, e.message);
            resultCode = '-500';
        }
    };

    return (
        <Card className="MuiPaper-common" {...others}>
            <CardContent>
                <Box className="CardContentGridItem4Center">
                    <Box className="picture-img-wrap mt-20">
                        <div style={{ height: '200px', overflow: 'hidden' }}>
                            {/*CR30876 LFC EQS담당 박유적 책임 요청사항*/}
                            {/*모바일 오류 수정*/}
                            <img
                                ref={refImg}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain'
                                }}
                                src={ActionEvent.GetPersonalSajin()}
                            />
                        </div>
                    </Box>
                    <Box className="note">
                        <h6>중요</h6>
                        <ul>
                            <li>얼굴 촬영하기 버튼을 누르시면 카메라 엑세스 허용 요청이 나타납니다.</li>
                            <li>"허용"을 눌러야 사진 촬영이 가능합니다.</li>
                            <li>"사진첨부" 기능을 이용하는 경우 정상적으로 사진이 업로드 되었는지 확인이 필요합니다.</li>
                        </ul>
                    </Box>
                </Box>
            </CardContent>
            <CardActions className="btn_wrap">
                <Button color="primary" fullWidth size="large" type="submit" variant="contained" onClick={loadCamera}>
                    (재)촬영
                </Button>
                <>
                    <input
                        type="file"
                        ref={imageInput}
                        style={{ display: 'none' }}
                        onChange={(e) => handleChangeFile(e)}
                    />
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={onCickImageUpload}
                    >
                        사진첨부
                    </Button>
                </>
                <Button fullWidth size="large" type="submit" variant="contained" onClick={onClose}>
                    돌아가기
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountProfile;
