import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControlLabel,
    Grid,
    Typography,
    RadioGroup,
    Radio
} from '@material-ui/core';
import useSignInPublicAccount from 'src/framework/core/hooks/useSignInPublicAccount';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';

// 안전준수서약서(수송자)
const SafeComplState = (props) => {
    const userInfo = useSelector((state) => state.user);
    const { setStep2Allow } = useSessionStorage(e3.sessionKeys.LoginSign);
    const { SignIn, loadLoginInfo } = useSignInPublicAccount();
    const { signinUser, stateUser, goToPage } = useUserInfoPublicAccount();
    const { userid, userpw, remember } = loadLoginInfo();
    const navigate = useNavigate();
    const [value1, setValue1] = React.useState('N');

    const onClose = () => {
        goToPage('/app/dashboard');
    };

    function handleClick(e) {
        if (value1 === 'Y') {
            if (userInfo.isLoggedIn === true) {
                ActionEvent.AllowVow((response, error) => {
                    if (error === null && response) {
                        setStep2Allow();
                        setTimeout(() => {
                            onClose();
                        }, 300);
                    }
                });
            } else {
                setStep2Allow();
                onClose();
            }
        } else {
            e3.modal.alert(alertType.Info, '전체 동의 후 진행 가능합니다.');
        }
    }

    return (
        <>
            <React.Fragment>
                <Helmet>
                    <title>방문신청</title>
                </Helmet>
                <Box className="DashboardLayoutContentBox privacy_wrap">
                    <Box>
                        <Typography color="textPrimary" variant="h2">
                            안전 준수 서약서
                        </Typography>
                    </Box>
                    <Container maxWidth="lg">
                        <div>
                            <form {...props}>
                                <div>
                                    <div className="agree-page-block marginTop-20">
                                        <p className="agree-page-line">
                                            본인은 롯데정밀화학(주)에서 자재 하차 시 다음사항을 준수하겠습니다.
                                        </p>
                                        <p className="agree-page-line center marginTop-10">- 다음 -</p>
                                    </div>
                                    <div className="agree-page-block">
                                        {userInfo.user.PLANT_ID == 'PLNT100' ? (
                                            <h3 className="marginTop-10">
                                                1. 차량운행 속도를 준수하겠습니다.
                                                <br />
                                                (주도로 19km/h, 공정구역 10km/h, 창고 내 5km/h)
                                            </h3>
                                        ) : (
                                            <h3 className="marginTop-10">
                                                1. 입차 시 안내된 공간에 주정차를 하겠습니다.
                                            </h3>
                                        )}
                                        {userInfo.user.PLANT_ID == 'PLNT100' ? (
                                            <h3>2. 운행 중, 정차 시 비상등을 상시 가동하겠습니다.</h3>
                                        ) : (
                                            <h3>2. 정차 시 시동을 끄겠습니다. </h3>
                                        )}
                                        
                                        <h3>
                                            3. 하차작업시 시동을 끄고 키 보관함에 키 보관하고 고임목 설치 및 접지선
                                            연결후 작업 하겠습니다.
                                        </h3>
                                        <h3>4. 지정된 장소 외 흡연하지 않겠습니다.(화장실 흡연 금지)</h3>
                                        <h3>
                                            5. 반팔, 슬리퍼 등 신체 노출이 있는 복장을 하지 않으며,
                                            안전보호구(안전모(턱근착용), 안전화, 안전안경)를 필히 착용하겠습니다.
                                        </h3>
                                        <h3>
                                            6. 컨테이너는 고정콘을 반드시 체결하며(4개소) 컨테이너 문을 열 때에는 즉시
                                            고정하겠습니다.
                                        </h3>
                                        <h3>7. 컨테이너, 탱크로리, 카고트럭에서 내려올 때 뛰어내리지 않겠습니다.</h3>
                                        <h3>
                                            8. 작업 대기시에는 차량에서 대기하며, 지게차 등 작업반경 내에 접근하지
                                            않겠습니다.
                                        </h3>
                                        <h3>9. 이동시에는 컨테이너, 탱크로리, 카고트럭 문을 닫고 이동하겠습니다.</h3>
                                        <h3>
                                            10. 귀사 안전관리자의 현당 작업자(지게차기사 및 당사직원)의 지시에 따르며
                                            관리 감독자 없이 자발적으로 벨브등의 조치를 하지 않겠습니다.
                                        </h3>
                                        <h3>
                                            11. 봉인 잔재물 및 그외 폐기물을 불법투기 하지 않고 지정된 장소에
                                            처리하겠습니다.
                                        </h3>
                                    </div>
                                </div>
                                <hr />
                                <p className="agree-page-line marginTop-20">
                                    본인은 상기 내용을 교육받았고, 숙지하였으며, 이를 준수하겠음.
                                    <br />
                                    불이행시 100% 본인과실이며 어떠한 처벌도 감수할 것을 서약합니다.
                                </p>
                                <Card className="MuiPaper-common marginTop-20">
                                    <CardContent className="cardcontent-common">
                                        <Grid container wrap="wrap">
                                            <Grid
                                                item
                                                md={12}
                                                sm={12}
                                                className="CardContentGridItem agree-wrap"
                                                xs={12}
                                            >
                                                <Box className="CardContentGridItem">
                                                    <h3 className="marginTop-0">안전 준수 서약서에</h3>
                                                    <RadioGroup
                                                        name="agree1"
                                                        value={value1}
                                                        onChange={(e) => {
                                                            setValue1(e.target.value);
                                                        }}
                                                    >
                                                        <div>
                                                            <FormControlLabel
                                                                value="Y"
                                                                control={<Radio />}
                                                                label={'동의함'}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <FormControlLabel
                                                                value="N"
                                                                control={<Radio />}
                                                                label={'동의하지 않음'}
                                                            />
                                                        </div>
                                                    </RadioGroup>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <br />
                                    <Button color="primary" size="large" variant="contained" onClick={handleClick}>
                                        확인
                                    </Button>
                                </Card>
                            </form>
                        </div>
                    </Container>
                </Box>
            </React.Fragment>
        </>
    );
};

export default SafeComplState;
