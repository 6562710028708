import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { alertType, e3 } from '../../framework/core/utils/utils';
import { TextField, Button, InputAdornment, SvgIcon } from '@material-ui/core';
import { MoreHorizontal, Search as SearchIcon, TrendingUp } from 'react-feather';

const API_URL = process.env.REACT_APP_PROXY.replace('lottechem', 'FileTransferService');

const FileUpload = React.forwardRef((props, ref) => {
    const [properties, setProperties] = React.useState([]);
    const inputRef = React.useRef(null);

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const handleFileInput = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                let nBytes = e.target.files[i].size;
                toBase64(e.target.files[i], function (result) {
                    setProperties((properties) => [
                        ...properties,
                        {
                            selectedFile: e.target.files[i].name,
                            displaySize: fileSize(nBytes),
                            base64File: result
                        }
                    ]);
                });
                if (props.autoUpload) handlePost(e.target.files[i]); // 자동으로 업로드 한다.
            }
        }
    };

    React.useEffect(() => {}, [properties]);

    const toBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file); // Image
        reader.onload = function () {
            // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            // if ((encoded.length % 4) > 0) {
            //   encoded += '='.repeat(4 - (encoded.length % 4));
            // }
            // resolve(encoded);
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const handlePost = async (file) => {
        const req = new FormData();
        if (file) {
            req.append('fileStream', file);
            const config = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            };
            try {
                const result = await axios.post(`${API_URL}/Upload`, req, config);
                return JSON.parse(result.data);
            } catch (e) {
                e3.modal.alert(alertType.Error, e.message);
            }
        }
        return;
    };

    const handleInputClick = () => {
        inputRef.current.click();
    };

    // Ref Event 상위로 전달 설정
    React.useImperativeHandle(ref, () => ({
        getFile() {
            return properties;
        },
        getUploadedFile() {
            var ret = handlePost(properties.selectedFile);
            return ret;
        },
        Reset() {
            setProperties({
                selectedFile: '',
                displaySize: '',
                base64File: ''
            });
        }
    }));

    return (
        <div className="e3-file-upload-control MuiInputBase-fullWidth">
            <input
                ref={inputRef}
                type="file"
                name="e3-control-file"
                onChange={(e) => handleFileInput(e)}
                style={{ display: 'none' }}
                multiple={true}
            />
            <TextField
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SvgIcon fontSize="small" color="action">
                                <MoreHorizontal />
                            </SvgIcon>
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                size="small"
                value={properties ? properties.map((item, num) => item.selectedFile) : ''}
                disabled={false}
                className="ISOTextField"
                placeholder="파일을 선택하세요! (여기를 선택하세요)"
                onClick={handleInputClick}
            />
            {props.useDebugButton && (
                <div className="btn_wrap inline-block vertical_top">
                    <Button type="button" onClick={handlePost}>
                        {' '}
                        파일 업로드{' '}
                    </Button>
                </div>
            )}
        </div>
    );
});

FileUpload.propTypes = {
    useDebugButton: PropTypes.bool,
    autoUpload: PropTypes.bool
};
FileUpload.defaultProps = {
    useDebugButton: false,
    autoUpload: false
};

export default FileUpload;
