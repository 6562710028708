/* eslint-disable no-prototype-builtins */
import { useDispatch } from 'react-redux';
import useLocalStorage from './useLocalStorage';
import useSessionStorage from './useSessionStorage';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import { dataList, dataReset } from '../reducers/FavoritMenuReducer';
import { menuList, menuReset } from '../reducers/MenuReducer';
import { e3, alertType } from 'src/framework/core/utils/utils';

/**
 * 프로그램 메뉴 정보를 API Server에서 가져온다
 * 로컬 스토리지에서 저장, 제거, 읽기를 실행한다.
 * 데이타베이스으 복잡한 쿼리 없이 Flat2Hierarchy를 지원
 */
export default function useMenuLoad() {
    const dispathcer = useDispatch();
    const dispatchFavoritMenu = (data) => dispathcer(dataList(data));
    const dispatchFavoritMenuReset = () => dispathcer(dataReset());
    const dispatchMenu = (data) => dispathcer(menuList(data));
    const dispatchMenuReset = () => dispathcer(menuReset());
    const { loadSessionStorage } = useSessionStorage('loginSign');
    const { localStorageSave, localStorageLoad, localStorageRemove } = useLocalStorage();

    const MenuSave = async (userId, plantId) => {
        let params = DataPackage.create('SGN100', 'GetMenus');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', plantId || ''));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', userId));
        try {
            const response = await ISOAxios.Execute(params);
            if (response) {
                localStorageRemove('MENU');
                localStorageSave('MENU', response.JsonData);
                const jsonData = JSON.parse(response.JsonData);
                dispatchMenu(jsonData);
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };

    const MenuLoad = () => localStorageLoad('MENU');
    const MenuRemove = () => {
        localStorageRemove('MENU');
        dispatchMenuReset();
    };

    const Flat2Hierarchy = () => {
        const flatMenu = JSON.parse(MenuLoad());
        // eslint-disable-next-line no-use-before-define
        return Flat2Hierarchy4Args(flatMenu);
    };

    const Flat2Hierarchy4Args = (flatMenu) => {
        const hierarchyMenu = [];
        const mappedArr = {};
        let arrElem;
        let mappedElem;
        try {
            for (let i = 0, len = flatMenu.length; i < len; i++) {
                arrElem = flatMenu[i];
                mappedArr[arrElem.MENU_ID] = arrElem;
                mappedArr[arrElem.MENU_ID].children = [];
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const MENU_ID in mappedArr) {
                if (mappedArr.hasOwnProperty(MENU_ID)) {
                    mappedElem = mappedArr[MENU_ID];
                    if (mappedElem.PARENT_ID) {
                        try {
                            mappedArr[mappedElem.PARENT_ID].children.push(mappedElem);
                        } catch (e) {
                            e3.modal.alert(alertType.Error, e.message);
                        }
                    } else {
                        hierarchyMenu.push(mappedElem);
                    }
                }
            }
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
        return hierarchyMenu;
    };

    const GetFavoritMenu = async (userId, plantId) => {
        let params = DataPackage.create('SGN100', 'GetFavoritMenu');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', plantId || ''));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', userId));
        try {
            const response = await ISOAxios.Execute(params);
            if (response) {
                localStorageRemove('FAVORITMENU');
                localStorageSave('FAVORITMENU', response.JsonData);
                const jsonData = JSON.parse(response.JsonData);
                dispatchFavoritMenu(jsonData);
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };
    const SetFavoritMenu = async (MENU_ID) => {
        const signinUser = loadSessionStorage();
        let params = DataPackage.create('SGN100', 'SetFavoritMenu');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', signinUser.PLT_ID || null));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', signinUser.USER_ID));
        params.KeyValues.push(DataPackage.NewKeyValue('MENU_ID', MENU_ID));
        try {
            const response = await ISOAxios.Execute(params);
            if (response) {
                localStorageRemove('FAVORITMENU');
                localStorageSave('FAVORITMENU', response.JsonData);
                const jsonData = JSON.parse(response.JsonData);
                dispatchFavoritMenu(jsonData);
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };
    const LoadFavoritMenu = () => localStorageLoad('FAVORITMENU');
    const RemoveFavoritMenu = () => {
        localStorageRemove('FAVORITMENU');
        dispatchFavoritMenuReset();
    };

    return {
        MenuSave,
        MenuLoad,
        MenuRemove,
        GetFavoritMenu,
        SetFavoritMenu,
        LoadFavoritMenu,
        RemoveFavoritMenu,
        Flat2Hierarchy,
        Flat2Hierarchy4Args
    };
}
