import React, { useEffect, useRef } from 'react';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { alertType, e3, validationType } from 'src/framework/core/utils/utils';
import { Box, Button, Container, Grid, Typography, TextField, Select, Checkbox } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    ISODialog,
    ISODialogTitle,
    ISODialogContent,
    ISODialogActions
} from '../../framework/components/controls/ISODialog';
import { ISOTextField, ISOButton } from 'src/framework/components/controls';

/**
 * 회사등록 - 팝업
 * @returns {JSX.Element}
 * @constructor
 */

const Company_PopUp1 = React.forwardRef((props, ref) => {
    const { callbackAction, selectedItem, ...others } = props;
    const [isOpen, setIsOpen] = React.useState(false);

    const popupTitle = '회사 등록';

    const refcmpName = useRef(ISOTextField);
    const refcmpNo = useRef(ISOTextField);
    const refplateid = useRef(ISOTextField);

    /* 10. function */
    // 팝업 오픈 및 조회
    React.useImperativeHandle(ref, () => ({
        showDialog() {
            setIsOpen(true);
            setTimeout(() => {}, 600);
        }
    }));

    // 팝업 종료
    const onClose = () => {
        setIsOpen(false);
    };

    const onSelect = (value) => {
        let result = {
            CMP_ID: Number(value[0].CMP_ID),
            CMP_NM: value[0].CMP_NM
        };
        onClose();
        callbackAction([result]);
    };

    // 리스트 등록
    const onInsert = async () => {
        let cmpName = e3.string.stringCmpCheck(refcmpName.current.getValue()) || '';
        let cmpNum = e3.string.stringNumCheck(refcmpNo.current.getValue()) || '';
        if (cmpName == '' || cmpNum == '') {
            e3.modal.alert(alertType.Error, '회사명과 사업자번호를 정확히 입력해주세요.');
            return;
        } else if (cmpNum.length != 10) {
            e3.modal.alert(alertType.Error, '사업자번호를 정확히 입력해주세요.');
            return;
        }

        try {
            let param = DataPackage.init();
            param.KeyValues.push(DataPackage.NewKeyValue('CMP_NM', cmpName));
            param.KeyValues.push(DataPackage.NewKeyValue('CMP_NO', cmpNum));
            param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', refplateid.current.getValue()));

            let response = await ISOAxios.InsertCmp(param);
            if (response.KeyValues[0].Value == '200') {
                response = JSON.parse(response.JsonData);
                if (response) {
                    onSelect(response);
                }
            } else {
                e3.modal.alert(alertType.Error, response.ErrorMessage);
            }
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    };

    return (
        <>
            <React.Fragment>
                <ISODialog
                    isOpen={isOpen}
                    onClose={onClose}
                    isReasize={false}
                    isDraggable={false}
                    dialogId="COMPANY_POPUP2_Dialog"
                    fullScreen
                    fullWidth={true}
                >
                    <ISODialogTitle onClose={onClose} id="COMPANY_POPUP2_Dialog">
                        {popupTitle}
                    </ISODialogTitle>
                    <Box className="DashboardLayoutContentBox">
                        <Container maxWidth="sm">
                            <div className="isodialog_search">
                                <p>업체를 신규로 등록하세요.</p>
                            </div>
                            <Grid container wrap="wrap">
                                <table>
                                    <tr>
                                        <td className="required">
                                            {/* PLATE_ID */}
                                            <ISOTextField
                                                select={true}
                                                ref={refplateid}
                                                variant="outlined"
                                                codeId={'PLANTID'}
                                                defaultindex={0}
                                                name="plantId"
                                                type="text"
                                                required={true}
                                                textlabel={'방문사업장'}
                                                margin="normal"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="required">
                                            {/* 회사명 */}
                                            <ISOTextField
                                                ref={refcmpName}
                                                name="CMP_NM"
                                                type="text"
                                                variant="outlined"
                                                required={true}
                                                textlabel={'회사명'}
                                                margin="normal"
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        refcmpName.current.setValue(
                                                            e3.string.stringCmpCheck(e.target.value)
                                                        );
                                                    }
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="required">
                                            {/* 사업자번호 */}
                                            <ISOTextField
                                                ref={refcmpNo}
                                                name="CMP_NO"
                                                type="text"
                                                variant="outlined"
                                                margin="normal"
                                                required={true}
                                                textlabel={'사업자번호'}
                                                placeholder={'숫자만 입력하세요'}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        refcmpNo.current.setValue(
                                                            e3.string.toCompanyNumberFormat(
                                                                e3.string.stringNumCheck(e.target.value)
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <p className="f_note marginLeft-0">
                                                * 사업자번호를 하이픈(-)없이 입력하세요.
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                                <Grid container spacing={2} className="btn_wrap">
                                    <Grid item xs={6}>
                                        <ISOButton color="primary" fullWidth size="large" onClick={onInsert}>
                                            등록
                                        </ISOButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ISOButton fullWidth size="large" onClick={onClose}>
                                            취소
                                        </ISOButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </ISODialog>
            </React.Fragment>
        </>
    );
});

export default Company_PopUp1;
