import React from 'react';
import { Helmet } from 'react-helmet';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import { Box, Container, Grid, Button, Typography, Card, CardContent } from '@material-ui/core';
import DataCheckCard from 'src/components/dashboard/DataCheckCard';
import InoutCheckCard from 'src/components/dashboard/InoutCheckCard';
import CompanyInfoCard from 'src/components/dashboard/CompanyInfoCard';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import Loader from 'src/components/loading/Loader';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const VisitHistory = () => {
    const { signinUser, stateUser, goToPage } = useUserInfoPublicAccount();
    const [isLoading, setIsLoading] = React.useState(true);
    const [infoArray, setInfoArray] = React.useState([]);
    const navigate = useNavigate();

    React.useEffect(async () => {
        if (stateUser.privacyAllow === false) {
            goToPage('/app/settings');
        } else {
            setTimeout(() => {
                onSearchInfo();
                setIsLoading(false);
            }, 500);
        }

        return () => {};
    }, []);

    // 사용자 정보를 다시 로드 한다.
    React.useEffect(() => {
        ActionEvent.Save6ReloadUserData();
        setTimeout(() => {
            onSearchInfo();
            setIsLoading(false);
        }, 500);
    }, []);

    const onSearchInfo = async () => {
        let info = await ActionEvent.getUserHisData();
        setInfoArray(JSON.parse(info));
    };

    const onViewInfo = (prop) => {
        navigate('/app/visithistoryview', {
            state: {
                usePlantId: prop.PLANT_ID,
                useEmpNm: prop.EMP_NM,
                useEmpTelNo: prop.EMP_TEL_NO,
                req: prop.VISIT_REQ_SEQ,
                flag: 'H'
            }
        });
    };

    const onAddInfo = (prop) => {
        navigate('/app/applicationforentry', {
            state: {
                usePlantId: prop.PLANT_ID,
                useEmpNm: prop.EMP_NM,
                useEmpTelNo: prop.EMP_TEL_NO,
                req: prop.VISIT_REQ_SEQ,
                flag: false
            }
        });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        방문 이력 정보
                    </Typography>
                </Box>
                <Container maxWidth={false}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                            {infoArray.map((item, index) => {
                                return (
                                    <AccountHistory
                                        info={infoArray}
                                        value={index}
                                        addinfo={onAddInfo}
                                        viewinfo={onViewInfo}
                                        className="user-info list"
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Loader isShow={isLoading} />
        </React.Fragment>
    );
};

const AccountHistory = (props) => {
    const userValue = ActionEvent.GetUser();
    const { info, value, addinfo, viewinfo, ...other } = props;
    const startVisitDate = info[value].START_DATE ? `${decodeURI(info[value].START_DATE)}` : '';
    const endVisitDate = info[value].END_DATE ? `${decodeURI(info[value].END_DATE)}` : '';
    const visitStatus = info[value].STATUS ? info[value].STATUS : '';
    const visitType = info[value].VISIT_NAME ? info[value].VISIT_NAME : '';
    const visitPorpose = info[value].VISIT_PURPOSE ? info[value].VISIT_PURPOSE : '';
    const visitPlateName = info[value].PLANT_NAME ? info[value].PLANT_NAME : '';
    const visitPlace = info[value].VISIT_PLACE_NAME ? info[value].VISIT_PLACE_NAME : '';
    const managerName = info[value].MANAGER_NAME ? info[value].MANAGER_NAME : '';
    const managerDept = info[value].MANAGER_DEPT_NAME ? info[value].MANAGER_DEPT_NAME : '';

    const hendleViewInfo = () => {
        viewinfo(info[value]);
    };

    const hemdleAddInfo = () => {
        addinfo(info[value]);
    };

    return (
        <Card {...props}>
            <CardContent>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">방문승인 여부</span>{' '}
                            {visitStatus == 'C' ? (
                                <span className="dashboad_value Y">
                                    <i>
                                        <em>승인</em>
                                    </i>
                                </span>
                            ) : (
                                <span className="dashboad_value N">
                                    <i>
                                        <em>미승인</em>
                                    </i>
                                </span>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">방문기간</span>{' '}
                            <span className="dashboad_value">
                                {startVisitDate}~{endVisitDate}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">방문구분</span>{' '}
                            <span className="dashboad_value">{visitType}</span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">방문목적</span>{' '}
                            <span className="dashboad_value">{visitPorpose}</span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">방문장소</span>{' '}
                            <span className="dashboad_value">
                                {visitPlateName} {visitPlace}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h4">
                            <span className="dashboad_label">담당자</span>{' '}
                            <span className="dashboad_value">
                                {managerName}({managerDept})
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="btn_wrap">
                    <Grid item xs={7}>
                        <Button color="primary" fullWidth type="submit" variant="contained" onClick={hemdleAddInfo}>
                            이 정보로 방문신청
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button fullWidth type="submit" variant="contained" onClick={hendleViewInfo}>
                            상세보기
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default VisitHistory;
