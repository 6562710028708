import {
    AlertCircle as AlertCircleIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    User as UserIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    LogOut as LogoutIcon,
    Camera as CameraIcon,
    Image as ImageIcon,
    Youtube as MovieIcon,
    Grid as GridIcon,
    Save as SaveIcon,
    Edit as CreateIcon,
    TableView
} from 'react-feather';

export default [
    {
        href: '/app/dashboard',
        icon: BarChartIcon,
        title: '출입신청정보'
    },
    {
        href: '/app/account',
        icon: UserIcon,
        title: '안면정보등록'
    },
    {
        href: '$',
        icon: MovieIcon,
        title: '개인정보처리방침'
    }
];
