/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import _ from 'lodash';
import { render, unmountComponentAtNode } from 'react-dom';
import React from 'react';
import moment from 'moment';
import ISOConfirmDialog from '../../components/controls/ISOConfirmDialog';
import Loader from '../../components/loaderable/Loader';
import ISOAlert from '../../components/controls/ISOAlert';
import base64 from 'base-64';
import store from 'src/framework/core/reducers/_store';
import {
    login,
    logout,
    clearStore,
    loginWithOher,
    privacyAllow,
    step1Allow,
    step2Allow,
    step3Allow,
    step4Allow,
    step5Allow,
    step6Allow
} from 'src/framework/core/reducers/loginReducer';

// const LOGIN_ACCESS_CUSTOMER = APP_TYPE === 'E3PUBLIC' ? true : false; // 안전교육 대상자

/**
 * true // console enable
 * false // console disable
 */
export const isDebug = () => true;
/**
 * Combine paths
 *
 * @param {string} parent
 * @param {string} child
 * @returns {string}
 */
export const combinePaths = (parent, child) => `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

/**
 * Recursively build paths for each navigation item
 *
 * @param routes
 * @param {string} parentPath
 * @returns {*}
 */
export const buildPaths = (routes, parentPath = '') =>
    routes.map((route) => {
        const path = combinePaths(parentPath, route.path);
        return {
            ...route,
            path,
            ...(route.routes && { routes: buildPaths(route.routes, path) })
        };
    });

/**
 * Recursively provide parent reference for each navigation item
 *
 * @param routes
 * @param parentRoute
 * @returns {*}
 */
export const setupParents = (routes, parentRoute = null) =>
    routes.map((route) => {
        const withParent = {
            ...route,
            ...(parentRoute && { parent: parentRoute })
        };
        return {
            ...withParent,
            ...(withParent.routes && {
                routes: setupParents(withParent.routes, withParent)
            })
        };
    });

/**
 * Convert navigation tree into flat array
 *
 * @param routes
 * @returns {any[]}
 */
export const flattenRoutes = (routes) =>
    routes.map((route) => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

/**
 * Combine all the above functions together
 *
 * @param routes
 * @returns {any[]}
 */
export const generateAppRoutes = (routes) => flattenRoutes(setupParents(buildPaths(routes)));

/**
 * Provides path from root to the element
 *
 * @param route
 * @returns {any[]}
 */
const pathTo = (route) => {
    if (!route.parent) {
        return [route];
    }
    return [...pathTo(route.parent), route];
};
export default pathTo;

/**
 * 그리드 컬럼 정보를 이용하여 이름 정보 확인
 * @param {react-data-grid columns} arr
 * @param {column key} key
 */
export const getNameFromArray = (arr, key) => arr.filter((it) => it.key === key)[0].name;

export const localStorageSave = (name, value) => {
    try {
        if (typeof localStorage === 'undefined') {
            alert('localStorage 를 지원합니다');
            return;
        }
        localStorage.setItem(name, value);
    } catch (e) {
        e3.modal.alert(alertType.Error, e.message);
    }
};
export const localStorageLoad = (name) => {
    try {
        if (typeof localStorage === 'undefined') {
            alert('localStorage 를 지원합니다');
            return;
        }
        // eslint-disable-next-line consistent-return
        return localStorage.getItem(name);
    } catch (e) {
        // eslint-disable-next-line consistent-return
        e3.modal.alert(alertType.Error, e.message);
    }
};
export const localStorageRemove = (name) => {
    try {
        if (typeof localStorage === 'undefined') {
            alert('localStorage 를 지원합니다');
            return;
        }
        localStorage.removeItem(name);
    } catch (e) {
        e3.modal.alert(alertType.Error, e.message);
    }
};

/**
 * window.location.hostname;   // => aaaa.local
 * window.location.href;       // => http://aaaa.local:8088/test.jsp
 * window.location.host;       // => aaaa.local:8088
 * window.location.port;       // => 8088
 * window.location.pathname;   // => test.jsp
 * window.location.search;     // => ?gg=1
 * window.location.protocol;   // => http:
 */
export const getCurrentURL = (def = 'about') => {
    const url =
        window.location.pathname === '/' ? def : window.location.pathname.substring(1, window.location.pathname.length);
    if (url.indexOf('/')) {
        const p = url.split('/');
        return p[0];
    }
    return url;
};
export const getCurrentMenu = () => {
    if (window.location.pathname === '/') return null;
    return _.filter(JSON.parse(localStorageLoad('MENU')), { RMS: window.location.pathname });
};

export const getHeader = (p) => {
    const header = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'X-API-Key': '123'
    });
    return header;
};

export const fetchPost = (url, params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: getHeader(params),
        body: JSON.stringify(params)
        // mode:"no-cors"
    };
    fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => callback(data, null))
        .catch((error) => callback(null, error));
};

export const stringifyComponent = (Comp) => {
    try {
        return JSON.stringify(Comp);
    } catch (err) {
        return String(Comp);
    }
};

export const setCookie = (name, value, days = 7) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};
export const getCookie = (name) => {
    const cname = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(cname) === 0) {
            return c.substring(cname.length, c.length);
        }
    }
    return '';
};
export const toggleFullScreen = () => {
    let ret = false;
    try {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            ret = true;
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            ret = false;
        }
    } catch (e) {
        e3.modal.alert(alertType.Error, e.message);
    }
    return ret;
};

export const paginate = (totalCount, pageblock, pageCurrent, displaypagesize = pageblock) => {
    const pageCount = Math.ceil(totalCount / pageblock);
    if (pageCount === 1) return null;
    const pages = _.range(1, pageCount + 1);
    const startIndex = (pageCurrent - 1) * pageblock;
    return {
        totalCount: totalCount,
        pageblock: pageblock,
        page: pageCurrent,
        itemss: _(pages).slice(startIndex).take(displaypagesize).value()
    };
};
export const setPageCurrent = () => {
    const menuTabs = localStorageLoad('MENUTABS') ? JSON.parse(localStorageLoad('MENUTABS')) : [];
    let isNew = true;
    let currentMenu = null;
    if (getCurrentMenu()) currentMenu = getCurrentMenu()[0];
    console.log('currentMenu', currentMenu);
    if (currentMenu) {
        menuTabs.forEach((element) => {
            if (element.RMS === currentMenu.RMS) isNew = false;
        });
        if (isNew && currentMenu) {
            if (menuTabs.length === 10) menuTabs.shift();
            menuTabs.push(currentMenu);
            localStorageSave('MENUTABS', JSON.stringify(menuTabs));
        }
        console.log('navigation -> Page -> PageBody Current Menu :', menuTabs);
    }
};

export const getNameValue = (eventTarget, name = '') => {
    const ret = { name: '', value: '', type: '' };
    if (name && name.length > 0) {
        ret.name = name;
    } else {
        ret.name = eventTarget.name ? eventTarget.name : eventTarget.id;
    }
    ret.type = eventTarget.type;
    ret.value = eventTarget.value ? eventTarget.value : '';
    return ret;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getYearMonth = () => {
    let dte = new Date();
    let year = dte.getFullYear();
    let month = dte.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return `${year}-${month}`;
};
export const getDate = () => {
    let todaye = new Date();
    let year = todaye.getFullYear();
    let month = todaye.getMonth() + 1;
    let date = todaye.getDate();
    month = month < 10 ? `0${month}` : month;
    date = date < 10 ? `0${date}` : date;
    return `${year}-${month}-${date}`;
};
export const getEndDate = (strdate) => {
    let v = strdate.split('-');
    let enddate = new Date(parseInt(v[0]), parseInt(v[1]), 1 - 1);
    return enddate.getDate();
};
export const getString2Date = (strdate) => {
    let v = strdate.split('-');
    return new Date(parseInt(v[0]), parseInt(v[1]) - 1, parseInt(v[2]));
};
export const getInteger2Date = (year, month, date) => new Date(year, month, date);
export const getString2Day = (strdate) => getString2Date(strdate).getDay();
export const getInteger2Day = (year, month, date) => getInteger2Date(year, month, date).getDay();

export const phoneNumberFomatter = (num, type) => {
    var formatNum = '';
    if (num.length === 11) {
        if (type === 0) {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
        } else {
            formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
    } else if (num.length === 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
        if (num.indexOf('02') === 0) {
            if (type === 0) {
                formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
            } else {
                formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
            }
        } else {
            if (type === 0) {
                formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
            } else {
                formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
        }
    }
    return formatNum;
};

/** ****************************
 * 상수
 ***************************** */
export const divName = {
    conFirmDivName: 'e3-confirm-alert',
    alertDivName: 'e3-alert',
    loadingDivName: 'e3-loading'
};

export const alertType = {
    Error: 'error',
    Warning: 'warning',
    Info: 'info',
    Success: 'success'
};

export const validationType = {
    string: 'string',
    number: 'number',
    email: 'email',
    phone: 'phone',
    require: ''
};

const SessionKeys = {
    LoginSign: 'loginSign',
    IsFullScreen: 'isFullScreen'
};
export const e3 = {
    base64Decode: (data) => base64.decode(data),
    base64Encode: (data) => base64.encode(data),
    sleep: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
    sessionKeys: SessionKeys,
    regex: {
        number: /^[0-9]/g,
        email: /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/,
        phone: /^\d{2,3}\d{3,4}\d{4}$/,
        phoneWithHyphen: /^\d{2,3}-\d{3,4}-\d{4}$/
    },
    /** ****************************
     * Modal
     ***************************** */
    modal: {
        alert: (alertType, contents) => {
            const component = <ISOAlert alertType={alertType} contents={contents} />;

            e3.ui.createElement(divName.alertDivName, component);
        },
        confirm: (title, contents, confirmtext, closetext, onConfirmClick, onCloseClick) => {
            const component = (
                <ISOConfirmDialog
                    title={title}
                    contents={contents}
                    onCloseClick={onCloseClick}
                    confirmtext={confirmtext}
                    onConfirmClick={onConfirmClick}
                    closetext={closetext}
                />
            );

            e3.ui.createElement(divName.conFirmDivName, component);
        },
        custom: (title, contents, onConfirmClick, onCloseClick) => {
            const component = (
                <ISOConfirmDialog
                    title={title}
                    contents={contents}
                    onConfirmClick={onConfirmClick}
                    onCloseClick={onCloseClick}
                    flag={true}
                />
            );

            e3.ui.createElement(divName.conFirmDivName, component);
        }
    },

    /** ****************************
     * Ui
     ***************************** */
    ui: {
        createElement: (divName, component) => {
            let divTarget = document.getElementById(divName);
            if (divTarget) {
                render(component, divTarget);
            } else {
                divTarget = document.createElement('div');
                divTarget.id = divName;
                document.body.appendChild(divTarget);
                render(component, divTarget);
            }
        },
        removeElementReconfirm: (divName) => {
            const target = document.getElementById(divName);
            if (target) {
                unmountComponentAtNode(target);
                target.parentNode.removeChild(target);
            }
        },
        showLoading: () => {
            e3.ui.createElement(divName.loadingDivName, <Loader />);
        },
        hideLoading: () => {
            e3.ui.removeElementReconfirm(divName.loadingDivName);
        }
    },
    data: {
        /**
         * string or array to jaxdatasource
         * @param datasource
         * @returns {null|*}
         */
        dataAdapter: (datasource) => {
            if (!datasource) return null;

            let convertSource = null;

            if (typeof datasource === 'string') {
                convertSource = JSON.parse(datasource);
            } else {
                convertSource = datasource;
            }
            return new jqx.dataAdapter({
                datatype: 'array',
                localdata: convertSource
            });
        },
        /**
         * control Validation
         * @param type
         * @param args(...n)
         * @returns {boolean}
         */
        validator: (type = validationType.require, ...args) => {
            if (!args) {
                e3.modal.alert(alertType.Error, '파라미터를 전달해주세요!');
                return false;
            }

            if (args.length % 2 === 1) {
                e3.modal.alert(alertType.Error, '파라미터에 컨트롤과 메세지 형식으로 전달해주세요!');
                return false;
            }

            for (let i = 0; i < args.length; i += 2) {
                if (typeof args[i] !== 'object' || !args[i].current) {
                    e3.modal.alert(alertType.Error, '파라미터 형식이 올바르지 않습니다. Component');
                    return false;
                }

                if (typeof (args[i] + 1) !== 'string') {
                    e3.modal.alert(alertType.Error, '파라미터 형식이 올바르지 않습니다. string');
                    return false;
                }

                if (type === validationType.require) {
                    if (!args[i].current.getValue()) {
                        e3.modal.alert(alertType.Error, `${args[i + 1]}는(은) 필수입니다.`);
                        return false;
                    }
                } else if (type === validationType.number) {
                    if (!args[i].current.getValue() && !e3.regex.number(args[i].current.getValue())) {
                        e3.modal.alert(alertType.Error, '숫자만 입력해주세요!');
                        return false;
                    }
                } else if (type === validationType.email) {
                    if (!args[i].current.getValue() && !e3.regex.email(args[i].current.getValue())) {
                        e3.modal.alert(alertType.Error, '이메일 형식으로 입력해주세요. aaa@aaa.com');
                        return false;
                    }
                } else if (type === validationType.phone) {
                    if (!args[i].current.getValue() && !e3.regex.phone(args[i].current.getValue())) {
                        e3.modal.alert(alertType.Error, '전화번호 형식으로 입력해주세요! 010-1234-4556');
                        return false;
                    }
                }
            }

            return true;
        }
    },
    grid: {
        /**
         * column default options;
         */
        columnOpt: {
            text: '',
            datafield: '',
            sortable: true,
            columngroup: '',
            columntype: 'string',
            cellsrenderer: null,
            validation: null,
            cellvaluechanging: null,
            cellsformat: '',
            cellclassname: '',
            align: 'center',
            cellsalign: 'left',
            // width: 150,
            resizable: true,
            draggable: true,
            editable: false,
            classname: '',
            hidden: false,
            buttonclick: null,
            pinned: false
            // createeditor: null,
        },
        columngroupOpt: {
            text: '',
            align: 'center',
            name: '',
            parentgroup: ''
        },
        /**
         * 칼럼 추가
         * @param options
         * @returns {{buttonclick: null, datafield: string, hidden: boolean, resizable: boolean, editable: boolean, columngroup: string, sortable: boolean, cellsrenderer: null, align: string, cellclassname: string, draggable: boolean, classname: string, width: number, cellsalign: string, text: string, cellvaluechanging: null, cellsformat: string, validation: null, columntype: string}}
         */
        setColumn: (options) => {
            let opt = options;
            let target = e3.grid.columnOpt;

            if (opt.datafield && opt.datafield === 'NO') {
                opt.width = 50;
                opt.cellsalign = 'center';
            }

            // 셀 정렬
            if (opt.columnType === 'numberinput' && !opt.cellsalign) {
                opt.cellsalign = 'right';
            } else if (opt.columnType === 'string' && !opt.cellsalign) {
                opt.cellsalign = 'left';
            } else if (opt.columnType === 'date' && !opt.cellsalign) {
                opt.cellsalign = 'center';
            }

            // column format setting
            if (opt.columnType === 'date' && !opt.cellsformat) {
                opt.cellsformat = 'yyyy-MM-dd HH:mm:ss';
            } else if (opt.columnType === 'numberinput' && !opt.cellsformat) {
                opt.cellsformat = 'D';
            }

            // grid combobax 자동 생성
            if (!opt.cellsformat && opt.columnType === 'combobox') {
                opt.displayfield = '';
                opt.createeditor = (row, value, editor) => {
                    editor.jqxComboBox({
                        source: e3.data.dataAdapter(opt.source),
                        displayMembuer: 'LABEL',
                        valueMember: 'VALUE'
                    });
                };
            }

            if (opt.width === 0 && !opt.hidden) {
                opt.hidden = true;
            }

            return { ...target, ...opt };
        },
        setColumnGroup: (options) => {
            let opt = options;
            let target = e3.grid.columngroupOpt;

            return { ...target, ...opt };
        },
        lpad: (s, padLength, padString = '0') => {
            while (s.length <= padLength) s = padString + s;
            return s;
        },

        rpad: (s, padLength, padString = '0') => {
            while (s.length <= padLength) s += padString;
            return s;
        }
    },
    date: {
        /**
         * 형변환 날짜 > 문
         * @param date typeof date
         * @param format yyyy-mm-dd..
         * @returns {string}
         */
        dateToString: (date, format) => moment(date).format(format.toUpperCase())
    },
    string: {
        /**
         * 좌측 문자열 채우기
         * @param val 채울값
         * @param len 채울 길이
         * @returns {string}
         */
        lPad: (val, len) => {
            const str = val.toString();
            let i = 0;
            let ret = '';
            while (i++ < len - str.length) {
                ret += '0';
            }
            return ret + str;
        },
        /**
         * 형변환 문자 > 날짜
         * @param s
         * @param format
         * @returns {Date}
         */
        stringToDate: (s, format) => moment(s, format).toDate(),
        YYYYMMDDToMoment: (stringValue) => {
            if (stringValue) {
                const date = new Date(stringValue.replace(/^(\d{4})(\d\d)(\d\d)$/, '$1/$2/$3'));

                return moment(date);
            }
            return null;
        },
        YYYYMMDDHHmmssToMoment: (stringValue) => {
            if (stringValue) {
                const date = new Date(
                    stringValue.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, '$4:$5:$6 $2/$3/$1')
                );

                return moment(date);
            }
            return null;
        },
        stringCmpCheck: (stringValue) => {
            if (stringValue) {
                const data = stringValue.replace(/[^a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|0-9|가-힣|ㆍᆢ(),. &/-]/gi, '');
                return data;
            }
        },
        stringHyphenNumCheck: (stringValue) => {
            if (stringValue) {
                const data = stringValue.replace(/[^0-9|-]/gi, '');
                return data;
            }
        },
        stringCarNumCheck: (stringValue) => {
            if (stringValue) {
                const data = stringValue.replace(/[^0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|ㆍᆢ]/gi, '');
                return data;
            }
        },
        stringNumCheck: (stringValue) => {
            if (stringValue) {
                const data = stringValue.replace(/[^0-9]/gi, '');
                return data;
            }
        },
        stringStrCheck: (stringValue) => {
            if (stringValue) {
                const data = stringValue.replace(/[^a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|ㆍᆢ\-)]/gi, '');
                return data;
            }
        },
        stringDefaltCheck: (stringValue) => {
            if (stringValue) {
                const data = stringValue.replace(/[^0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣| ㆍᆢ\-)]/gi, '');
                return data;
            }
        },
        toPhoneFormat: (s) => {
            return s.replace(/^([0-9]{2,3})([0-9]{3,4})([0-9]{4})$/, '$1-$2-$3');
        },
        /**
         * 사업자번호 포멧으로 변환
         */
        toCompanyNumberFormat: (s) => {
            return s.replace(/^([0-9]{3})([0-9]{2})([0-9]{5})$/, '$1-$2-$3');
        }
    },
    validation: {
        isCarNumber(value) {
            if (/^[0-9]{2}[가-힣][0-9]{4}$/.test(value) && value.length === 7) {
                return true;
            } else if (/^[0-9]{3}[가-힣][0-9]{4}$/.test(value) && value.length === 8) {
                return true;
            } else if (/^[가-힣]{2}[0-9]{2}[0-9]{4}$/.test(value) && value.length === 8) {
                return true;
            } else if (/^[가-힣]{2}[0-9]{2}[가-힣]{1}[0-9]{4}$/.test(value) && value.length === 9) {
                return true;
            }
            return false;
        },
        isMobileNumber(value) {
            if (/^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/.test(value)) {
                return true;
            }
            return false;
        },
        isMobileNumberWithHyphen(value) {
            if (/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/.test(value)) {
                return true;
            }
            return false;
        },
        isBusinessNumberWithHyphen(value) {
            if (/^[0-9]{3}-[0-9]{2}-[0-9]{5}$/.text(value) && value.length === 12) {
                return true;
            }

            return false;
        }
    },
    saveSession: (sessionName, seaaionValue) => {
        const val = typeof seaaionValue !== 'string' ? JSON.stringify(seaaionValue) : seaaionValue;
        window.sessionStorage.setItem(sessionName, val);
    },
    loadSession: (sessionName) => {
        const tmp = window.sessionStorage.getItem(sessionName);
        const ret = tmp && tmp.length > 0 ? tmp.toString() : undefined;
        return ret;
    },
    clearSession: (isLogoutReducer = false) => {
        window.sessionStorage.clear();
        if (isLogoutReducer) {
            store.dispatcher(clearStore());
            store.dispatcher(logout());
        }
    },
    setCookie: (name, value, days = 7) => {
        const expires = new Date();
        expires.setDate(expires.getDate() + days);
        document.cookie = `${name}=${e3.base64Encode(value)}; expires=${expires.toUTCString()}; path=/`;
    },
    getCookie: (name) => {
        try {
            const cname = `${name}=`;
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(cname) === 0) {
                    return e3.base64Decode(c.substring(cname.length, c.length));
                }
            }
            return '';
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    }
};
