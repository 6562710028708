import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';
import ListToolbar from 'src/components/listofcard/ListToolbar';
import ListToolbar4Admin from 'src/components/listofcard/ListToolbar4Admin';
import ItemCard from 'src/components/listofcard/ItemCard';
import ItemDialog from 'src/components/listofcard/ItemDialog';
import ISONotification from 'src/framework/components/controls/ISONotification';
import ActionTAEvent from 'src/components/actionevent/ActionTAEvent';
import Loader from 'src/components/loading/Loader';
import { e3, alertType  } from 'src/framework/core/utils/utils';

const CardList = () => {
  const dialog = React.useRef();
  const signinUser = ActionTAEvent.GetUser();
  const [plantId, setPlantId] = React.useState('PLNT100'); //signinUser.PLANT_ID
  const [isLoading, setIsLoading] = React.useState(true); // 파일 저장 및 삭제 동안 이동을 막기 위함
  const [scrollActive, setScrollActive] = React.useState(false);    // Scroll 범례 Fix 관련
  const [legend, setLegend] = React.useState(null);                 // Scroll 범례 Fix 관련
  let elBody = document.querySelector(".DashboardLayoutContent");   // Scroll 범례 Fix 관련

	// Notify 출력 설정
	const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: '' });

  // 조회 결과
  const [equipments, setEquipments] = React.useState(null);

	// dialog 화면 출력
	const onShow = (pitem) => {
		dialog.current.showDialog(pitem);
	};

  /// paramters order :: plantid, taid, facilityknd, processcd, facilitynm
  const SelectSignSheet = async (plantid, taid, facilityknd, processcd, facilitynm) => {
    let result = await ActionTAEvent.SelectSignSheet(plantid, taid, facilityknd, processcd, facilitynm);
    if(result)  {
      setEquipments(result);
      // 범례 출력을 자료 생성
      let oneItem = result[0];
      let list = []; 
      for(var i=0; i<oneItem.ING_STEP; i++) {
        var model = {
          code: oneItem[`STEP${i+1}_CD`],
          name: oneItem[`STEP${i+1}_NM`],
        }
        list.push(model);
      }
      setLegend(list);
    }
    setIsLoading(false);
  }

  const InsertSignSheet = async (plantid, taid, facilityknd, processcd, facilityno, stepcd, wkstatus, rmk) => {
    setIsLoading(true);
    let result = await ActionTAEvent.InsertSignSheet(plantid, taid, facilityknd, processcd, facilityno, stepcd, wkstatus, rmk);
    // console.log('SelectSignSheet :: ', result);
    if(result) {
      SelectSignSheet(plantid, taid, facilityknd, processcd, facilityno);
    } else setIsLoading(false);
  }

  const handleSearch = (pv1, pv2, pv3, pv4, pv5) => {
    if(pv1 && pv2 && pv3) {
      setIsLoading(true);
      SelectSignSheet(pv1, pv2, pv3, pv4, pv5);
    } else {
      setNotify({isOpen: true, message: '[사업장], [TA 구분], [설비종류] 조회 조건을 완성하세요!'});
    }
  }

  // // plant_id, ta_id, facility_knd, process_cd, facility_no, step_cd, wk_status, rmk
  const handleActionClick = (plantid, taid, facilityknd, processcd, facilityno, stepcd, wkstatus, rmk) => {
    // console.log(plantid, taid, facilityknd, processcd, facilityno, stepcd, wkstatus, rmk);
    InsertSignSheet(plantid, taid, facilityknd, processcd, facilityno, stepcd, wkstatus, rmk);
  }

  const handleCancelActionClick = async(plantid, taid, facilityknd, processcd, facilityno, ingStep) => {
    setIsLoading(true);
    try {
      let result = await ActionTAEvent.CacelSignSheet(plantid, taid, facilityknd, processcd, facilityno, ingStep);
      if(result?.ErrorMessage) {
        e3.modal.alert(alertType.Error, result.ErrorMessage);  
      }
      SelectSignSheet(plantid, taid, facilityknd, processcd, facilityno);
    }
    catch (error){
      e3.modal.alert(alertType.Error, error.message);
    }
    setIsLoading(false);
  }

  const DashboardContentLayoutScroll = () => {
    let toolbar = document.querySelector('.search-tool-bar-for-list');
    let style = getComputedStyle(toolbar);
    let height = toolbar.clientHeight;
    height += parseInt(style.marginTop);
    height += parseInt(style.marginBottom);
    if(elBody.scrollTop > height) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  }
  const watchScroll = () => {
    setTimeout(() => {
      if(!elBody) {
        elBody = document.querySelector('.DashboardLayoutContentBox');
      }
      elBody.addEventListener('scroll', DashboardContentLayoutScroll);
    }, 200);
  }
  React.useEffect(() => {
    watchScroll();
    return () => { 
      if(!elBody) {
        elBody = document.querySelector('.DashboardLayoutContentBox');
      }
      elBody.removeEventListener('scroll', DashboardContentLayoutScroll);
    }
  })

  React.useEffect(() => {
    setIsLoading(false);
  }, [equipments])

  return (
    <React.Fragment>
      <Helmet>
        <title>설비 Item 목록</title>
      </Helmet>
      <Box className="DashboardLayoutContentBox">
        <Container maxWidth={false}>
          {signinUser?.PLANT_ID && signinUser?.PLANT_ID !== 'null' ? (
            <ListToolbar handlesearch={handleSearch} plantid={plantId} className="search-tool-bar-for-list" />
          ):(
            <ListToolbar4Admin handlesearch={handleSearch}  className="search-tool-bar-for-list" />
          )}

          <Box className={scrollActive ? "step-horizontal fixed" : "step-horizontal relative"}>
            <ul>
              {legend && legend.map((item, index) => (
                <li key={`legend-${index}`}><em>{index + 1}</em><span className="step-horizontal-code">{item.code}</span> <span className="step-horizontal-name">{item.name}</span></li>  
              ))}
            </ul>
          </Box>
          
          <Box className="paddingTop-10">
            <Grid container spacing={3}>
              {equipments ? ( equipments.map((equip, index) => (
                <Grid item key={`${equip.code}-${index}`} lg={4} md={6} xl={4} xs={12} className="card-list">
                  <ItemCard equipment={equip} handleEdit={onShow} />
                </Grid>
              ))):(
                <Grid item lg={12} md={12} xl={12} xs={12} className="card-list empty">
                  <Card className="CardContentGridItem">
                    <CardContent>
                      <em></em>
                      <Typography color="textPrimary" gutterBottom variant="h3">
                        조회된 자료가 존재하지 않습니다.    
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>
          {/* <Box className="DashboardLayoutContentBoxItem">
            <Pagination color="primary" count={3} size="small"/>
          </Box> */}
        </Container>
      </Box>
      <ItemDialog ref={dialog} jsActionClick={handleActionClick} jsCancelActionClick={handleCancelActionClick} />
			<ISONotification notify={notify} setNotify={setNotify} />
      <Loader isShow={isLoading} />
    </React.Fragment>
  );
}
export default CardList;
