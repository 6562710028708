import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import { Box, Container, Grid, Button, ButtonGroup, Typography } from '@material-ui/core';
import AccountProfile from 'src/components/dashboard/AccountProfile';
import moment from 'moment';
import DataCheckCard from 'src/components/dashboard/DataCheckCard';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import Loader from 'src/components/loading/Loader';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';
import AccessCard_PopUp from './popup/AccessCard_PopUp.js';
import useSessionStorage from '../framework/core/hooks/useSessionStorage';

const Dashboard = () => {
    const { signinUser, stateUser, goToPage } = useUserInfoPublicAccount();
    const { clearSessionStorage } = useSessionStorage('loginSign');
    const navigate = useNavigate();
    const [sajin, setSajin] = React.useState(false);
    let [stepState, setStepState] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [submitCheck, setSubmitCheck] = React.useState(false);
    const [safeworkCheck, setSafeWorkCheck] = React.useState(false); // 사내 출입자 안전수칙준수 서약서(작업자)
    const [safeCarCheck, setSafeCarCheck] = React.useState(false); // 안전준수서약서(수송자)
    const [inOutDate, setInOutDate] = React.useState();
    const [viewflag, setViewFlag] = React.useState(false);
    const [btnCheck, setBtnCheck] = React.useState(false);
    const [itemDisplay, setItemDisplay] = React.useState({
        item1: true,
        item2: false,
        item3: false,
        item4: false,
        item5: false,
        item6: true
    }); // 항목 보이기
    //CR39572 LFC OTS TF 박유적 책임 요청사항
    //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
    const inputRef = React.useRef();

    // 출입증 팝업
    const [accessCardData, setAccessCardData] = React.useState();
    const accessCard_dialog = React.useRef(AccessCard_PopUp);
    const onAccessCardShow = async () => {
        await accessCard_dialog.current.showDialog();
    };
    const openAccessCard = async () => {
        let info = await ActionEvent.getUserData();
        if (info && info.KeyValues[1].Value == '0') {
            e3.modal.alert(alertType.Warning, '방문 승인 후 신청기간 동안 모바일 출입증이 제공 됩니다.');
            return;
        } else {
        }
        setAccessCardData(info);
        onAccessCardShow();
    };

    // 출문 - 상주업체, 상주협력사
    const getInOutDate = async () => {
        let info = await ActionEvent.GetInOutDate('P');
        if (info && info.KeyValues[1].Value == '0') {
            setInOutDate('-');
        } else {
            info = JSON.parse(info.JsonData);
            setInOutDate(`${decodeURI(info[0].IN_OUT_DATETIME)}`);
        }
    };

    const onSubmit = () => {
        let status = null;
        if (
            (signinUser.ADD_TYPE == 'ADD_TYPE_01' || signinUser.ADD_TYPE == 'ADD_TYPE_03') &&
            signinUser.STATUS == 'O'
        ) {
            status = 'A';
            SetVisitRegiste(status);
        } else if (
            (signinUser.ADD_TYPE == 'ADD_TYPE_02' ||
                signinUser.ADD_TYPE == 'ADD_TYPE_04' ||
                signinUser.ADD_TYPE == 'ADD_TYPE_05') &&
            signinUser.STATUS == 'Y'
        ) {
            status = 'C';
            SetVisitRegiste(status);
        }
    };

    const SetVisitRegiste = async (status) => {
        let str = await ActionEvent.SetVisitRegiste(status);
        if (str && str.KeyValues.find((item) => item.Key === 'CODE').Value == '200') {
            if (signinUser.REQ_TYPE == 'REQ_TYPE_001') {
                e3.modal.alert(alertType.Success, '신청이 완료되었습니다.');
            } else {
                e3.modal.alert(
                    alertType.Success,
                    '신청이 완료되었습니다. 최종 승인까지 기다려 주세요. 방문신청 건이 여러 건 일 경우 다음 방문건이 나타나고, 모두 완료하면 현재날짜로부터 가까운 방문건이 보여집니다.'
                );
            }
            setSubmitCheck(false);
        }
        //CR40326 LFC 정보시스템담당 남영진 책임 요청사항
        //출입관리 위반인원 관리 기능 보완
        //위반인원 체크
        else
        {
            if (str?.ErrorMessage && str.ErrorMessage.length > 0)
            {
                if (str && str.KeyValues.find((item) => item.Key === 'CODE').Value == '500')
                {
                    e3.modal.alert(alertType.Warning, result?.ErrorMessage);
                    return;
                }
            }
        } 
        ReLoad();
    };

    const LoadPersonalSajin = async () => {
        await ActionEvent.LoadPersonalSajin();
    };

    const GetPersonalSajin = () => {
        return ActionEvent.GetPersonalSajin();
    };

    const handleGotoPage = (pageStep) => {
        if (
            (signinUser.ADD_TYPE == 'ADD_TYPE_01' && signinUser.STATUS != 'O') || // 직접신청 STATUS = '신청중' -> '신청'
            (signinUser.ADD_TYPE == 'ADD_TYPE_02' && signinUser.STATUS != 'Y') || // 대리신청 STATUS = '완료' -> '승인'
            (signinUser.ADD_TYPE == 'ADD_TYPE_03' && signinUser.STATUS != 'O') || // 외부업체 대리 등록 STATUS = '신청중' -> '신청'
            (signinUser.ADD_TYPE == 'ADD_TYPE_04' && signinUser.STATUS != 'Y') || // 보안실 대리 신청 STATUS = '완료' -> '승인'
            (signinUser.ADD_TYPE == 'ADD_TYPE_05' && signinUser.STATUS != 'Y') // 협력사 출입 등록 STATUS = '완료' -> '승인'
        ) {
            if (
                (userInfo.STATUS != 'C' && userInfo.ADD_TYPE == 'ADD_TYPE_02' && userInfo.STATUS != 'Y') ||
                (userInfo.ADD_TYPE == 'ADD_TYPE_04' && userInfo.STATUS != 'Y') ||
                (userInfo.ADD_TYPE == 'ADD_TYPE_05' && userInfo.STATUS != 'Y')
            ) {
                e3.modal.alert(alertType.Warning, '현재 방문신청 진행을 시작 할 수 있는 상태가 아닙니다.');
            }
            return;
        } else {
            if (pageStep === 1) {
                navigate('/app/visithistoryview', {
                    state: {
                        usePlantId: `${decodeURI(signinUser.PLANT_ID)}`,
                        useEmpNm: `${decodeURI(signinUser.EMP_NM)}`,
                        useEmpTelNo: `${decodeURI(signinUser.EMP_TEL_NO)}`,
                        req: `${decodeURI(signinUser.VISIT_REQ_SEQ)}`,
                        flag: 'D'
                    }
                });
            } // 안전수칙서약서
            else if (pageStep === 2) {
                if (signinUser.VOW_CONSENT_DT != 'null') {
                    e3.modal.alert(
                        alertType.Info,
                        `${decodeURI(signinUser.VOW_CONSENT_DT)}` + '에 서약서에 동의하셨습니다.'
                    );
                } else {
                    if (safeworkCheck == true) {
                        goToPage('/app/saferulepledge'); // 사내출입자 안전수칙준수 서약서(작업자)
                    } else if (safeCarCheck == true) {
                        goToPage('/app/safecomplstate'); // 안전준수서약서(수송자)
                    }
                }
            } // 증명서
            else if (pageStep === 3) {
                goToPage('/app/attachfiles');
            } // 안전교육 학습
            else if (pageStep === 4) {
                goToPage('/app/videoplayer');
            } // 안전교육 평가
            else if (pageStep === 5) {
                if (signinUser.SAFE_EDU_CMPLT_YN === 'Y') {
                    if (signinUser.PASS_YN === 'Y') {
                        e3.modal.alert(
                            alertType.Info,
                            `${decodeURI(signinUser.PASS_DT)}` +
                                '에 이미' +
                                signinUser.PASS_SCORE +
                                '점으로 통과하셨습니다.'
                        );
                    } else {
                        navigate('/app/safequiz', { replace: true });
                    }
                } else {
                    e3.modal.alert(alertType.Error, '안전교육 학습을 하신 후 이용 가능합니다.');
                }
            } // 안면정보 등록
            else if (pageStep === 6) {
                goToPage('/app/account');
            } else goToPage('');
        }
    };

    React.useEffect(() => {
        // 사진 정보를 가져온다.
        LoadPersonalSajin();
        setTimeout(() => {
            setSajin(GetPersonalSajin());
        }, 600);
    }, [sajin]);

    React.useEffect(() => {
        let mounted = true;

        if (mounted) {
            setIsLoading(true);
            //개인정보동의
            if (stateUser.privacyAllow === false) {
                goToPage('/app/settings');
            }
            // 현재날짜에 신청된 히스토리가 없으면
            if (
                signinUser.REQ_TYPE != 'REQ_TYPE_001' &&
                `${decodeURI(signinUser.END_DATE_ORIGIN)}` <
                    moment().format('YYYYMMDDHHmmss')
            ) {
                goToPage('/app/visithistory');
            }
         
            ReLoad();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
        return () => {
            mounted = false;
        };
    }, []);

    const ReLoad = async () => {
        let userInfo = await ActionEvent.SaveReloadUserData();
        if (userInfo == null) {
            clearSessionStorage(true);
        } else {
            userAddItemView(userInfo);
            userAddDataCheck(userInfo);
            getInOutDate();
        }
    };

    const userAddItemView = (userInfo) => {
        if (userInfo.REQ_TYPE == 'REQ_TYPE_001') {
            setSafeWorkCheck(true);
            setItemDisplay({ item1: false, item2: true, item3: false, item4: false, item5: false, item6: true });
        } else if (userInfo.PARTNER_CMP_TYPE == 'Y') {
            setItemDisplay({ item1: true, item2: false, item3: false, item4: true, item5: true, item6: true });
        } else if (userInfo.STATUS == 'Y' && userInfo.ADD_TYPE == 'ADD_TYPE_04') {
            setSafeCarCheck(true);
            setItemDisplay({ item1: true, item2: true, item3: false, item4: true, item5: true, item6: true });
        } else {
            if (userInfo.VISIT_CD == 'VISIT100') {
                setItemDisplay({ item1: true, item2: false, item3: false, item4: true, item5: true, item6: true });
            } else if (userInfo.VISIT_CD == 'VISIT200') {
                setSafeWorkCheck(true);
                setItemDisplay({ item1: true, item2: true, item3: true, item4: true, item5: true, item6: true });
            } else if (userInfo.VISIT_CD == 'VISIT300' || userInfo.VISIT_CD == 'VISIT400') {
                setSafeCarCheck(true);
                if (userInfo.VISIT_PURPOSE == 'VISIT300_040') {
                    // 유해화학물질
                    setItemDisplay({ item1: true, item2: true, item3: true, item4: true, item5: true, item6: true });
                } else {
                    setItemDisplay({ item1: true, item2: true, item3: false, item4: true, item5: true, item6: true });
                }
            }
        }
    };

    const userAddDataCheck = (userInfo) => {
        if (
            (userInfo.ADD_TYPE == 'ADD_TYPE_01' && userInfo.STATUS != 'O') ||
            (userInfo.ADD_TYPE == 'ADD_TYPE_02' && userInfo.STATUS != 'Y') ||
            (userInfo.ADD_TYPE == 'ADD_TYPE_03' && userInfo.STATUS != 'O') ||
            (userInfo.ADD_TYPE == 'ADD_TYPE_04' && userInfo.STATUS != 'Y') ||
            (userInfo.ADD_TYPE == 'ADD_TYPE_05' && userInfo.STATUS != 'Y')
        ) {
            if (
                (userInfo.ADD_TYPE == 'ADD_TYPE_01' && userInfo.STATUS != 'O') ||
                (userInfo.ADD_TYPE == 'ADD_TYPE_02' && userInfo.STATUS == 'C') ||
                (userInfo.ADD_TYPE == 'ADD_TYPE_03' && userInfo.STATUS != 'O') ||
                (userInfo.ADD_TYPE == 'ADD_TYPE_04' && userInfo.STATUS == 'C') ||
                (userInfo.ADD_TYPE == 'ADD_TYPE_05' && userInfo.STATUS == 'C')
            ) {
                setBtnCheck(true);
            }
            setSubmitCheck(false);
        } else {
            if (
                stateUser &&
                stateUser.privacyAllow === true &&
                !(userInfo.EMP_NM == '' && userInfo.EMP_TEL_NO === '') &&
                userInfo.SAJIN === 'Y'
            ) {
                if (userInfo.REQ_TYPE == 'REQ_TYPE_001') {
                    if (userInfo.VOW_CONSENT_DT != 'null') {
                        setSubmitCheck(true);
                        //CR39572 LFC OTS TF 박유적 책임 요청사항
                        //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                        inputRef.current.focus();
                    } else {
                        setSubmitCheck(false);
                    }
                } else if (userInfo.PARTNER_CMP_TYPE == 'Y') {
                    if (userInfo.SAFE_EDU_CMPLT_YN === 'Y' && userInfo.PASS_YN === 'Y') {
                        setSubmitCheck(true);
                        //CR39572 LFC OTS TF 박유적 책임 요청사항
                        //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                        inputRef.current.focus();
                    } else {
                        setSubmitCheck(false);
                    }
                } else if (userInfo.STATUS == 'O' && userInfo.ADD_TYPE == 'ADD_TYPE_04') {
                    if (
                        userInfo.VOW_CONSENT_DT != 'null' &&
                        userInfo.SAFE_EDU_CMPLT_YN === 'Y' &&
                        userInfo.PASS_YN === 'Y'
                    ) {
                        setSubmitCheck(true);
                        //CR39572 LFC OTS TF 박유적 책임 요청사항
                        //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                        inputRef.current.focus();
                    } else {
                        setSubmitCheck(false);
                    }
                } else {
                    if (userInfo.VISIT_CD == 'VISIT100') {
                        if (userInfo.SAFE_EDU_CMPLT_YN === 'Y' && userInfo.PASS_YN === 'Y') {
                            setSubmitCheck(true);
                            //CR39572 LFC OTS TF 박유적 책임 요청사항
                            //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                            inputRef.current.focus();
                        } else {
                            setSubmitCheck(false);
                        }
                    } else if (userInfo.VISIT_CD == 'VISIT200') {
                        if (
                            userInfo.VOW_CONSENT_DT != 'null' &&
                            userInfo.FILE_COUNT > 0 &&
                            userInfo.CHECK_DT != 'null' &&
                            Number(userInfo.BP_HIGHT) != 0 &&
                            Number(userInfo.BP_LOW) != 0 &&
                            userInfo.SAFE_EDU_CMPLT_YN === 'Y' &&
                            userInfo.PASS_YN === 'Y'
                        ) {
                            setSubmitCheck(true);
                            //CR39572 LFC OTS TF 박유적 책임 요청사항
                            //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                            inputRef.current.focus();
                        } else {
                            setSubmitCheck(false);
                        }
                    } else if (userInfo.VISIT_CD == 'VISIT300' || userInfo.VISIT_CD == 'VISIT400') {
                        if (userInfo.VISIT_PURPOSE == 'VISIT300_040') {
                            if (
                                userInfo.VOW_CONSENT_DT != 'null' &&
                                userInfo.FILE_COUNT > 0 &&
                                userInfo.CHECK_DT != 'null' &&
                                userInfo.SAFE_EDU_CMPLT_YN === 'Y' &&
                                userInfo.PASS_YN === 'Y'
                            ) {
                                setSubmitCheck(true);
                                //CR39572 LFC OTS TF 박유적 책임 요청사항
                                //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                                inputRef.current.focus();
                            } else {
                                setSubmitCheck(false);
                            }
                        } else {
                            if (
                                userInfo.VOW_CONSENT_DT != 'null' &&
                                userInfo.SAFE_EDU_CMPLT_YN === 'Y' &&
                                userInfo.PASS_YN === 'Y'
                            ) {
                                setSubmitCheck(true);
                                //CR39572 LFC OTS TF 박유적 책임 요청사항
                                //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                                inputRef.current.focus();
                            } else {
                                setSubmitCheck(false);
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        {signinUser.REQ_TYPE == 'REQ_TYPE_001' ? '출입신청 정보' : '방문신청 정보'}
                    </Typography>
                </Box>
                <Container maxWidth={false}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                            <AccountProfile className="user-info" />
                        </Grid>
                        <Box className="tip">
                            <h6>안내 사항</h6>
                            <a
                                onClick={() => {
                                    setIsLoading(true);
                                    ReLoad();
                                    setTimeout(() => {
                                        setIsLoading(false);
                                    }, 300);
                                }}
                                style={{ float: 'right' }}
                            >
                                <img src="./static/images/t_refresh_on.png"></img> 새로고침
                            </a>
                            <ul>
                                <li>
                                    아래 미완료된 항목들은 차례대로 진행하시고 모두 <br />
                                    완료되면 "출입절차 완료하기" 버튼을 눌러 주세요.
                                </li>
                                <li>
                                    완료된 절차들은 다시 선택하면 정보 확인이 <br />
                                    가능합니다.
                                </li>
                                <li>
                                    "출입절차 완료하기" 버튼을 누른 이후 각 절차에 대한 정보확인은 제공하지 않습니다.
                                </li>
                            </ul>
                        </Box>
                        {itemDisplay.item1 == true ? (
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className={
                                    !(signinUser.EMP_NM == '' && signinUser.EMP_TEL_NO === '')
                                        ? 'check-info active'
                                        : 'check-info'
                                }
                            >
                                <DataCheckCard
                                    title="방문정보 등록"
                                    basset={1}
                                    step={stepState}
                                    check={!(signinUser.EMP_NM == '' && signinUser.EMP_TEL_NO === '') ? true : false}
                                    handleGotoPage={handleGotoPage}
                                />
                            </Grid>
                        ) : null}
                        {itemDisplay.item2 == true ? (
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className={signinUser.VOW_CONSENT_DT != 'null' ? 'check-info active' : 'check-info'}
                            >
                                <DataCheckCard
                                    title="안전수칙서약서 제출"
                                    basset={2}
                                    step={stepState}
                                    check={signinUser.VOW_CONSENT_DT != 'null' ? true : false}
                                    handleGotoPage={handleGotoPage}
                                />
                            </Grid>
                        ) : null}
                        {itemDisplay.item3 == true ? (
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className={
                                    signinUser.VISIT_CD == 'VISIT200' &&
                                    signinUser.FILE_COUNT > 0 &&
                                    signinUser.CHECK_DT != 'null' &&
                                    Number(signinUser.BP_HIGHT) > 0 &&
                                    Number(signinUser.BP_LOW) > 0
                                        ? 'check-info active'
                                        : signinUser.VISIT_PURPOSE == 'VISIT300_040' &&
                                          signinUser.FILE_COUNT > 0 &&
                                          signinUser.CHECK_DT != 'null'
                                        ? 'check-info active'
                                        : 'check-info'
                                }
                            >
                                <DataCheckCard
                                    title="증명서 제출"
                                    basset={3}
                                    step={stepState}
                                    check={
                                        signinUser.VISIT_CD == 'VISIT200' &&
                                        signinUser.FILE_COUNT > 0 &&
                                        signinUser.CHECK_DT != 'null' &&
                                        Number(signinUser.BP_HIGHT) > 0 &&
                                        Number(signinUser.BP_LOW) > 0
                                            ? true
                                            : signinUser.VISIT_PURPOSE == 'VISIT300_040' &&
                                              signinUser.FILE_COUNT > 0 &&
                                              signinUser.CHECK_DT != 'null'
                                            ? true
                                            : false
                                    }
                                    handleGotoPage={handleGotoPage}
                                />
                            </Grid>
                        ) : null}
                        {itemDisplay.item4 == true ? (
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className={signinUser.SAFE_EDU_CMPLT_YN === 'Y' ? 'check-info active' : 'check-info'}
                            >
                                <DataCheckCard
                                    title="안전교육 학습"
                                    basset={4}
                                    step={stepState}
                                    check={signinUser.SAFE_EDU_CMPLT_YN === 'Y' ? true : false}
                                    handleGotoPage={handleGotoPage}
                                />
                            </Grid>
                        ) : null}
                        {itemDisplay.item5 == true ? (
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className={signinUser.PASS_YN === 'Y' ? 'check-info active' : 'check-info'}
                            >
                                <DataCheckCard
                                    title="안전교육 평가"
                                    basset={5}
                                    step={stepState}
                                    check={signinUser.PASS_YN === 'Y' ? true : false}
                                    handleGotoPage={handleGotoPage}
                                />
                            </Grid>
                        ) : null}
                        {itemDisplay.item6 == true ? (
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                                className={signinUser.SAJIN === 'Y' ? 'check-info active' : 'check-info'}
                            >
                                <DataCheckCard
                                    title="안면정보 등록"
                                    basset={6}
                                    step={stepState}
                                    check={signinUser.SAJIN === 'Y' ? true : false}
                                    handleGotoPage={handleGotoPage}
                                />
                            </Grid>
                        ) : null}
                        <Grid container spacing={2} className="btn_wrap">
                            {submitCheck == true ? (
                                <Grid item xs={12}>
                                    <Button
                                        className="MuiButton-containedPrimary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={false}
                                        onClick={onSubmit}
                                        //CR39572 LFC OTS TF 박유적 책임 요청사항
                                        //방문 신청 절차 완료 시 출입절차 완료하기 버튼으로 포커스 이동 처리
                                        ref={inputRef}
                                    >
                                        출입절차 완료하기
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Button
                                        className="MuiButton-containedTertiary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={true}
                                    >
                                        {btnCheck == true ? '출입절차 완료' : '출입절차 완료하기'}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <hr />
                    {signinUser && signinUser.REQ_TYPE == 'REQ_TYPE_001' ? (
                        <Grid container className="banner">
                            <Grid>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={openAccessCard}
                                >
                                    출입증
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    disabled={viewflag}
                                    variant="contained"
                                    className="door"
                                    onClick={(e) => {
                                        e3.modal.custom(
                                            String(moment().format('YYYY-MM-DD HH:mm')),
                                            '본인 핸드폰인 경우만 체크하셔야 정상적으로 출문 처리가 됩니다.',
                                            async () => {
                                                if (signinUser.STATUS != 'C') {
                                                    e3.modal.alert(
                                                        alertType.Warning,
                                                        '출입절차 완료하기 후 출문 하실수 있습니다.'
                                                    );
                                                } else {
                                                    let info = await ActionEvent.SetInOutDate('P');
                                                    if (
                                                        info &&
                                                        info.KeyValues.find((item) => item.Key === 'CODE').Value ==
                                                            '200'
                                                    ) {
                                                        getInOutDate();
                                                        e3.modal.alert(alertType.Info, '출문 처리가 완료되었습니다.');
                                                    }
                                                }
                                            }
                                        );
                                    }}
                                >
                                    출문체크
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container className="banner">
                            <Grid item xs={12}>
                                <ButtonGroup>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={openAccessCard}
                                    >
                                        출입증
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            </Box>
            <AccessCard_PopUp ref={accessCard_dialog} item={accessCardData} />
            <Loader isShow={isLoading} />
        </React.Fragment>
    );
};

export default Dashboard;
