import React from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import flip_camera from './cameraflip.png';
import { Grid, Button, ButtonGroup, Typography, Container, Box } from '@material-ui/core';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import {
    ISODialog,
    ISODialogTitle,
    ISODialogContent,
    ISODialogActions
} from 'src/framework/components/controls/ISODialog';
import ActionEvent from '../actionevent/ActionEvent';

const percentOfHeight = 80;

class CameraPopup extends React.Component {
    constructor() {
        super();
        this.state = {
            isMobile: this.mobileCheck(),
            dataUri: '',
            isCameraErr: false,
            selfie: true,
            cameraStarted: false,
            cameraWidth: 0,
            cameraHeight: 0,
            isOpen: false
        };
        window.addEventListener('resize', this.windowResize, false);
    }

    componentDidMount() {
        this.setState({
            cameraWidth: (window.innerWidth / 100) * percentOfHeight,
            cameraHeight: (window.innerHeight / 100) * percentOfHeight
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
        this.handleCameraStop();
    }

    // Check if mobile
    mobileCheck = () => {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                ) ||
                a.match(/iPhone|iPad|iPod/i)
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    // Change camera mode (front or back)
    changeFacingMode = () => {
        if (this.state.selfie) {
            this.setState({ selfie: false });
        } else {
            this.setState({ selfie: true });
        }
    };

    // Cancel photo captured
    cancelCapture = () => {
        this.setState({
            dataUri: ''
        });
    };

    saveCapture = () => {
        this.handleSendImg();
    };

    handleSendImg = async function () {
        this.props.setIsLoading(true);
        //await ActionEvent.SetUserSajin(this.state.dataUri);

        let message = '';
        let resultCode = '';
        try {
            //const response = await ISOAxios.Execute(params);
            const response = await ActionEvent.SetUserSajin(this.state.dataUri);
            resultCode = response.KeyValues.find((item) => item.Key === 'CODE').Value;
            message = response.KeyValues.find((item) => item.Key === 'Message').Value;

            if (parseInt(resultCode) === 200) {
                ActionEvent.SaveSessionPersonalSajin(this.state.dataUri);
                await ActionEvent.Save6ReloadUserData();
                this.props.setImage(this.state.dataUri);
            }
        } catch (e) {
            message = e.message;
            resultCode = '-500';
        } finally {
            this.props.setIsLoading(false);
            this.props.messageBox.current.showDialog(message);
            if (parseInt(resultCode) === 200) {
                setTimeout(() => {
                    this.onClose();
                }, 500);
            } else {
                this.cancelCapture();
            }
        }
    };

    // Take photo
    handleTakePhoto = (dataUri) => {
        setTimeout(() => this.setState({ dataUri: dataUri }), 700);
    };

    // Handle camera start
    handleCameraStart = () => {
        if (!this.state.cameraStarted)
            this.setState({
                cameraStarted: true
            });
    };

    handleCameraStop = () => {
        if (this.state.cameraStarted)
            this.setState({
                cameraStarted: false
            });
    };

    // Handle camera error
    handleCameraError = (error) => {
        this.setState({ isCameraErr: true });
    };

    // Window resize event listener
    windowResize = () => {
        this.setState({
            cameraWidth: (window.innerWidth / 100) * percentOfHeight,
            cameraHeight: (window.innerHeight / 100) * percentOfHeight
        });
    };

    onClose = () => {
        this.setState({
            isOpen: false
        });
    };

    showDialog = () => {
        this.setState({
            isOpen: true
        });
    };

    render() {
        const { isMobile, cameraStarted, cameraWidth, cameraHeight } = this.state;
        const sizeOfCanvas = {
            height: cameraHeight,
            width: cameraWidth
        };

        return (
            <React.Fragment>
                <ISODialog
                    isOpen={this.state.isOpen}
                    isReasize={false}
                    onClose={this.onClose}
                    dialogId="Camera_Dialog"
                    isDraggable={false}
                    fullScreen
                    fullWidth={true}
                >
                    <ISODialogTitle onClose={this.onClose} id="Camera_Dialog">
                        사진 촬영
                    </ISODialogTitle>
                    <ISODialogContent>
                        <Container maxWidth="lg" className={'dialog-mobile-full-content-body'}>
                            <PerfectScrollbar>
                                <Grid
                                    container
                                    alignContent="center"
                                    alignItems={'center'}
                                    justify="center"
                                    style={{ justifyContent: 'center' }}
                                >
                                    <Grid item xl={12} md={12} sm={12} xs={12}>
                                        <div className="orientation_state_box">
                                            {!this.state.dataUri ? (
                                                <div className="orientation_state" style={{ height: '300px' }}>
                                                    <div className="camera-container cameraActive">
                                                        <Camera
                                                            onTakePhoto={(dataUri) => {
                                                                this.handleTakePhoto(dataUri);
                                                            }}
                                                            onCameraStart={this.handleCameraStart}
                                                            onCameraError={(error) => {
                                                                this.handleCameraError(error);
                                                            }}
                                                            isFullscreen={false}
                                                            isMaxResolution={true}
                                                            sizeFactor={1}
                                                            imageType="jpg"
                                                            idealResolution={{ width: 1024, height: 768 }}
                                                            idealFacingMode={
                                                                this.state.selfie
                                                                    ? FACING_MODES.USER
                                                                    : FACING_MODES.ENVIRONMENT
                                                            }
                                                        />
                                                        {!this.state.isCameraErr && cameraStarted && (
                                                            <React.Fragment>
                                                                <div className="camera-circle"></div>
                                                                <Box className="note">
                                                                    <ul>
                                                                        <li>휴대폰으로 촬영한 사진만 등록 가능</li>
                                                                        <li>얼굴이 사진의 80% 이상 되도록 촬영</li>
                                                                        <li>모자, 마스크, 악세사리, 선글라스 제거</li>
                                                                    </ul>
                                                                    {isMobile && (
                                                                        <img
                                                                            className="switch_cam"
                                                                            src={flip_camera}
                                                                            onClick={this.changeFacingMode}
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div style={{ height: 'calc(100vh - 400px)', overflow: 'hidden' }}>
                                                        <img
                                                            style={{
                                                                height: '100%',
                                                                width: '100%',
                                                                objectFit: 'cover'
                                                            }}
                                                            src={this.state.dataUri}
                                                        />
                                                    </div>
                                                    <ButtonGroup
                                                        disableElevation
                                                        fullWidth
                                                        variant="contained"
                                                        size="large"
                                                    >
                                                        <Button color="primary" onClick={this.cancelCapture}>
                                                            재촬영{' '}
                                                        </Button>
                                                        <Button onClick={this.saveCapture}> 사진 전송 </Button>
                                                        <Button onClick={this.onClose}> 닫기 </Button>
                                                    </ButtonGroup>
                                                </>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </PerfectScrollbar>
                        </Container>
                    </ISODialogContent>
                </ISODialog>
            </React.Fragment>
        );
    }
}

export default CameraPopup;
