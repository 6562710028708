import React from 'react';
import {
    Grid,
    Button,
    Container,
    Typography,
    ButtonGroup,
    // Box,
    // Paper,
} from '@material-ui/core';
import { ISODialog, ISODialogTitle, ISODialogContent, ISODialogActions } from 'src/framework/components/controls/ISODialog';
import ISOTextField from 'src/framework/components/controls/ISOTextField';
import ItemJobCard from './ItemJobCard';
import ActionTAEvent from '../actionevent/ActionTAEvent';
import { e3, alertType  } from 'src/framework/core/utils/utils';

const ItemDialog = React.forwardRef((props, ref) => {
    // Dialog 출력 정보 설정
    const [isOpen, setIsOpen] = React.useState(false);
    const [item, setIem] = React.useState({
      plantId: '',
      taid: '',
      code: '',
      name: '',
      process: '',
      equipment: '',
      equipmentCode: '',
      stepLength: 0,
      stepList: [],
      currentIndex: 0,
      currentStep: '',
      currentStepState: '',
      nextStep : {
        index: -1,
        step:  ''
      },
    });
    const { jsActionClick, jsCancelActionClick } = props;
    const signinUser = ActionTAEvent.GetUser();
    
    const onClose = () => {
      setIsOpen(false);		// Dialog 닫기
    };
    
    const handleOnClick = event => {
      if (item?.plantId && item.taid && item.equipmentCode && item.process && item.code && item.stepList[item.currentIndex].code ) {
        jsActionClick(item.plantId, item.taid, item.equipmentCode, item.process, item.code, item.stepList[item.currentIndex].code, 'WY', ''); 	// Callback 호출
      }
      onClose();
    }

    const handleOnCancelClick = event => {
      e3.modal.confirm('확인', '현재 단계를 취소하시겠습니까?', onCancel); 
    }

    const onCancel = async () => {
      let ingStep = item.currentIndex + 1;
      ingStep = ingStep < 1 ? 1 : ingStep;
      if (parseInt(ingStep) <= 1)
      {
        e3.modal.alert(alertType.Error, "취소할 단계가 없습니다.");            
      }
      else if (item?.plantId && item.taid && item.equipmentCode && item.process && item.code && ingStep ) {
        jsCancelActionClick(item.plantId, item.taid, item.equipmentCode, item.process, item.code, ingStep);
        onClose();
      }
    };

    React.useEffect(() => {
      //
    }, [item]);
    
    // Ref Event 상위로 전달 설정
    React.useImperativeHandle(
      ref,
      () => ({
        showDialog(pitem) {
          setIem(pitem);
          setIsOpen(true);
        },
      }),
    );

    return (
      <React.Fragment>
        <ISODialog isOpen={isOpen} isReasize={false} isDraggable={false} onClose={onClose} dialogId="Item_Edit_Dialog" fullScreen fullWidth={true}>
          <ISODialogTitle onClose={onClose} id="Item_Edit_Dialog">
            [{item.code}] {item.name}  (<span>{item.process} / {item.equipment}</span>)
          </ISODialogTitle>
          <ISODialogContent >
            <Container className={"dialog-mobile-full-content-body"}>
              <Grid container className={"step-vertical"}>
                {item.stepList.map((job, index) => (
                  <Grid item md={12} sm={12} xl={12} xs={12} key={`jobobjec-${index}`} className={item.currentIndex===index ? "active" : (item.currentIndex-1===index) ? "previous" : ""}>
                    <ItemJobCard index={index} job={job} />
                  </Grid>
                ))}
              </Grid>

              <Grid container className="padding-1 step-info">
                <Grid item md={4} xs={4}>
                  <Typography color="textPrimary" variant="h3" align="center">
                    작업단계
                  </Typography>
                </Grid>
                <Grid item md={8} xs={8}>
                  <Typography color="textPrimary" variant="h3" align="center">
                    {item?.stepList[item.currentIndex]?.name}
                  </Typography>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Typography color="textPrimary" variant="h3" align="center">
                  담당자
                  </Typography>
                </Grid>
                <Grid item md={8} xs={8}>
                  <Typography color="textPrimary" variant="h3" align="center">
                    {decodeURI(signinUser.USER_NAME)}
                  </Typography>
                </Grid>
              </Grid>

              <div className="delete-confirmation-button">
                <ButtonGroup disableElevation fullWidth variant="contained" size="large" color="primary">
                <Button color="primary" className="major-button" fullWidth variant="contained" onClick={handleOnClick} disabled={item?.stepList[item.currentIndex]?.auth === 'Y' ? false : true} >
                    현단계 완료
                  </Button>
                  <Button color="primary" fullWidth variant="contained" onClick={handleOnCancelClick}>
                    이전단계 취소
                  </Button>
                </ButtonGroup>
              </div>
            </Container>
          </ISODialogContent>
          {/* <ISODialogActions buttonAlign={props.buttonAlign}>
            <Button type="button" size="small" onClick={onClose}> 확인 </Button>
          </ISODialogActions> */}
        </ISODialog>
      </React.Fragment>
    );
  });

  export default ItemDialog;