import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { e3, alertType } from 'src/framework/core/utils/utils';
import { Box, Container, Card, CardContent, CardHeader, CardActions, Divider, Grid, Button } from '@material-ui/core';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import ActionEvent from 'src/components/actionevent/ActionEvent';

export default function CameraCheck(props) {
    const navigate = useNavigate();
    const [image, setImage] = React.useState({
        source: ''
    });

    function handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto - handleTakePhoto', dataUri);
        setTimeout(() => {
            ActionEvent.SaveSessionPersonalSajin(dataUri);
            setImage({ source: dataUri });
        }, 700);
    }

    function handleTakePhotoAnimationDone(dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto - handleTakePhotoAnimationDone', dataUri);
    }

    function handleCameraError(error) {
        console.log('handleCameraError', error);
    }

    function handleCameraStart(stream) {
        console.log('handleCameraStart', stream);
    }

    function handleCameraStop() {
        console.log('handleCameraStop');
    }

    const handleSendImg = async function () {
        let params = DataPackage.create('MAPI100', 'toImage');
        params.KeyValues.push(DataPackage.NewKeyValue('img', image.source));
        // params.JsonData = image.source;
        try {
            const response = await ISOAxios.Execute(params);
            console.log('handleSendImg', response);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };

    const handleCancle = () => {
        setImage({ source: '' });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Camera (사진 촬영)</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Container maxWidth={false}>
                    <Card>
                        <CardHeader title="사진 촬영" />
                        <Divider />
                        <CardContent>
                            {!image.source ? (
                                <div className="cameraActive">
                                    <Camera
                                        onTakePhoto={(dataUri) => {
                                            handleTakePhoto(dataUri);
                                        }}
                                        onTakePhotoAnimationDone={(dataUri) => {
                                            handleTakePhotoAnimationDone(dataUri);
                                        }}
                                        onCameraError={(error) => {
                                            handleCameraError(error);
                                        }}
                                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        idealResolution={{ width: 1024, height: 768 }}
                                        imageType={IMAGE_TYPES.JPG}
                                        imageCompression={0.97}
                                        isMaxResolution={true}
                                        isImageMirror={false}
                                        isSilentMode={false}
                                        isDisplayStartCameraError={true}
                                        isFullscreen={false}
                                        sizeFactor={1}
                                        onCameraStart={(stream) => {
                                            handleCameraStart(stream);
                                        }}
                                        onCameraStop={() => {
                                            handleCameraStop();
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="imageActive">
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} xs={6}>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                variant="contained"
                                                onClick={handleSendImg}
                                            >
                                                전송
                                            </Button>
                                        </Grid>
                                        <Grid item lg={6} xs={6}>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                variant="contained"
                                                onClick={handleCancle}
                                            >
                                                재촬영
                                            </Button>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <img id="picture" src={image.source} className="facePhoto" />
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </React.Fragment>
    );
}
