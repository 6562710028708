import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardContent, Box, Container, Grid, Button, Typography } from '@material-ui/core';
import QuizCard from 'src/components/listofcard/QuizCard';
import QuizCardStep from 'src/components/listofcard/QuizCardStep';
import QuizCardResut from 'src/components/listofcard/QuizCardResut';
import ISONotification from 'src/framework/components/controls/ISONotification';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import Loader from 'src/components/loading/Loader';
import { useNavigate } from 'react-router-dom';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';
import { Navigate } from 'react-router';

const quizInit = [
    {
        id: 1,
        question: '데이타 로딩 중입니다. 잠시만 기다려 주세요!',
        a: '읽어오고 있습니다.',
        b: '읽어오고 있습니다..',
        c: '읽어오고 있습니다..',
        d: '읽어오고 있습니다..',
        sol: 'a',
        sel: '',
        point: 20
    }
];

let PASS_LIMIT_POINT = 30;
let EDU_MTRL_ID = -1;
let EDU_MTRL_NM = '';

const QuizList = () => {
    const { signinUser, getReloadUserData, goToPage } = useUserInfoPublicAccount();
    const navigate = useNavigate();
    const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: '' });
    let list = [];
    const [quizList, setQuizList] = React.useState(quizInit);
    quizInit.forEach((item, index) => {
        let m = { index: index, num: item.id, value: 0, sel: item.sel, isSol: false };
        list.push(m);
    });
    const [sumValue, setSumValue] = React.useState(list);
    const [display, setDisplay] = React.useState('Step'); // List, Step, Result
    const [oderIndex, setOrderIndex] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true); // 문제 로딩

    // 문제 정보를 가져온다.
    const loadQuiz = async () => {
        let eduInfo = await ActionEvent.GetSafeSubject();
        PASS_LIMIT_POINT = eduInfo[0].PASS_STD_SCORE;
        EDU_MTRL_NM = eduInfo[0].EDU_MTRL_NM;
        EDU_MTRL_ID = eduInfo[0].EDU_MTRL_ID;

        let qList = await ActionEvent.GetExamSentence();
        setQuizList(qList);
        list = [];
        qList.forEach((item, index) => {
            let m = { index: index, num: item.id, value: 0, sel: item.sel, isSol: false };
            list.push(m);
        });
        setSumValue(list);
        setIsLoading(false);
    };
    // 문제 결과를 저장한다.
    const SaveQuizResult = async (passYn, passScore) => {
        let ret = await ActionEvent.SetExamSentenceResult(signinUser, passYn, passScore);
    };
    // 단계별 결과를 기록한다.
    const calculator = (id, v, index, sel) => {
        setTimeout(() => {
            sumValue.forEach((item) => {
                if (item.num === id) {
                    item.value = v;
                    item.sel = sel;
                    item.isSol = true;
                }
            });
            if (display === 'Step') setOrderIndex(index + 1);
        }, 100);
    };

    // 시험 결과 집계 (LIST 제출 버튼)
    const handleClick = (event) => {
        const totalPoint = sumValue.reduce((total, item) => {
            return total + parseInt(item.value);
        }, 0);

        // 시험 결과 판정 및 저장
        if (totalPoint >= PASS_LIMIT_POINT) {
            SaveQuizResult('Y', totalPoint);
        } else {
            SaveQuizResult('N', totalPoint);
        }

        // 결과 출력
        setTimeout(() => {
            setNotify({ isOpen: true, message: `[${totalPoint}]점 입니다.`, type: alertType.Info });
            setDisplay('Result');
        }, 100);
    };

    const handleGotoPage = async () => {
        await getReloadUserData();
        setTimeout(() => {
            goToPage('/app/dashboard');
        }, 300);
    };

    const handleGotoPage2 = async () => {
        await getReloadUserData();
        setTimeout(() => {
            goToPage('/app/videoplayer');
        }, 300);
    };

    React.useEffect(() => {
        loadQuiz();
        window.onhashchange = function () {
            navigate('/app/dashboard', { replace: true });
        };
    }, []);

    const FindPointValue = (id) => {
        let obj = sumValue.find((v) => v.num === id);
        return obj.value;
    };

    const ResultControl = ({ totalPoint }) => {
        return (
            <React.Fragment>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Box className="padding-1 result-wrap">
                        <Typography color="textPrimary" variant="h1" align="center">
                            {totalPoint >= PASS_LIMIT_POINT ? (
                                <div className="QuizResultSuccess">
                                    <Typography color="textPrimary" variant="h3" align="center">
                                        <span className="QuizResultPoint">{totalPoint}</span>점
                                    </Typography>
                                </div>
                            ) : (
                                <div className="QuizResultFail">
                                    <Typography color="textPrimary" variant="h3" align="center">
                                        <span className="QuizResultPoint">{totalPoint}</span>점
                                    </Typography>
                                </div>
                            )}
                        </Typography>
                        <Typography color="textPrimary" variant="h4" align="center">
                            {totalPoint >= PASS_LIMIT_POINT ? (
                                <span className="QuizResultSuccessMessage">
                                    <strong>통과하셨습니다.</strong>
                                </span>
                            ) : (
                                <span className="QuizResultFailMessage">
                                    <strong>통과하지 못했습니다.</strong>
                                    <br />
                                    재학습 후 안전교육 평가해 주세요.
                                </span>
                            )}
                        </Typography>
                        <Typography color="textPrimary" align="center" className="btn_wrap">
                            {totalPoint >= PASS_LIMIT_POINT ? (
                                <Button
                                    fullWidth
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={handleGotoPage}
                                >
                                    {' '}
                                    방문신청 정보화면으로 이동{' '}
                                </Button>
                            ) : (
                                <Button
                                    fullWidth
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={handleGotoPage2}
                                >
                                    {' '}
                                    안전교육 재학습{' '}
                                </Button>
                            )}
                        </Typography>
                    </Box>
                </Grid>
                <hr />
                <h3>평가 결과</h3>
                {quizList.map((item, index) => (
                    <Grid item key={item.id} xs={12}>
                        <QuizCardResut
                            quiz={item}
                            index={index}
                            sel={sumValue.map((value) => {
                                if (item.id == value.num) {
                                    return value.sel;
                                }
                            })}
                            point={FindPointValue(item.id)}
                        />
                    </Grid>
                ))}
            </React.Fragment>
        );
    };

    const ListControl = () => {
        return (
            <React.Fragment>
                {quizList.map((item, index) => (
                    <Grid item key={item.id} lg={6} md={12} xl={6} xs={12}>
                        <QuizCard quiz={item} index={index} calculator={calculator} />
                    </Grid>
                ))}
            </React.Fragment>
        );
    };

    const StepControl = () => {
        let totalPoint = 0;
        let value = {};
        let _quiz = {};
        value = sumValue.find((v) => v.index === oderIndex);
        _quiz = value !== undefined && quizList.find((item) => item.id === value.num);
        if (value === undefined) {
            // 점수 합 확인
            // totalPoint = sumValue.reduce((total, item) => {
            //     return total + parseInt(item.value);
            // }, 0);
            let okCount = sumValue.filter((item) => item.value > 0).length;
            let calValue = sumValue.length == 0 ? 100 : (okCount / sumValue.length) * 100;
            totalPoint = parseInt(calValue);
            // 시험 결과 판정 및 저장
            if (totalPoint >= PASS_LIMIT_POINT) {
                SaveQuizResult('Y', totalPoint);
            } else {
                SaveQuizResult('N', totalPoint);
            }
        }

        return (
            <React.Fragment>
                {value !== undefined ? (
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                        <QuizCardStep quiz={_quiz} index={oderIndex} calculator={calculator} />
                    </Grid>
                ) : (
                    <ResultControl totalPoint={totalPoint} />
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{EDU_MTRL_NM} 평가</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        안전교육 평가
                    </Typography>
                </Box>
                <Container maxWidth={false}>
                    <Card className="MuiPaper-common quiz">
                        <CardContent className="cardcontent-common">
                            <Box className="padding-1">
                                <Grid container spacing={2}>
                                    {display === 'Step' ? (
                                        <StepControl />
                                    ) : display === 'Result' ? (
                                        <ResultControl
                                            totalPoint={sumValue.reduce((total, item) => {
                                                return total + parseInt(item.value);
                                            }, 0)}
                                        />
                                    ) : (
                                        <ListControl />
                                    )}
                                </Grid>
                            </Box>
                            {display === 'List' && (
                                <React.Fragment>
                                    {/*<Divider />*/}
                                    <Box className="CardContentGridItemEnd btn_wrap">
                                        <Button
                                            fullWidth
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            className="MuiButton-containedSizeLarge"
                                            onClick={handleClick}
                                        >
                                            답안 제출
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}
                        </CardContent>
                    </Card>
                    <hr />
                    <Box className="note">
                        <p>
                            {quizList ? quizList.length : 0}문제, 평균 {PASS_LIMIT_POINT}점 이상 출입 가능합니다.
                        </p>
                    </Box>
                </Container>
            </Box>
            <ISONotification notify={notify} setNotify={setNotify} />
            <Loader isShow={isLoading} />
        </React.Fragment>
    );
};
export default QuizList;
