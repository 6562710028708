import React from 'react';
import { Helmet } from 'react-helmet';
import  { useNavigate } from 'react-router-dom';
import { Box, 
    Container,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Button,
} from '@material-ui/core';
import ISONotification from 'src/framework/components/controls/ISONotification';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ImageUpload from 'src/components/files/ImageUpload';
import ActionEvent from 'src/components/actionevent/ActionEvent';

const TestArea = () => {
    // Notify 출력 설정
	const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: '' });
	const navigate = useNavigate();
    const myImage = React.useRef(ImageUpload);

    const handleTest = () => {
        console.log('myImage', myImage.current.getFile());
    }

    const handleTest2 = async () => {
        setTimeout(() => {
            let result = ActionEvent.ifFaceDetectTest();
            console.log(result);
        }, 100);
    }
  
    return (
        <React.Fragment>
            <Helmet>
                <title>TestArea</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Container maxWidth={false}>
                    <Card>
                        <CardHeader subheader="콤포넌트의 실행을 테스트" title="테스트 페이지" />
                        <Divider />
                        <CardContent>
                            <Box className="padding-20">
                                <div>
                                    ※ 사진의 품질이 떨어질 경우 사용 
                                </div>
                                <ImageUpload ref={myImage} type='image' />
                            </Box>
                            <Divider />
                            <Box>
                                <Button color="primary" fullWidth variant="contained" onClick={handleTest}> 테스트 </Button>
                            </Box>
                            <Box>
                                <Button color="primary" fullWidth variant="contained" onClick={handleTest2}> 테스트2 </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
			<ISONotification notify={notify} setNotify={setNotify} />
        </React.Fragment>
    );
}

export default TestArea;
