import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Grid, Button, Container, TextField, Typography } from '@material-ui/core';
import { MessageOnlyDialog } from 'src/framework/components/controls/ISODialog';
import useSignIn from 'src/framework/core/hooks/useSignIn';
import { e3, alertType } from 'src/framework/core/utils/utils';

/// E3MOBILE Login
const Login = () => {
    const title = `Login`; // 로그인 페이지 제목
    const loginType = 'USER';
    const navigate = useNavigate();

    // 로그인 및 메뉴 정보 처리
    const { SignIn, loadLoginInfo } = useSignIn();
    const { userid, userpw, remember } = loadLoginInfo();
    const [values, setValues] = React.useState({
        showPassword: false,
        isLoading: false
    });
    const msgRef = React.useRef();

    const closeDialog = () => {
        msgRef.current.hideDialog();
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Box className="MainLayoutBox">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            userid: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            userid: Yup.string().max(255).required('사용자 계정은 필수 입력값 입니다.'),
                            password: Yup.string().max(255).required('비밀번호는 필수 입력값 입니다.')
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setValues({ ...values, isLoading: true });
                            SignIn(
                                loginType,
                                values.userid,
                                values.password,
                                (ret, err) => {
                                    let isauth = false;
                                    try {
                                        if (err) {
                                            // console.log('login process :: ', err);
                                            if (typeof err === 'string') {
                                                msgRef.current.showDialog(err);
                                            } else {
                                                msgRef.current.showDialog('접속 오류입니다. 다시 시도해 주세요!');
                                            }
                                            setValues({ ...values, isLoading: false });
                                        }
                                        if (ret && ret.ErrorMessage && ret.ErrorMessage.length > 0) {
                                            // console.log('login process ret.ErrorMessage :: ', ret.ErrorMessage);
                                            msgRef.current.showDialog(ret.ErrorMessage);
                                        } else {
                                            // console.log('login process ret :: ', ret);
                                            isauth = true;
                                        }
                                    } catch (e) {
                                        e3.modal.alert(alertType.Error, e.message);
                                    } finally {
                                        setValues({ ...values, isLoading: false });
                                        setSubmitting(false);
                                        if (isauth) {
                                            navigate('/app/e3dashboard');
                                        }
                                    }
                                },
                                true
                            );
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box className="marginBottom-30">
                                    <Typography color="textPrimary" variant="h2">
                                        Login
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.userid && errors.userid)}
                                    fullWidth
                                    helperText={touched.userid && errors.userid}
                                    label="사용자계정"
                                    margin="normal"
                                    name="userid"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.userid}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="비밀번호"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <Box className="paddingTop-2">
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        로그인
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
                <p className="copyright">ⓒ Insight On.</p>
            </Box>
            <MessageOnlyDialog title="로그인 오류" closeDialog={closeDialog} ref={msgRef} />
        </React.Fragment>
    );
};

export default Login;
