import { useSelector } from 'react-redux';
import ISOAxios, { DataPackage } from '../../framework/core/apis/ISOAxios';
import { e3, alertType } from 'src/framework/core/utils/utils';
import { PartyMode } from '@material-ui/icons';

const ActionEvent = {
    NewParam: (ProgramId, MethodName) => {
        let dataPackage = DataPackage.create(ProgramId, MethodName);
        return dataPackage;
    },
    GetUser: () => {
        const tmp = window.sessionStorage.getItem('loginSign');
        const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
        return ret;
    },
    GetSate6User: () => useSelector((state) => state.user),
    ReloadUserData: async () => {
        const SESSION_EXPIRATION_TERM =
            parseFloat(process.env.REACT_APP_SESSION_EXPIRATION_TERM) || 1000 * 60 * 60 * 24;
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectAccountInfo');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));

            const response = await ISOAxios.Execute(params);
            if (response && response.JsonData && JSON.stringify(response.JsonData) !== '[]') {
                const resultData = JSON.parse(response.JsonData)[0];
                Object.keys(resultData).map((it) => (resultData[it] = encodeURI(resultData[it])));
                const expiration = new Date(new Date().getTime() + SESSION_EXPIRATION_TERM);
                resultData.expiration = expiration;
                resultData.RESULT = 200;
                return resultData;
            } else return null;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    Save6ReloadUserData: async () => {
        try {
            let result = await ActionEvent.ReloadUserData();
            if (result) {
                const val = typeof result !== 'string' ? JSON.stringify(result) : result;
                window.sessionStorage.setItem('loginSign', e3.base64Encode(val));
            }
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SaveReloadUserData: async () => {
        try {
            let result = await ActionEvent.ReloadUserData();
            if (result) {
                const val = typeof result !== 'string' ? JSON.stringify(result) : result;
                window.sessionStorage.setItem('loginSign', e3.base64Encode(val));
            }
            return result;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    AllowPrivacy: async (callback) => {
        try {
            let dataPackage = DataPackage.create('MAPI100', 'Update4PrivacyAllow');

            const response = await ISOAxios.Execute(dataPackage);
            await ActionEvent.Save6ReloadUserData();
            callback(response, null);
        } catch (error) {
            callback(null, error);
        }
    },
    AllowVow: async (callback) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'Update4VowAllow');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            const response = await ISOAxios.Execute(params);
            await ActionEvent.Save6ReloadUserData();
            callback(response, null);
        } catch (error) {
            callback(null, error);
        }
    },
    /*
    AllowFilesCount: async () => {
        let returnModel = {
            uploadCount: 0,
            allowCount: 0,
            totalLength: 0
        };
        try {
            let result = await ActionEvent.AllowSelectFiles();
            if (result) {
                if (result && result.length === 0) return false;
                result.forEach((element) => {
                    returnModel.uploadCount += parseInt(element.FCHK);
                    returnModel.allowCount += parseInt(element.ACHK);
                });
                returnModel.totalLength = result.length;
                return returnModel;
            } else {
                return returnModel;
            }
        } catch (e) {
            return returnModel;
        }
    },
    AllowFiles: async () => {
        try {
            let result = await ActionEvent.AllowFilesCount();
            if (result) {
                // console.log('AllowFilesCount --> AllowFiles :: ',result);
                window.sessionStorage.setItem('AllowFilesCount', JSON.stringify(result));
                if (result.totalLength === result.uploadCount && result.uploadCount === result.allowCount) return true;
                else return false;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    },
    GetAllowFilesCount: () => {
        const tmp = window.sessionStorage.getItem('AllowFilesCount');
        let returnModel = {
            uploadCount: 0,
            allowCount: 0,
            totalLength: 0
        };
        const ret = tmp && tmp.length > 0 ? tmp.toString() : JSON.stringify(returnModel);
        return JSON.parse(ret);
    }, */
    GetSafeSubject: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectSafeSubject');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('EDU_MTRL_ID', signinUser.EDU_MTRL_ID));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return JSON.parse(response.JsonData);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    /* GetSafeSubjectQuiz: async (EDU_MTRL_ID) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectSafeSubjectQuiz');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('EDU_MTRL_ID', EDU_MTRL_ID));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return JSON.parse(response.JsonData);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    }, */
    SetSafeSubjectResult: async (passYn) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'Update4SafeSubjectResult');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('EDU_MTRL_ID', signinUser.EDU_MTRL_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('SAFE_EDU_CMPLT_YN', passYn));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            await ActionEvent.Save6ReloadUserData();
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetExamSentence: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectExamSentence');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('EDU_MTRL_ID', signinUser.EDU_MTRL_ID));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return JSON.parse(response.JsonData);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SetExamSentenceResult: async (signinUser, passYn, passScore) => {
        try {
            let params = DataPackage.create('MAPI100', 'Update4ExamSentenceResult');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('EDU_MTRL_ID', signinUser.EDU_MTRL_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('PASS_YN', passYn));
            params.KeyValues.push(DataPackage.NewKeyValue('PASS_SCORE', passScore));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            ActionEvent.Save6ReloadUserData();
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SetUserFacePhoto: async (photo) => {
        try {
            let params = DataPackage.create('MAPI100', 'Update4FacePhoto');
            params.KeyValues.push(DataPackage.NewKeyValue('ID_PHOTO', photo));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            ActionEvent.Save6ReloadUserData();
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SavePersonalSajin: async (dataUri) => {
        try {
            let params = DataPackage.create('MAPI100', 'toImage');
            params.KeyValues.push(DataPackage.NewKeyValue('ID_PHOTO', dataUri));
            // params.JsonData = this.state.dataUri;
            const response = await ISOAxios.Execute(params);
            if (response) ActionEvent.Save6ReloadUserData();
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        } finally {
            ActionEvent.SaveSessionPersonalSajin(dataUri);
        }
    },
    LoadPersonalSajin: async () => {
        try {
            let params = DataPackage.create('MAPI100', 'fromImage');
            let dataUri = '';

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            if (response.JsonData) {
                if (response.JsonData.indexOf('base64') < 0) {
                    dataUri = `data:image/jpeg;base64, ${response.JsonData}`;
                } else {
                    dataUri = response.JsonData;
                }
            } else {
                dataUri = './static/images/avatars/nopicture.svg';
            }
            setTimeout(() => {
                ActionEvent.SaveSessionPersonalSajin(dataUri);
            }, 100);
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SaveSessionPersonalSajin: (dataUri) => {
        try {
            window.sessionStorage.setItem('PersonalSajin', e3.base64Encode(dataUri));
        } catch (e) {
            e3.modal.alert(alertType.Error, '[저장실패]' + e.message);
        }
    },
    GetPersonalSajin: () => {
        const tmp = window.sessionStorage.getItem('PersonalSajin');
        const ret = tmp && tmp.length > 0 ? e3.base64Decode(tmp.toString()) : './static/images/avatars/nopicture.svg';
        return ret;
    },
    GetUploadFileType: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'getUloadFileType');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_CD', signinUser.VISIT_CD));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_PURPOSE', signinUser.VISIT_PURPOSE));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }

            return response.JsonData;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetUploadFiles: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectUploadFiles');
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            params.KeyValues.push(DataPackage.NewKeyValue('FILE_TYPE', signinUser.FILE_TYPE));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response.JsonData;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetBpInfo: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectBpInfo');

            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SaveFile: async (fileList) => {
        try {
            let params = DataPackage.create('MAPI100', 'setUloadFile');
            params.JsonData = JSON.stringify(fileList);

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    DeleteFile: async (fileType) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'DeleteFile');
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            params.KeyValues.push(DataPackage.NewKeyValue('FILE_TYPE', fileType));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    DownloadFile: async (fileType) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'DownloadFile');
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            params.KeyValues.push(DataPackage.NewKeyValue('FILE_TYPE', fileType));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    ifFaceDetectValidate: async (imageData) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'IfFaceDetectTest');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.JsonData = imageData;

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    ifFaceDetectTest: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'IfFaceDetectTest');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('user_id', 'admin'));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    getUserData: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectUserInfo');
            if (signinUser.REQ_TYPE != 'REQ_TYPE_001') {
                params.KeyValues.push(DataPackage.NewKeyValue('USER_CHECK', 'Y'));
            }else{
                params.KeyValues.push(DataPackage.NewKeyValue('USER_CHECK2','Y'));
            }
            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    DeleteUserInfo: async (req) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'UpdateUserInfo');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', req));

            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    getUserHisData: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectUserHisInfo');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));

            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response.JsonData;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetOutItemList: async (params) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectOutItemList');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('REG_RESNO', signinUser.USER_ID));

            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetVisitGuestList: async (params) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectVisitGuestList');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('MANAGER', signinUser.USER_ID));

            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SetVisitRegiste: async (status) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'UpdateUserVisitStatus');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            params.KeyValues.push(DataPackage.NewKeyValue('ADD_TYPE', signinUser.ADD_TYPE));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_CD', signinUser.VISIT_CD));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_PURPOSE', signinUser.VISIT_PURPOSE));
            params.KeyValues.push(DataPackage.NewKeyValue('STATUS', status));

            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SetInOutDate: async (flag) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'InsertUserInOutDate');
            if (flag == 'U') {
                params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            } else {
                params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));
            }
            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetInOutDate: async (flag) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectUserInOutDate');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            if (flag == 'U') {
                params.KeyValues.push(DataPackage.NewKeyValue('CHECK', 'U'));
            } else {
                params.KeyValues.push(DataPackage.NewKeyValue('CHECK', 'P'));
            }
            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    SetUserSajin: async (saginUri) => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'toImage');

            params.KeyValues.push(DataPackage.NewKeyValue('ID_PHOTO', saginUri));

            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    },
    GetInOutPlace: async () => {
        try {
            const signinUser = ActionEvent.GetUser();
            let params = DataPackage.create('MAPI100', 'SelectInOutPlace');
            params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
            params.KeyValues.push(DataPackage.NewKeyValue('VISIT_REQ_SEQ', signinUser.VISIT_REQ_SEQ));

            const response = await ISOAxios.Execute(params);

            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    }
};

export default ActionEvent;
