import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import { Box, Container, Grid, Card, CardContent, Button, ButtonGroup, Typography } from '@material-ui/core';
import moment from 'moment';
import Loader from 'src/components/loading/Loader';
import useUserInfo from '../framework/core/hooks/useUserInfo';

const E3Dashboard = () => {
    const { signinUser, stateUser, goToPage } = useUserInfo();
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    const [itemList, setItemList] = React.useState();
    const [visitList, setVisitList] = React.useState();
    const [itemflag, setItemFlag] = React.useState(false);
    const [visitflag, setVisitFlag] = React.useState(false);
    const [inOutDate, setInOutDate] = React.useState();
    const [viewflag, setViewFlag] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        ReLoad();
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    const ReLoad = () => {
        outItemList();
        visitGuestList();
        getInOutDate();
    };

    const outItemList = async () => {
        let info = await ActionEvent.GetOutItemList();
        if (info && info.KeyValues[2].Value == '0') {
            setItemList('');
        } else {
            setItemList(JSON.parse(info.JsonData));
        }
    };

    const onItemFlag = () => {
        if (itemflag == true) {
            setItemFlag(false);
        } else {
            setItemFlag(true);
        }
    };
    const visitGuestList = async () => {
        let info = await ActionEvent.GetVisitGuestList();
        if (info && info.KeyValues[2].Value == '0') {
            setVisitList('');
        } else {
            setVisitList(JSON.parse(info.JsonData));
        }
    };

    const onVisitFlag = () => {
        if (visitflag == true) {
            setVisitFlag(false);
        } else {
            setVisitFlag(true);
        }
    };

    const getInOutDate = async () => {
        let info = await ActionEvent.GetInOutDate('U');
        if (info && info.KeyValues[1].Value == '0') {
            setInOutDate('-');
        } else {
            info = JSON.parse(info.JsonData);
            setInOutDate(`${decodeURI(info[0].IN_OUT_DATETIME)}`);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>E3Dashboard</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        {`${decodeURI(signinUser.USER_NAME)}`}님, 환영합니다.
                    </Typography>
                </Box>
                <Container maxWidth={false}>
                    <Grid container spacing={2}>
                        <div className="staff-info">
                            <Typography variant="h3">
                                방문 신청 도착 :{' '}
                                <strong>{visitList && visitList != '' ? visitList.length : '0'}</strong> 건
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                            </Typography>
                            {visitflag === true ? (
                                <a onClick={onVisitFlag} className="up">
                                    닫기
                                </a>
                            ) : (
                                <a onClick={onVisitFlag} className="down">
                                    확인하기
                                </a>
                            )}
                            {visitflag === true ? (
                                <div style={{ paddingTop: '13px', paddingBottom: '10px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    이름
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    회사
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    신청일
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {visitList && visitList != ''
                                                ? visitList.map((item) => (
                                                      <tr>
                                                          <td>{item.EMP_NM ? item.EMP_NM : ''}</td>
                                                          <td>{item.CMP_NM ? item.CMP_NM : ''}</td>
                                                          <td>{item.REQ_DATE ? `${decodeURI(item.REQ_DATE)}` : ''}</td>
                                                      </tr>
                                                  ))
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            <p className="f_note mt-10 ml-0">※ 방문승인은 PC에서만 가능합니다.</p>
                        </div>

                        <div className="staff-info mb-0">
                            <Typography variant="h3">
                                반출 회수 예정 : <strong>{itemList && itemList != '' ? itemList.length : '0'}</strong>{' '}
                                건 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                            </Typography>
                            {itemflag === true ? (
                                <a onClick={onItemFlag} className="up">
                                    닫기
                                </a>
                            ) : (
                                <a onClick={onItemFlag} className="down">
                                    확인하기
                                </a>
                            )}
                            {itemflag === true ? (
                                <div style={{ paddingTop: '13px', paddingBottom: '10px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    반출일
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    반출자
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    회수예정일
                                                </th>
                                                <th
                                                    style={{
                                                        textAlign: 'center',
                                                        verticalAlign: 'middle',
                                                        width: '80px'
                                                    }}
                                                >
                                                    품명
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemList && itemList != ''
                                                ? itemList.map((item) => (
                                                      <tr>
                                                          <td>{`${decodeURI(item.CARRY_OUT_DATE)}`}</td>
                                                          <td>{item.CARRIER_NAME}</td>
                                                          <td>{`${decodeURI(item.CARRY_OUT_DATE)}`}</td>
                                                          <td>{item.ITEM_NAME}</td>
                                                      </tr>
                                                  ))
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            <p className="f_note mt-10 ml-0"> ① 반출 시 입력한 회수예정일 기준 D-2일 입니다.</p>
                            <p className="f_note mt-5 ml-0"> ② 상세내용은 PC에서 확인하시기 바랍니다.</p>
                        </div>
                    </Grid>
                    <hr />
                    <div style={{ paddingBottom: '10px' }}>
                        <Box className="tip">
                            <a
                                onClick={() => {
                                    setIsLoading(true);
                                    ReLoad();
                                    setTimeout(() => {
                                        setIsLoading(false);
                                    }, 300);
                                }}
                                style={{ float: 'right' }}
                            >
                                <img src="./static/images/t_refresh_on.png"></img> 새로고침
                            </a>
                        </Box>
                    </div>
                    <div className="user-info last-check">
                        <Card>
                            <CardContent>
                                <Grid container spacing={12} className="BoxBetween">
                                    <Grid xs={12}>
                                        <Typography color="textSecondary" gutterBottom variant="h4">
                                            <span className="dashboad_label">마지막 출문 체크</span>{' '}
                                            <span className="dashboad_value">{inOutDate}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                    <Grid container className="banner">
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                disabled={viewflag}
                                variant="contained"
                                className="door"
                                onClick={(e) => {
                                    e3.modal.custom(
                                        String(moment().format('YYYY-MM-DD HH:mm')),
                                        '본인 핸드폰인 경우만 체크하셔야 정상적으로 출문 처리가 됩니다.',
                                        async () => {
                                            let info = await ActionEvent.SetInOutDate('U');
                                            if (
                                                info &&
                                                info.KeyValues.find((item) => item.Key === 'CODE').Value == '200'
                                            ) {
                                                getInOutDate();
                                                e3.modal.alert(alertType.Info, '출문 처리가 완료되었습니다.');
                                            }
                                        }
                                    );
                                }}
                            >
                                출문체크
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Loader isShow={isLoading} />
        </React.Fragment>
    );
};

export default E3Dashboard;
