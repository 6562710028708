import React, { useCallback, useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import { Container, Typography, Box, Button, makeStyles } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';
import { alertType, e3 } from '../framework/core/utils/utils';
import useLocalStorage from '../framework/core/hooks/useLocalStorage';
import { handleError } from 'video-react/lib/actions/video';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        marginTop: theme.spacing(5),
    },
    result: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    scanner: {
        margin: '0 auto',
        width: '100%',
    },
}));

const MdmQrScan = () => {
    const classes = useStyles();
    const [scanResult, setScanResult] = useState(null);
    const navigate = useNavigate();
    const { localStorageSave } = useLocalStorage();

    const handleScan = useCallback((data) => {
        if (data) {
            setScanResult(data);
        }
    }, []);


    const videoConstraints = {
        facingMode: 'environment', // 후면 카메라 사용
    };

    const goBack = useCallback(() => {
        navigate('/m_eats');
    }, [navigate]);

    const manualInput = useCallback(() => {
        navigate('/mdmVendSelect');
    }, [navigate]);

    useEffect(() => {
        if (scanResult) {
            onSearchVend(scanResult);
        }
    }, [scanResult]);

    const onSearchVend = async (value) => {
        let param = DataPackage.create('MDM400', 'SelectVendQr');
        const idPattern = /m_eats\/(\d+)/;
        const match = value.replaceAll('"', '').match(idPattern);

        if (match && match[1]) {
            const extractedId = match[1];
            param.KeyValues.push(DataPackage.NewKeyValue('VEND_CODE', extractedId));
            console.log(extractedId);
        } else {
            console.log("ID를 추출할 수 없습니다.");
        }

        try {
            const response = await ISOAxios.Execute(param);
            const vendData = JSON.parse(response.JsonData);
            console.log('response: ', response);
            if (vendData.length > 0) {
                const venderCode = vendData[0]?.VENDER_CODE ?? '';
                const venderName = vendData[0]?.VENDER_NAME ?? '';
                e3.modal.confirm(
                    '가맹점 확인',
                    `선택된 가맹점: ${venderName}`,
                    '확인',
                    '취소',
                    () => onConfirmClick(venderCode, venderName)
                );
            } else {
                e3.modal.alert(alertType.Error, '선택된 가맹점이 존재하지 않습니다');
                setScanResult(null);
            }
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    };

    const onConfirmClick = (venderCode, venderName) => {
        localStorageSave('vender_code', venderCode);
        localStorageSave('vender_name', venderName);
        navigate('/mdmLogin', {
            state: {
                from: 'qr',
                code: scanResult,
            },
        });
    };

    return (
        <Container className={classes.root}>
            <Typography variant="h4" gutterBottom>
                QR 코드 스캔
            </Typography>
            <Box className={classes.scanner}>
                <QrReader
                    onResult={(result, error) => {
                        if (result) {
                            handleScan(result?.text);
                        }

                        if (error) {
                            handleError(error);
                        }
                    }}
                    constraints={videoConstraints}
                    style={{ width: '100%' }}
                />
            </Box>
            {scanResult ? (
                <Typography variant="h6" className={classes.result}>
                    스캔 결과: {scanResult}
                </Typography>
            ) : (
                <Typography variant="body1" className={classes.result}>
                    QR 코드를 스캔해 주세요.
                </Typography>
            )}
            <Button
                variant="contained"
                color="primary"
                startIcon={<CameraAlt />}
                className={classes.button}
                onClick={() => setScanResult(null)}
            >
                다시 스캔하기
            </Button>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={goBack}
            >
                이전으로
            </Button>
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    className={classes.button}*/}
            {/*    onClick={manualInput}*/}
            {/*>*/}
            {/*    수동 입력*/}
            {/*</Button>*/}
        </Container>
    );
};

export default MdmQrScan;
