import React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    // Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar
} from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
// import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';
import useSessionStorage from '../framework/core/hooks/useSessionStorage';

const APP_TYPE = process.env.REACT_APP_TYPE;

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
    const { clearSessionStorage } = useSessionStorage('loginSign');
    const [notifications] = useState([]);
    const handleLogoutClick = () => {
        clearSessionStorage(true);
    };
    const location = useLocation();
    const isMdmRoute = (location.pathname.includes('/mdm') || location.pathname.includes('/m_eats'));

    return (
        <AppBar elevation={0} {...rest} className="DashboardHeader">
            <Toolbar>
                <RouterLink to={isMdmRoute ? "/m_eats" : "/"} className="DashboardNavbarLogo">
                    <Logo />
                    {APP_TYPE === 'E3MOBILE' ? <span>작업 관리</span> : <span>스마트 안전출입관리시스템</span>}
                </RouterLink>
                <Box className="DashboardNavbarItemBox" />
                <Hidden mdDown>
                    {/*<IconButton color="inherit" className="DashboardNavbarItem">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
                    <IconButton color="inherit" onClick={handleLogoutClick} className="DashboardNavbarItem">
                        로그아웃
                        {/*<InputIcon />*/}
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen} className="menu-all"></IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
