import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
//import { experimentalStyled } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { experimentalStyled } from '@material-ui/styles';
import MainNavbar from './MainNavbar';

const MainLayout = () => (
  <div className="MainLayoutRoot">
    <MainNavbar />
    <div className="MainLayoutWrapper">
      <div className="MainLayoutContainer">
        <div className="MainLayoutContent">
          <Outlet />
        </div>
      </div>
    </div>
  </div>
);

export default MainLayout;
