import React, { Component } from 'react';
import {
    Typography,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    Slider,
    Hidden,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import autoBind from "auto-bind";
import ISOCarouselBanner from './ISOCarouselBanner';

class ISOCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: this.props.autoPlay ? this.props.autoPlay : false,
            animation: this.props.animation ? this.props.animation : "fade",
            indicators: this.props.indicators ? this.props.indicators : true,
            timeout: this.props.timeout ? this.props.timeout : 500, // Animation
            interval: this.props.interval ? this.props.interval : 6000,
            navButtonsAlwaysVisible: this.props.navButtonsAlwaysVisible ? this.props.navButtonsAlwaysVisible : true,
            navButtonsAlwaysInvisible: false,
            cycleNavigation: false,
            items: this.props.items,
            index: this.props.index ? this.props.index : 0,
            changeOnFirstRender: this.props.changeOnFirstRender ? this.props.changeOnFirstRender : false,
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible() {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    toggleNavButtonsAlwaysInvisible() {
        this.setState({
            navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
        })
    }

    toggleCycleNavigation() {
        this.setState({
            cycleNavigation: !this.state.cycleNavigation
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    setActive(index) {
        this.setState({
            index: index,
            changeOnFirstRender: true,
        })
    }

    render() {
        let setIndex = this.props.setIndex;
        return (
            <React.Fragment>
                <Carousel
                    className="ISOCarousel"
                    autoPlay={this.state.autoPlay}
                    index={this.state.index}
                    changeOnFirstRender={this.state.changeOnFirstRender}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    interval={this.state.interval}
                    cycleNavigation={this.state.cycleNavigation}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                    navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                    next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                    prev={(now, previous) => { now !== 0 && console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)} }
                    onChange={(now, previous) => setIndex(now) && console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
                    // fullHeightHover={false}
                    // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
                    // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
                    // indicatorContainerProps={{style: {margin: "20px"}}}
                    // NextIcon='next'
                >
                    {
                        this.state.items.map((item, index) => {
                            return <ISOCarouselBanner item={item} key={index} showContent={false} length={1} contentPosition={item.contentPosition} />
                        })
                    }
                </Carousel>

                <Hidden lgDown>
                    <FormLabel component="legend">Options</FormLabel>
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.toggleAutoPlay} checked={this.state.autoPlay} value="autoplay"
                                color="primary" />
                        }
                        label="Auto-play"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.toggleIndicators} checked={this.state.indicators} value="indicators"
                                color="primary" />
                        }
                        label="Indicators"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.toggleNavButtonsAlwaysVisible} checked={this.state.navButtonsAlwaysVisible} value="NavButtonsAlwaysVisible" color="primary" />
                        }
                        label="NavButtonsAlwaysVisible"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.toggleNavButtonsAlwaysInvisible} checked={this.state.navButtonsAlwaysInvisible} value="NavButtonsAlwaysInvisible" color="primary" />
                        }
                        label="NavButtonsAlwaysInvisible"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={this.toggleCycleNavigation} checked={this.state.cycleNavigation} value="CycleNavigation" color="primary" />
                        }
                        label="CycleNavigation"
                    />

                    <FormControlLabel
                        control={
                            <RadioGroup name="animation" value={this.state.animation} onChange={this.changeAnimation} row
                                style={{ marginLeft: "10px" }}>
                                <FormControlLabel value="fade" control={<Radio color="primary" />} label="Fade" />
                                <FormControlLabel value="slide" control={<Radio color="primary" />} label="Slide" />
                            </RadioGroup>
                        }
                    />

                    <FormControlLabel
                        control={
                            <div style={{ width: 300 }}>
                                <Typography id="discrete-slider" gutterBottom>
                                    Animation Duration (Timeout) in ms
                                </Typography>
                                <Slider
                                    defaultValue={500}
                                    getAriaValueText={() => `${this.state.timeout}ms`}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={100}
                                    marks
                                    min={100}
                                    max={2000}
                                    onChange={this.changeTimeout}
                                />
                            </div>
                        }
                    />
                </Hidden>
            </React.Fragment>
        )
    }
}

export default ISOCarousel;