import React, { useEffect, useRef, useState } from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ISOInput } from '../framework/components/controls';
import ISOAxios, { DataPackage } from '../framework/core/apis/ISOAxios';
import { alertType, e3 } from '../framework/core/utils/utils';
import ActionEvent from '../components/actionevent/ActionEvent';
import useLocalStorage from '../framework/core/hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        width: '350px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9'
    },
    header: {
        marginBottom: '20px',
    },
    content: {
        textAlign: 'left',
    },
    row: {
        marginBottom: '5px',
    },
    title: {
        marginBottom: '20px',
        fontSize: '30px',
        color: '#0058b2',
    },
    input: {
        width: '100%',
        padding: '5px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: '#fff',
    },
    isoInput: {
        width: '100%',
        fontSize: '14px',
    },
    button: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#0d6efd',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        width: '100%',
    },
}));

const MdmPayment = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const signinUser = ActionEvent.GetUser();
    const { localStorageLoad } = useLocalStorage();

    const [venderCode, setVenderCode] = useState('');
    const [venderName, setVenderName] = useState('');
    const [userNameInput, setUserNameInput] = useState('');
    const [amountLimit, setAmountLimit] = useState('0');
    const [amountLimitOrigin, setAmountLimitOrigin] = useState('0');
    const [isDisabled, setIsDisabled] = useState(true);
    const [message, setMessage] = useState('요청금액은 잔여금액을 초과할 수 없습니다.');
    const [formattedDate, setFormattedDate] = useState('');
    let isProcessing = false;

    const ref_REQUEST_AMOUNT = useRef();

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            navigate('/m_eats');
        };
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            setFormattedDate(
                `${today.getFullYear()}년 ${today.getMonth() + 1}월 ${today.getDate()}일 ${today.getHours()}시 ${today.getMinutes()}분`
            );
        };

        updateDateTime();
        const intervalId = setInterval(updateDateTime, 60000); // 1분마다 업데이트

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 정리
    }, []);

    useEffect(() => {
        if (!localStorageLoad('vender_name')) {
            e3.modal.alert(alertType.Error, '선택된 가맹점이 없습니다.');
            navigate('/mdmVenderSelect');
        } else {
            setVenderName(localStorageLoad('vender_name'));
            setVenderCode(localStorageLoad('vender_code'));
        }
        fetchUserInfo(empNo).then(r => fetchAmountLimit(empNo));

    }, [localStorageLoad, navigate]);

    const userName = signinUser?.EMP_NM || signinUser?.USER_NAME || '무명인';
    const empNo = signinUser?.EMP_NM || '';

    const fetchUserInfo = async (id) => {
        try {
            const param = DataPackage.create("MDM400", "SelectUserName");
            param.KeyValues.push(DataPackage.NewKeyValue("EMP_NO", id));
            const response = await ISOAxios.Execute(param);
            const userData = JSON.parse(response.JsonData)[0];
            setUserNameInput(`${userData.USER_NAME} (${userName})`);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    };

    const fetchAmountLimit = async (id) => {
        try {
            const param = DataPackage.create("MDM400", "SelectAmountLimit");
            param.KeyValues.push(DataPackage.NewKeyValue("EMP_NO", id));
            const response = await ISOAxios.Execute(param);
            const limit = JSON.parse(response.JsonData)[0].AMOUNT_LIMIT;
            setAmountLimit(addCommas(removeNonNumeric(limit)) + '원');
            setAmountLimitOrigin(limit);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    };

    const handleSubmit = async() => {
        const amount = ref_REQUEST_AMOUNT.current.getValue();
        if (isProcessing) return;

        isProcessing = true; // 프로세스 시작 표시

        await e3.modal.confirm(
            '결제확인',
            `${venderName} 에서 ${amount}원 결제를 진행하시겠습니까?`,
            '확인',
            '취소',
            onSave
        );
    };

    const onSave = async () => {
        const amount = ref_REQUEST_AMOUNT.current.getValue();
        if (!amount) {
            e3.modal.alert(alertType.Warning, '요청금액을 입력해주세요');
            return;
        }
        try {
            const param = DataPackage.create("MDM400", "InsertPayment");
            param.KeyValues.push(
                DataPackage.NewKeyValue("VENDER_CODE", venderCode),
                DataPackage.NewKeyValue("VENDER_NAME", venderName),
                DataPackage.NewKeyValue("EMP_NO", userName),
                DataPackage.NewKeyValue("AMOUNT", amount)
            );
            const response = await ISOAxios.Execute(param);
            if (response.KeyValues[0].Value === '200') {
                e3.modal.alert(alertType.Success, '결제가 완료되었습니다.');
                navigate('/mdmReceipt');
            }
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
            isProcessing = false;
        }
    };

    const onAmountChange = (e) => {
        const value = removeNonNumeric(e.target.value);
        if (Number(value) > amountLimitOrigin) {
            ref_REQUEST_AMOUNT.current.setValue(amountLimitOrigin);
            setMessage('요청금액은 잔여금액을 초과할 수 없습니다.');
        } else if (Number(value) === 0) {
            ref_REQUEST_AMOUNT.current.setValue('');
            setMessage('요청금액은 10원 이상이어야 합니다.');
            setIsDisabled(true);
        } else {
            ref_REQUEST_AMOUNT.current.setValue(value);
            setMessage('요청금액은 잔여금액을 초과할 수 없습니다.');
            setIsDisabled(Number(value) < 100);
        }
    };

    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

    return (
        <React.Fragment>
            <Helmet>
                <title>롯데정밀화학 기숙사 식비 관리</title>
            </Helmet>
            <Box className="MainLayoutBox intro-wrap-mdm">
                <Container className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.header}>
                            <h2 className={classes.title}>결제 정보</h2>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.row}>
                                <label className={classes.label}>가맹점</label>
                                <input type="text" value={venderName} readOnly className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>일시</label>
                                <input type="text" value={formattedDate} readOnly className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>성명(사번)</label>
                                <input type="text" value={userNameInput} readOnly className={classes.input} />
                            </div>
                            <div className={classes.row}>
                                <label className={classes.label}>잔여금액</label>
                                <input type="text" value={amountLimit} readOnly className={classes.input} />
                            </div>
                            <div id={'amountIn'} className={classes.row}>
                                <label className={classes.label}>요청금액</label>
                                <ISOInput
                                    ref={ref_REQUEST_AMOUNT}
                                    className={classes.isoInput}
                                    onChange={onAmountChange}
                                />
                                <div dangerouslySetInnerHTML={{ __html: message }} />
                            </div>
                            <button className={classes.button} onClick={handleSubmit} disabled={isDisabled}>
                                결제 하기
                            </button>
                        </div>
                    </div>
                </Container>
            </Box>
            <footer>
                <p className="copyright">COPYRIGHT LOTTE Fine Chemical Co., Ltd.</p>
            </footer>
        </React.Fragment>
    );
};

export default MdmPayment;
