import axios from 'axios';
import base64 from 'base-64';
import { alertType, e3 } from '../utils/utils';
import { loadCookie } from '../hooks/useCookieData';

const API_URL = process.env.REACT_APP_PROXY;
const APP_TYPE = process.env.REACT_APP_TYPE;

const axiosRequestConfig = {
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: 'json',
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN'
};

const axiosInstance = axios.create(axiosRequestConfig);

export const DataPackage = {
    init: () => {
        let dataPackage = {};
        dataPackage.Token = '';
        dataPackage.JsonData = '';
        dataPackage.ErrorMessage = '';
        dataPackage.ProgramId = '';
        dataPackage.MethodName = '';
        dataPackage.KeyValues = [];
        dataPackage.Division = '';
        return dataPackage;
    },
    InitSystemDataPackage: () => {
        let dataPackage = DataPackage.init();
        dataPackage.AssemblyName = 'E3.COM.SYS.BIZ.dll';
        dataPackage.Namespace = 'E3.COM.SYS.BIZ';
        dataPackage.ClassName = 'SYSTEM';
        return dataPackage;
    },
    create: (programid, methodname) => {
        let dataPackage = DataPackage.init();
        dataPackage.ProgramId = programid;
        dataPackage.MethodName = methodname;
        return dataPackage;
    },
    NewKeyValue: (key, value) => {
        let keyValue = {};
        keyValue.Key = key;
        keyValue.Value = value;
        return keyValue;
    },
    GetParam4Plant: () => {
        let m = {};
        m.Key = 'PLT_ID';
        m.Value = DataPackage.GetPlantId();
        return m;
    },
    GetPlantId: () => {
        const tmp = window.sessionStorage.getItem('loginSign');
        const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
        return ret.PLT_ID || '';
    }
};

const ISOAxios = {
    CreateInstance: (
        baseUrl,
        headers = { 'Content-Type': 'application/json; charset=utf-8' },
        responseType = 'json'
    ) => {
        const config = {
            baseURL: baseUrl,
            headers: headers,
            responseType: responseType,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN'
        };
        return axios.create(config);
    },
    SetToken: (value) => {
        window.sessionStorage.setItem('Token', value);
    },
    GetToken: () => {
        const tmp = window.sessionStorage.getItem('Token');
        const ret = tmp && tmp.length > 0 ? tmp.toString() : undefined;
        return ret;
    },
    Login: async (params) => {
        try {
            const result = await axiosInstance.post('/login', JSON.stringify(params));
            return typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    MdmLogin: async (params) => {
        try {
            const result = await axiosInstance.post('/MdmSignin', JSON.stringify(params));
            return typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    Execute: async (params) => {
        try {
            params.Token = ISOAxios.GetToken();
            let tmp = JSON.stringify(params);
            const result = await axiosInstance.post('/execute', JSON.stringify(params));
            const result_data = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;

            if (!result_data.ErrorMessage) {
                return result_data;
            }
            e3.modal.alert(alertType.Error, result_data.ErrorMessage);

            return false;
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    RegistGuest: async (params) => {
        try {
            const result = await axiosInstance.post('/RegistGuest', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    RequestCertificationCode: async (params) => {
        try {
            const result = await axiosInstance.post('/RequestCertificationCode', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },

    /**
     * 모바일 식수관리 로그인용 인증번호 생성
     * */
    RequestMdmCertificationCode: async (params) => {
        try {
            const result = await axiosInstance.post('/RequestMdmCertificationCode', JSON.stringify(params));
            console.log('result : ', result);
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    GetPlantId: () => {
        const tmp = window.sessionStorage.getItem('loginSign');
        const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
        return ret.PLT_ID || '';
    },
    GetMngList: async (params) => {
        try {
            const result = await axiosInstance.post('/GetMngList', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    GetCmpList: async (params) => {
        try {
            const result = await axiosInstance.post('/GetCmpList', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    InsertCmp: async (params) => {
        try {
            const result = await axiosInstance.post('/InsertCmp', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    /* GetCarList: async (params) => {
        try {
            const result = await axiosInstance.post('/GetCarList', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        } 
    }, */
    GetUserInfo: async (params) => {
        try {
            const result = await axiosInstance.post('/GetUserInfo', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        } finally {
        }
    },
    SetUserInfo: async (params) => {
        try {
            const result = await axiosInstance.post('/SetUserInfo', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    GetUserCheck: async (params) => {
        try {
            const result = await axiosInstance.post('/GetUserCheck', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    GetUserModCheck: async (params) => {
        try {
            const result = await axiosInstance.post('/GetUserModCheck', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    SetUserModify: async (params) => {
        try {
            const result = await axiosInstance.post('/SetUserModify', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    },
    GetCodes: async (params) => {
        try {
            const result = await axiosInstance.post('/GetCodes', JSON.stringify(params));
            const responseData = typeof result.data === 'string' ? JSON.parse(result.data) : result.data;
            return responseData;
        } catch (err) {
            //throw new Error(err);
            e3.modal.alert(alertType.Error, err.message);
        }
    }
};

export const GetCodes = async (codeID) => {
    let params = DataPackage.create('SYS100', 'getCodes');
    params.KeyValues.push(DataPackage.NewKeyValue('codeId', codeID));
    const result = await ISOAxios.Execute(params);
    if (result) return JSON.parse(result.JsonData);
    return null;
};

export default ISOAxios;
