import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ISONotification from 'src/framework/components/controls/ISONotification';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControlLabel,
    Grid,
    Typography,
    RadioGroup,
    Radio
} from '@material-ui/core';
import { ISODialog } from 'src/framework/components/controls/ISODialog';

const SettingsView = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const popupTitle = '개인정보동의';
    const userInfo = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { setPrivacyAllow } = useSessionStorage(e3.sessionKeys.LoginSign);
    const [value1, setValue1] = React.useState('N');
    const [value2, setValue2] = React.useState('N');

    React.useImperativeHandle(ref, () => ({
        showDialog() {
            setIsOpen(true);

            setTimeout(() => {}, 600);
        }
    }));

    const onClose = () => {
        setIsOpen(false);
    };

    /* function handleChange(e) {
        if ('valueAll' === e.target.name) {
            setChecked({
                value1: !checked.valueAll,
                value2: !checked.valueAll
            });
        } else {
            setChecked({ ...checked, [e.target.name]: e.target.checked });
        }
        /*  console.log('[' + e.target.name + ']: ' + e.target.checked);
        setChecked({ ...checked, [e.target.name]: e.target.checked }); 
    } */

    function handleClick4Ok(e) {
        if (value1 === 'Y' && value2 === 'Y') {
            if (userInfo.isLoggedIn === true) {
                ActionEvent.AllowPrivacy((response, error) => {
                    if (error === null && response) {
                        setPrivacyAllow();
                        setTimeout(() => {
                            onClose();
                        }, 300);
                    }
                });
            } else {
                setPrivacyAllow();
                onClose();
            }
        } else {
            e3.modal.alert(alertType.Warning, '전체 동의 후 진행 가능합니다.');
        }
    }

    return (
        <>
            <React.Fragment>
                <ISODialog
                    isOpen={isOpen}
                    onClose={onClose}
                    isReasize={false}
                    isDraggable={false}
                    dialogId="PRIVACY_POPUP_Dialog"
                    fullWidth={true}
                >
                    <Helmet>
                        <title>개인정보동의</title>
                    </Helmet>
                    <Box className="DashboardLayoutContentBox privacy_wrap">
                        <Box>
                            <Typography color="textPrimary" variant="h2">
                                개인정보 수집 · 이용 동의
                            </Typography>
                        </Box>
                        <Container maxWidth="lg">
                            <div>
                                <form {...props}>
                                    <div>
                                        <div className="agree-page-block marginTop-20">
                                            <p className="agree-page-line">
                                                롯데정밀화학주식회사(이하 "회사"라 합니다)는 사업장 출입를 위해 회사
                                                사업장을 출입하는 차량 및 인원의 출입 신청 및 등록을 위해 아래와 같이
                                                최소한의 개인정보를 수집하고 있습니다.
                                            </p>
                                        </div>
                                        <div className="agree-page-block marginTop-20">
                                            <h3>1. 수집하는 개인정보의 항목 및 이용목적</h3>
                                            <p className="agree-page-line">
                                                회사에서 수집하는 개인정보의 항목 및 이용목적은 다음과 같습니다.
                                            </p>
                                            <table className="agree-page-table marginTop-10">
                                                <colgroup>
                                                    <col className="col-2" />
                                                    <col className="col-2" />
                                                    <col className="col-5" />
                                                    <col className="col-5" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={'2'}>구분</th>
                                                        <th rowSpan={'2'}>수집·이용 항목</th>
                                                        <th rowSpan={'2'}>수집·이용 목적</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={'2'}>
                                                            필수
                                                            <br />
                                                            정보
                                                        </td>
                                                        <td>
                                                            개인
                                                            <br />
                                                            정보
                                                        </td>
                                                        <td>
                                                            <b>
                                                                {/*CR35602 LFC 디지털혁신팀 김도형 수석 요청사항*/}
                                                                {/*개인정보 수집 이용 동의서 수정*/}                                                                
                                                                {/*방문자명, 휴대폰번호, 회사명, 차량번호, 차종, 차종상세,*/}
                                                                {/*사진*/}
                                                                방문자명, 휴대폰번호, 회사명, 차량번호, 차종, 차종상세, 사진(원본정보)
                                                            </b>
                                                        </td>
                                                        <td rowSpan={'2'}>
                                                            <ul>
                                                                <li>본인식별 및 인증, 차량 확인</li>
                                                                <li>안전교육 수료여부 확인</li>
                                                                <li>출입 적격성 확인</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            민감
                                                            <br />
                                                            정보
                                                        </td>
                                                        <td>
                                                            {/*CR35602 LFC 디지털혁신팀 김도형 수석 요청사항*/}
                                                            {/*개인정보 수집 이용 동의서 수정*/}
                                                            {/*<b>건강검진결과서, 혈압</b>*/}
                                                            <b>건강검진결과서, 혈압, 생체인식정보(얼굴)</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'}>생성정보</td>
                                                        <td>
                                                            <b>출입기록</b>
                                                        </td>
                                                        <td>출입자 관리 및 출입실적 관리</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h3>2. 개인정보의 보유 및 이용기간</h3>
                                            <p className="agree-page-line">
                                                회사는 개인정보 동의일로부터 아래와 같이 해당 정보를 보유합니다.
                                            </p>
                                            <ul className="agree-page-list">
                                                <li>
                                                    개인정보 : 출입 종료 후{' '}
                                                    <u style={{ fontSize: '16px' }}>
                                                        <strong>1년</strong>
                                                    </u>
                                                </li>
                                                <li>
                                                    민감정보 : 출입 종료 후{' '}
                                                    <u style={{ fontSize: '16px' }}>
                                                        <strong>1일</strong>
                                                    </u>
                                                </li>
                                            </ul>
                                            <p className="agree-page-line marginTop-10">
                                                보유기간의 종료, 내방객이 개인정보의 파기를 요청하는 경우 개인정보를
                                                지체 없이 파기합니다.
                                                <br />
                                                단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
                                            </p>
                                            <ul className="agree-page-list">
                                                <li>
                                                    <strong>보존 항목</strong> : 법령 또는 회사의 보안규정을 위반한
                                                    출입자의 개인정보
                                                </li>
                                                <li>
                                                    <strong>보존 이유</strong> : 영업비밀 유출 방지 및 사업장 보안 유지
                                                </li>
                                                <li>
                                                    <strong>보존 기간</strong> : 개인정보 처리 목적이 달성될 때까지
                                                </li>
                                            </ul>
                                            <h3>3. 동의를 거부할 권리</h3>
                                            <p className="agree-page-line">
                                                귀하는 본 개인정보 수집 · 이용에 대한 동의를 거부하실 권리가 있으며,
                                                동의를 거부하실 경우 롯데정밀화학 정보보호 규정에 의거하여 출입이 제한될
                                                수 있습니다.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <p className="agree-page-line marginTop-20">
                                        상기와 같이 개인정보를 수집하고 이용함에 있어 충분히 내용을 확인하고 이에
                                        동의하십니까?
                                    </p>
                                    <Card className="MuiPaper-common marginTop-20">
                                        <CardContent className="cardcontent-common">
                                            <Grid container wrap="wrap">
                                                <Grid
                                                    item
                                                    md={12}
                                                    sm={12}
                                                    className="CardContentGridItem agree-wrap"
                                                    xs={12}
                                                >
                                                    <Box className="CardContentGridItem">
                                                        <h3 className="marginTop-0">개인정보 수집 · 이용에</h3>
                                                        <RadioGroup
                                                            name="agree1"
                                                            value={value1}
                                                            onChange={(e) => {
                                                                setValue1(e.target.value);
                                                            }}
                                                        >
                                                            <div>
                                                                <FormControlLabel
                                                                    value="Y"
                                                                    control={<Radio />}
                                                                    label={'동의함'}
                                                                />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <FormControlLabel
                                                                    value="N"
                                                                    control={<Radio />}
                                                                    label={'동의하지 않음'}
                                                                />
                                                            </div>
                                                        </RadioGroup>
                                                    </Box>
                                                    <Box className="CardContentGridItem">
                                                        <h3 className="marginTop-0">민감정보 수집 · 이용에</h3>
                                                        <RadioGroup
                                                            name="agree2"
                                                            value={value2}
                                                            onChange={(e) => {
                                                                setValue2(e.target.value);
                                                            }}
                                                        >
                                                            <div>
                                                                <FormControlLabel
                                                                    value="Y"
                                                                    control={<Radio />}
                                                                    label={'동의함'}
                                                                />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <FormControlLabel
                                                                    value="N"
                                                                    control={<Radio />}
                                                                    label={'동의하지 않음'}
                                                                />
                                                            </div>
                                                        </RadioGroup>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <br />
                                        <Button
                                            color="primary"
                                            size="large"
                                            variant="contained"
                                            onClick={handleClick4Ok}
                                        >
                                            확인
                                        </Button>
                                    </Card>
                                </form>
                            </div>
                        </Container>
                    </Box>
                </ISODialog>
            </React.Fragment>
        </>
    );
});

export default SettingsView;
