import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  } from '@material-ui/core';
import ISOTextField from 'src/framework/components/controls/ISOTextField';
import ActionTAEvent from 'src/components/actionevent/ActionTAEvent';

const ListToolbar = (props) => {
  const { handlesearch, ...others } = props;
  const val1 = React.useRef(ISOTextField);
  const val2 = React.useRef(ISOTextField);
  const val3 = React.useRef(ISOTextField);
  const val4 = React.useRef(ISOTextField);
  const val5 = React.useRef(ISOTextField);

  const handleCboChange = event => {
    setTimeout(() => {
      SelectTA(val1.current.getValue());
    }, 100);
    setTimeout(() => {
      SelectFACT(val1.current.getValue());
    }, 100);
  };

  const handleChange = (event) => {
    // console.log(event.target.type, event.target.name, event.target.value);
  };

  const SelectTA = async (plantId) => {
    let result = await ActionTAEvent.GetCodeData('TA_MNG', plantId);
    // console.log('SelectTA', plantId, result);
    if( result) {
      val2.current.setDataBinding(result);
    }
  }
  const SelectFACT = async (plantId) => {
    let result = await ActionTAEvent.GetCodeData('FACT_KND', plantId);
    // console.log('SelectFACT', plantId, result);
    if( result) {
      val3.current.setDataBinding(result);
    }
  }

  const handleOnClick = event => {
    setTimeout(() => {
      handlesearch(val1.current.getValue(), val2.current.getValue(), val3.current.getValue(), val4.current.getValue() || '', val5.current.getValue() || '');
    }, 100);
  };

  React.useEffect(() => {
  }, [])

  return (
  <Box {...others}>
    <Box className="search-wrap">
      <Card>
        <CardContent>
          <Box>
            <Grid container spacing={1}>
              <Grid item lg={4} sm={6} xl={2} xs={12}>
                <ISOTextField ref={val1} select={true} codeid={'PLANTID'} isaddblank={true} textlabel="사업장" placeholder="사업장을 선택하세요" onChange={handleCboChange} />
              </Grid>
              <Grid item lg={4} sm={6} xl={2} xs={12}>
                <ISOTextField ref={val2} select={true} codeid={'TA_MNG'} isaddblank={true} textlabel="정기보수" placeholder="정기보수를 선택하세요" />
              </Grid>
              <Grid item lg={4} sm={6} xl={2} xs={12}>
                <ISOTextField ref={val3} select={true} codeid={'FACT_KND'} isaddblank={true} textlabel="설비종류" placeholder="설비 종류를 선택하세요" />
              </Grid>
              <Grid item lg={4} sm={6} xl={2} xs={12}>
                <ISOTextField ref={val4} select={true} codeid={'PROCESS_CD'} isaddblank={true} textlabel="설비공정" placeholder="설비 공정을 선택하세요" />
              </Grid>
              <Grid item lg={4} sm={6} xl={2} xs={12}>
                <ISOTextField ref={val5} value="" textlabel="설비명" placeholder="설비명을 입력하세요" onChange={handleChange} />
              </Grid>
              <Grid item lg={4} sm={6} xl={2} xs={12}>
                <Button color="primary" fullWidth variant="contained" onClick={handleOnClick} >
                  검색
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
  );
}

export default ListToolbar;
