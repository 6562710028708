import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    //   Divider,
    Grid,
    //   Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import GetAppIcon from '@material-ui/icons/Work';

const QuizCard = ({ quiz, index, calculator, ...rest }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event) => {
        if (event.target.value === quiz.sol) {
            calculator(quiz.id, quiz.point, index, event.target.value);
        } else {
            calculator(quiz.id, 0, index, event.target.value);
        }
        setValue(event.target.value);
    };

    return (
        <Card className="CardContentGridItem Height100" {...rest}>
            <CardContent>
                <Box className="CardContentGridItemCenter paddingBottom-3">
                    <Grid container>
                        <Grid item md={12} xs={12}>
                            <div style={{ width: '100%', height: '100%' }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        <span className="number">{index + 1}</span>
                                        {quiz.question}
                                    </FormLabel>
                                    {/*<Divider />*/}
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <ol className="quizlist">
                                            <li>
                                                {' '}
                                                <FormControlLabel value="a" control={<Radio />} label={quiz.a} />
                                            </li>
                                            <li>
                                                {' '}
                                                <FormControlLabel value="b" control={<Radio />} label={quiz.b} />
                                            </li>
                                            <li>
                                                {' '}
                                                <FormControlLabel value="c" control={<Radio />} label={quiz.c} />
                                            </li>
                                            <li>
                                                {' '}
                                                <FormControlLabel value="d" control={<Radio />} label={quiz.d} />
                                            </li>
                                        </ol>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
            <Box className="CardContentBoxFlexGrow1" />
        </Card>
    );
};
QuizCard.propTypes = {
    quiz: PropTypes.object.isRequired
};

export default QuizCard;
