import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Card, CardHeader, CardContent, Typography, Button } from '@material-ui/core';
import {
    ISOInput,
    ISOCombobox,
    ISOButton,
    ISOGrid,
    ISOTextField,
    ISODateTime
} from 'src/framework/components/controls';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import FileUpload from 'src/components/files/FileUpload';
import Loader from 'src/components/loading/Loader';
import * as GF from 'src/components/utils/GlobalFunction';
import useUserInfoPublicAccount from 'src/framework/core/hooks/useUserInfoPublicAccount';
import { alertType, e3 } from 'src/framework/core/utils/utils';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

const AttachFiles = () => {
    const { signinUser, getReloadUserData, goToPage } = useUserInfoPublicAccount();
    const [fileTypes, setFileTypes] = React.useState(null);
    const [userFiles, setUserFiles] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true); // 파일 저장 및 삭제 동안 이동을 막기 위함

    const refCheckDay = React.useRef(ISODateTime);
    const refBpHight = React.useRef(ISOTextField);
    const refBpLow = React.useRef(ISOTextField);

    React.useEffect(async () => {
        await FileTypes();
        await GetFiles();
        await GetBpInfo();

        return () => {
            setTimeout(async () => {}, 100);
        };
    }, []);

    const onSubmit = () => {
        if (signinUser.FILE_TYPE == 'FILE_TYPE_01') {
            if ((refCheckDay.current.getDate() || '') == '') {
                e3.modal.alert(alertType.Info, '건강검진 일자를 정확히 작성해 주세요.');
                return;
            } else if ((refBpHight.current.getValue() || '') == '') {
                e3.modal.alert(alertType.Info, '혈압(고)를 정확히 작성해 주세요.');
                return;
            } else if ((refBpLow.current.getValue() || '') == '') {
                e3.modal.alert(alertType.Info, '혈압(저)를 정확히 작성해 주세요.');
                return;
            }
        } else if (signinUser.FILE_TYPE == 'FILE_TYPE_02') {
            if (refCheckDay.current.getDate() == '') {
                e3.modal.alert(alertType.Info, '제출서류 일자를 정확히 작성해 주세요.');
                return;
            }
        }

        if (userFiles.length == 0) {
            e3.modal.alert(alertType.Info, fileTypes[0].FILE_NAME + '를 제출 해 주세요.');
            return;
        }

        saveBpData();

        if (signinUser.FILE_TYPE == 'FILE_TYPE_01') {
            if (Number(refBpHight.current.getValue()) >= 160 || Number(refBpLow.current.getValue()) >= 100) {
                e3.modal.alert(
                    alertType.Info,
                    '혈압이 높습니다. 방문 전 안전운영담당 (052-270-6670,6686) 에 연락 바랍니다.'
                );
            }
        }

        setTimeout(() => {
            goToPage('/app/dashboard');
        }, 300);
    };

    const saveBpData = async () => {
        try {
            const signinUser = ActionEvent.GetUser();

            let params = DataPackage.create('MAPI100', 'SetUserFileInfo');

            if (signinUser.FILE_TYPE == 'FILE_TYPE_01') {
                params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
                params.KeyValues.push(DataPackage.NewKeyValue('CHECK_DT', refCheckDay.current.getDate()));
                params.KeyValues.push(DataPackage.NewKeyValue('BP_HIGHT', refBpHight.current.getValue() || ''));
                params.KeyValues.push(DataPackage.NewKeyValue('BP_LOW', refBpLow.current.getValue() || ''));
                params.KeyValues.push(DataPackage.NewKeyValue('CHECK', 'Y'));
            } else if (signinUser.FILE_TYPE == 'FILE_TYPE_02') {
                params.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', signinUser.PLANT_ID));
                params.KeyValues.push(DataPackage.NewKeyValue('CHECK_DT', refCheckDay.current.getDate()));
            }
            const response = await ISOAxios.Execute(params);
            if (response?.ErrorMessage && response?.ErrorMessage.length > 0) {
                throw response?.ErrorMessage;
            }
            return response;
        } catch (e) {
            e3.modal.alert(alertType.Error, e.message);
        }
    };

    const allowFiles = async () => {
        let result = await ActionEvent.AllowFiles();
        return result;
    };

    const FileTypes = async () => {
        if (signinUser?.PLANT_ID && signinUser?.VISIT_CD && signinUser?.VISIT_PURPOSE) {
            let ftypes = await ActionEvent.GetUploadFileType();
            setFileTypes(JSON.parse(ftypes));
        }
    };

    const GetFiles = async () => {
        if (signinUser?.PLANT_ID && signinUser?.VISIT_CD && signinUser?.VISIT_PURPOSE) {
            let uFiles = await ActionEvent.GetUploadFiles();
            setUserFiles(JSON.parse(uFiles));
            setTimeout(() => {
                setIsLoading(false);
            }, 300);
        }
    };
    const GetBpInfo = async () => {
        if (signinUser?.PLANT_ID && signinUser?.VISIT_CD && signinUser?.VISIT_PURPOSE) {
            let uBpInfo = await ActionEvent.GetBpInfo();
            if (uBpInfo && parseInt(uBpInfo.KeyValues[2].Value) > 0) {
                uBpInfo = JSON.parse(uBpInfo.JsonData);
                if (uBpInfo[0].CHECK_DT && signinUser.VISIT_CD == 'VISIT200') {
                    refCheckDay.current.setDate(new Date(`${decodeURI(uBpInfo[0].CHECK_DT)}`));
                    refBpHight.current.setValue(uBpInfo[0].BP_HIGHT);
                    refBpLow.current.setValue(uBpInfo[0].BP_LOW);
                } else if (uBpInfo[0].CHECK_DT && signinUser.VISIT_PURPOSE == 'VISIT300_040') {
                    refCheckDay.current.setDate(new Date(`${decodeURI(uBpInfo[0].CHECK_DT)}`));
                } else {
                    refCheckDay.current.setDate(new Date(moment().format('YYYY-MM-DD')));
                }
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 300);
        }
    };
    const SaveFile = async (files) => {
        if (signinUser?.PLANT_ID && signinUser?.VISIT_CD && signinUser?.VISIT_PURPOSE) {
            setIsLoading(true);
            let fileList = [];
            files.map((item) => {
                fileList.push({
                    VISIT_REQ_SEQ: signinUser.VISIT_REQ_SEQ,
                    FILE_TYPE: fileTypes[0].FILE_TYPE,
                    FILE_NM: item.selectedFile,
                    UPLOAD_FILE: item.base64File,
                    REG_ID: signinUser.EMP_ID
                });
            });
            let sFile = await ActionEvent.SaveFile(fileList);
            setTimeout(async () => {
                await GetFiles();
                setTimeout(() => {}, 100);
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            }, 100);
        }
    };

    const DeleteFile = async (fileType) => {
        if (signinUser?.PLANT_ID && signinUser?.VISIT_CD && signinUser?.VISIT_PURPOSE) {
            setIsLoading(true);
            let dFile = await ActionEvent.DeleteFile(fileType);
            setTimeout(async () => {
                await GetFiles();
                setTimeout(() => {}, 100);
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            }, 100);
        }
    };
    const DownloadFile = async (fileType) => {
        if (signinUser?.PLANT_ID && signinUser?.VISIT_CD && signinUser?.VISIT_PURPOSE) {
            setIsLoading(true);
            let dFile = await ActionEvent.DownloadFile(fileType);
            if (dFile && dFile.ErrorMessage === '') {
                const fileInfo = JSON.parse(dFile.JsonData);

                fileInfo.map((item, idx) => {
                    let extention = item.FILE_NM.slice((Math.max(0, item.FILE_NM.lastIndexOf('.')) || Infinity) + 1);
                    let fileName = `${item.FILE_NM.substring(0, item.FILE_NM.lastIndexOf('.'))}_REV.${extention}`;
                    GF.actionDownloadFile(item.UPLOAD_FILE, fileName);
                });
            }
            setTimeout(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            }, 100);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Box className="DashboardLayoutContentBox">
                <Box>
                    <Typography color="textPrimary" variant="h2">
                        증명서 제출
                    </Typography>
                </Box>
                <Container maxWidth={false}>
                    <Card className="MuiPaper-common">
                        <CardContent className="cardcontent-common">
                            <Box className="note">
                                <h5>제출방법</h5>
                                <ul>
                                    <li>제출해야 할 목록이 자동으로 뜹니다.</li>
                                    <li>
                                        <strong>서류를 모두 제출</strong>해야 출입절차를 진행할 수 있습니다.
                                    </li>
                                    <li>잘못 제출한 서류는 삭제 후 다시 제출할 수 있습니다.</li>
                                </ul>
                            </Box>
                            <Box className="tip">
                                <h6>안내 사항</h6>
                                <ul>
                                    <li>
                                        증명서 유효기간은 6개월입니다.{' '}
                                        <strong>방문 기간 중 유효기간이 만료인 경우 증명서 다시 제출</strong>해야
                                        합니다.
                                    </li>
                                    <li>
                                        유효기간 6개월 이내라도 기존 작업과{' '}
                                        <strong>다른 작업으로 방문하는 경우 증명서 다시 제출</strong>해야 합니다.
                                    </li>
                                    {signinUser.VISIT_CD == 'VISIT200' ? (
                                        <>
                                            <li>
                                                건강검진확인증/결과서는{' '}
                                                <strong>혈압이 기재된 내용이 보이도록 하여 제출</strong>
                                                해야 합니다.
                                            </li>
                                            <li>
                                                혈압이 기재되지 않은 확인증은 방문자가 수기로 작성하여 제출하며, <br />
                                                <strong>수기로 작성한 혈압 정보에 대한 모든 책임은 방문자 본인</strong>
                                                에게 있습니다.
                                            </li>
                                            <li>
                                                <strong>건강검진 제외자</strong>는 담당자에게 문의하여 건강검진결과서
                                                대신 <strong>"건강검진 제외 확인서"를 작성하여 제출</strong> 바랍니다.
                                            </li>
                                        </>
                                    ) : null}
                                </ul>
                            </Box>
                            <hr />
                            <Grid container spacing={2} className="marginTop-20">
                                {fileTypes &&
                                    fileTypes.map((item, index) => {
                                        let result =
                                            userFiles && userFiles.length > 0
                                                ? userFiles?.find((f) => f.FILE_TYPE === item.FILE_TYPE)
                                                : null;
                                        return (
                                            <Grid item xs={12} key={`user-file-item-${index}`} className={result}>
                                                <FileTile
                                                    item={item}
                                                    file={result}
                                                    fileName={userFiles}
                                                    saveFile={SaveFile}
                                                    deleteFile={DeleteFile}
                                                    downloadFile={DownloadFile}
                                                    className="file_wrap"
                                                />
                                            </Grid>
                                        );
                                    })}
                                {signinUser.FILE_TYPE == 'FILE_TYPE_01' ? (
                                    <Grid item xs={12} className="input_h">
                                        <div className="required">
                                            <div className="MuiInputAdornment-root">
                                                <span>건강검진 일자 </span>
                                            </div>
                                            <ISODateTime
                                                ref={refCheckDay}
                                                dateFormat={'yyyy-MM-dd'}
                                                onFocus={(e) => e.target.blur()}
                                                showMonthYearPicker={false}
                                                required={true}
                                            />
                                        </div>
                                        <div className="required mmhg">
                                            <ISOTextField
                                                ref={refBpHight}
                                                textlabel={'혈압(고) '}
                                                margin="normal"
                                                name="bphight"
                                                type="text"
                                                required={true}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        refBpHight.current.setValue(
                                                            e3.string.stringNumCheck(e.target.value)
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="required mmhg">
                                            <ISOTextField
                                                ref={refBpLow}
                                                textlabel={'혈압(저) '}
                                                margin="normal"
                                                name="bplow"
                                                type="text"
                                                required={true}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        refBpLow.current.setValue(
                                                            e3.string.stringNumCheck(e.target.value)
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            onClick={onSubmit}
                                            className="btn_wrap"
                                        >
                                            검진 결과 제출
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} className="input_h">
                                        <div className="required">
                                            <div className="MuiInputAdornment-root">
                                                <span>서류발급 일자 </span>
                                            </div>
                                            <ISODateTime
                                                ref={refCheckDay}
                                                dateFormat={'yyyy-MM-dd'}
                                                showMonthYearPicker={false}
                                                onFocus={(e) => e.target.blur()}
                                                required={true}
                                            />
                                        </div>
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            onClick={onSubmit}
                                            className="btn_wrap"
                                        >
                                            사용자 서류 제출
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
            <Loader isShow={isLoading} />
        </React.Fragment>
    );
};

const FileTile = (props) => {
    const refFile = React.useRef(null);
    const { item, file, fileName, saveFile, deleteFile, downloadFile, ...other } = props;

    const handleSaveClick = () => {
        let str = refFile.current.getFile();
        let ret = saveFile(str);
    };

    const handleDeleteClick = () => {
        deleteFile(item.FILE_TYPE);
    };

    const handleDownloadFile = () => {
        downloadFile(item.FILE_TYPE);
    };

    return (
        <Card className="Height100" {...other}>
            <CardContent>
                <Grid container spacing={3} className="BoxBetween">
                    <Grid item>
                        <Typography color="textSecondary" variant="h3">
                            {item.FILE_NAME}
                        </Typography>
                    </Grid>
                </Grid>
                <Box className="BoxCenter paddingTop-2">
                    {file ? (
                        <Grid container>
                            <Grid item xs={9}>
                                <span
                                    className="user-file-name MuiInputBase-root MuiOutlinedInput-root"
                                    onClick={handleDownloadFile}
                                >
                                    {fileName ? fileName.map((item, num) => item.FILE_NM + ', ') : ''}
                                </span>
                            </Grid>
                            <Grid item xs={3} align="right">
                                <Button variant="contained" onClick={handleDeleteClick}>
                                    서류 삭제
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container className="file-upload">
                            <Grid item xs={9}>
                                <FileUpload ref={refFile} />
                            </Grid>
                            <Grid item xs={3}>
                                <Button color="Primary" variant="contained" onClick={handleSaveClick}>
                                    서류 제출
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default AttachFiles;
